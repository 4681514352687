<div *ngIf="exogenousVariables && exogenousCategories">
  <div class="panel-with-header-and-content">
    <!--
        <h3 class="panel-header">
        </h3>
        -->
    <div class="panel-content">
      <dx-data-grid
        [dataSource]="exogenousVariables.all"
        redrawOnResize="true"
        [rowAlternationEnabled]="true"
        [showColumnHeaders]="true"
        height="100%"
        [allowColumnResizing]="true"
        (onEditorPreparing)="onEditorPreparing($event)"
        (onRowUpdated)="onRowUpdated($event)"
      >
        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-editing [allowUpdating]="true" mode="batch" [allowDeleting]="false"></dxo-editing>
        <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

        <dxi-column [allowEditing]="false" dataField="displayName" dataType="string" [caption]="language.modelName"> </dxi-column>
        <dxi-column [allowEditing]="false" dataField="categoryId" dataType="number" [groupIndex]="0" [calculateDisplayValue]="getCategoryDisplayValue" [caption]="language.edittriggerCategory">
        </dxi-column>
        <dxi-column dataField="visibleTriggerFormulaText" dataType="string" [caption]="language.edittriggerVisibleTrigger"> </dxi-column>
        <dxi-column dataField="warningTriggerFormulaText" dataType="string" [caption]="language.edittriggerWarningTrigger"> </dxi-column>
        <dxi-column dataField="errorTriggerFormulaText" dataType="string" [caption]="language.edittriggerErrorTrigger"> </dxi-column>
        <dxi-column dataField="readOnlyTriggerFormulaText" dataType="string" [caption]="language.edittriggerReadOnlyTrigger"> </dxi-column>
        <dxi-column dataField="sortOrder" dataType="number" [caption]="language.modelSortOrder"> </dxi-column>
      </dx-data-grid>
    </div>
  </div>
</div>
