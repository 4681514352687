<div class="datagrid-container" *ngIf="models.length > 0">
  <dx-data-grid [dataSource]="models" (onEditingStart)="onEditingStart($event.data)"
    (onRowUpdated)="onRowUpdated($event.data, $event.component)">
    <dxo-editing [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"></dxo-editing>
    <dxi-column dataField="ShortName" [caption]="language.modelShortName"></dxi-column>
    <dxi-column dataField="Title" [caption]="language.modelTitle" editCellTemplate="editCellTitleLanguageTemplate"
      [calculateDisplayValue]="title"></dxi-column>
    <dxi-column dataField="Condition" [caption]="language.f3MeWebApiMenuManagementSimulationLanguageEditPostfix"
      editCellTemplate="editCellConditionLanguageTemplate" [calculateDisplayValue]="valuePostfix"></dxi-column>
    <div *dxTemplate="let d of 'editCellTitleLanguageTemplate'">
      <app-languageitem-editor-grid [cellInfo]="d" [item]="d.data.TitleCopy"></app-languageitem-editor-grid>
    </div>
    <div *dxTemplate="let d of 'editCellConditionLanguageTemplate'">
      <app-languageitem-editor-grid [cellInfo]="d" [item]="d.data.ValuePostfixCopy"></app-languageitem-editor-grid>
    </div>
  </dx-data-grid>
</div>