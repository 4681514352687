<div class="login-no data">
    <app-cardview [title]="language.loginNoDataAvailableTitle">
      <h1>{{ language.loginNoDataAvailableTitle }}</h1>
      <div>
        <p>{{ language.loginNoDataAvailable }}</p>
      </div>
      
      <div class="button-container align-right">
        <dx-button [text]="language.landingpageGoToLogin" (onClick)="gotoLogin()"></dx-button>
      </div>
    </app-cardview>
  </div>