import { RouterModule, Routes } from "@angular/router";
import {
  DxAccordionModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxFormModule,
  DxLookupModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTabsModule,
  DxTextBoxModule,
  DxTreeListModule,
} from "devextreme-angular";
import { SourceSpecificationsEditComponent, SourceSpecificationsEditModule, SourceSpecificationsModule } from "../sourcesedit";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AuthorizationEditModule } from "../../../../angular-common/components/authorization/authorization-edit.module";
import { CardViewModule } from "../../../../angular-common/components/cardview";
import { HtmlEditorModule } from "../../../../angular-common/components/htmleditor/htmleditor.module";
import { ListSelectorModule } from "../../../../angular-common/components/listselector/listselector.module";
import { ModelTreeModule } from "../../../../angular-common/components/modeltree/modeltree.module";
import { ModelTreeServiceModule } from "../../../../angular-common/components/modeltree/modeltreeservice/modeltree-service.module";
import { canActivateUserAcknowledgementGuard } from "../../../../employee-common/auth/user-acknowledgemen.guard";
import { CMSModule } from "../../../../employee-common/cms/cms.module";
import { LanguageItemEditorGridModule } from "../../../../employee-common/cms/languageitem-editor-grid/languageitem-editor-grid.module";
import { CmsManagementModule } from "../../../../employee-common/cms/management/cms-management.module";
import { PluginManagementModule } from "../../../../employee-common/cms/plugin/manage/plugin-management.module";
import { EbsManagementModule } from "../../../../employee-common/ebs/management/ebs-management.module";
import { EmployeeEmailModule } from "../../../../employee-common/email/employee-email.module";
import { LabourConditionManagementModule } from "../../../../employee-common/labourconditions/management/labourconditions-management.module";
import { UserEmployeeMappingModule } from "../../../../employee-common/management/mapping/prefered-user-employee-mapping/user-employee-mapping.module";
import { ChoicesRoutesDto } from "../../../../employee-common/models/choices/dto/ChoicesRoutes-dto";
import { MenuItemTypesDto } from "../../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { ReportingManagementModule } from "../../../../employee-common/reporting/management/reporting-management.module";
import { ManagementSettingsModule } from "../managementsettings/management-settings.module";
import { ManagementRoutesDto } from "../models/dto/ManagementRoutes-dto";
import { SimulationRoutesDto } from "../models/dto/SimulationRoutes-dto";
import { SimulationLanguageEditServiceModule } from "../shared/simulationlanguage/simulation-language-edit.module";
import { SmartEbsDesignerComponent } from "../smart-ebs/smart-ebs-designer.component";
import { SmartEbsModule } from "../smart-ebs/smart-ebs.module";
import { TargetFieldsEditComponent } from "../targetfieldsedit/target-fields-edit.component";
import { TargetFieldsEditModule } from "../targetfieldsedit/target-fields-edit.module";
import { TargetTranslationsModule } from "../targettranslations/target-translations.module";
import { SimulationModelEditComponent } from "./cms-edit-simmodelelements.component";
import { CmsLabourConditionLinkEditWithChoicesComponent } from "./cms-labourcondtion-link-withchoices-edit.component";
import { SimulationLanguageComponent } from "./cms-simulation-language.component";
import { TargetContentComponent } from "./cms-target-content.component";
import { TargetDescriptionLanguageItemEditComponent } from "./cms-target-description.component";
import { TargetEditComponent } from "./cms-target-edit.component";
import { TargetLanguageItemEditComponent } from "./cms-target-title.component";
import { VariantSelectionComponent } from "./cms-variant-selection.component";

// TODO routes to choices module: https://dev.azure.com/sdworx/f3/_workitems/edit/847118
const routes: Routes = [
  {
    path: ManagementRoutesDto.VariantSelection,
    component: VariantSelectionComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: ChoicesRoutesDto.TargetFieldsEdit,
    component: TargetFieldsEditComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: SimulationRoutesDto.SimulationEdit,
    component: SimulationModelEditComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: ChoicesRoutesDto.TargetEdit,
    component: TargetEditComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: ChoicesRoutesDto.SourcesEdit,
    component: SourceSpecificationsEditComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },

  {
    path: ManagementRoutesDto.SmartEbs,
    component: SmartEbsDesignerComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: ManagementRoutesDto.SimulationLanguageEdit,
    component: SimulationLanguageComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    CardViewModule,
    ListSelectorModule,
    ModelTreeModule,
    ModelTreeServiceModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxLookupModule,
    DxDataGridModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxAccordionModule,
    DxPopupModule,
    DxFormModule,
    DxTreeListModule,
    CMSModule,
    EmployeeEmailModule,
    TargetFieldsEditModule,
    SourceSpecificationsEditModule,
    SourceSpecificationsModule,
    ManagementSettingsModule,
    TargetTranslationsModule,
    CmsManagementModule,
    LanguageItemEditorGridModule,
    PluginManagementModule,
    HtmlEditorModule,
    DxTabsModule,
    SmartEbsModule,
    SimulationLanguageEditServiceModule,
    ReportingManagementModule,
    EbsManagementModule,
    UserEmployeeMappingModule,
    AuthorizationEditModule,
    LabourConditionManagementModule,
  ],
  exports: [],
  declarations: [
    TargetContentComponent,
    SimulationModelEditComponent,
    TargetEditComponent,
    VariantSelectionComponent,
    TargetDescriptionLanguageItemEditComponent,
    TargetLanguageItemEditComponent,
    SimulationLanguageComponent,
    CmsLabourConditionLinkEditWithChoicesComponent,
  ],
  providers: [],
})
export class ManagementModule {
  constructor() {
    LabourConditionManagementModule.registerDetailClass(CmsLabourConditionLinkEditWithChoicesComponent);
  }
}
