import { SmartEbsItem } from "./smart-ebs-item";

export class SmartJsonReader {
  public static createSmartEbsFromJson(jsonFile: any): SmartEbsItem[] {
    const items: SmartEbsItem[] = [];
    let i = 0;
    // We currently only use the first period
    if (jsonFile.result[0]) {
      jsonFile.result[0].breakdowns.forEach((breakdown) => {
        breakdown.breakdownDetails.forEach((details) => {
          const ebsItem = new SmartEbsItem(i, details.title, details.title, details.total);
          items.push(ebsItem);
          i++;
        });
      });
    }
    return items;
  }
}
