import { LanguageItem } from "../../../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";
import { ExogenousInputChoiceOptionValueDto } from "../../../../../employee-common/models/me/dto/ExogenousInputChoiceOptionValue-dto";
import { SimulationBase } from "./simulation-base.model";

export class SimulationInput extends SimulationBase {
  public lowerBound: number;
  public upperBound: number;
  public stepSize: number;
  public options: ExogenousInputChoiceOptionValueDto[];
  public visible: boolean;
  public readonly: boolean;

  public constructor(
    languageSelection: PlLanguageSelection,
    public shortName: string,
    title: LanguageItem,
    periodUnit: LanguageItem,
    public sortOrder: number,
    public value: string,
    valueAsNumber: number,
  ) {
    super(languageSelection, shortName, title, periodUnit, sortOrder, value, valueAsNumber);
    this.options = [];
    this.visible = true;
    this.readonly = false;
  }

  public get useSlider() {
    return this.useChoice === false;
  }

  public get useChoice() {
    if (this.options && this.options.length > 0) {
      return true;
    }
    return false;
  }
}
