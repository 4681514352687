<div class="users-claims">
  <app-cardview [title]="language.accountClaimsTitle">
    <div>
      <p>{{ language.accountClaimsTitle }}</p>
    </div>

    <div *ngIf="loaded === false">
      {{ language.pleaseWaitLoading }}
    </div>
    <div *ngIf="loaded">
      <div>
        <div *ngIf="hasCurrentUser === false">
          <span>{{ language.ClaimNoUserLoaded }}</span>
          <dx-button class="refresh-btn" icon="refresh" (onClick)="gotoSignin()" [textContent]="language.landingpageGoToLogin"></dx-button>
        </div>

        <div *ngIf="hasCookie">
          <span>{{ language.accountClaimsCookieInUse }}</span>
          <dx-button class="refresh-btn" icon="refresh" (onClick)="goToCookieClear()" [textContent]="language.accountClaimsCookieClear"></dx-button>
        </div>

        <span *ngIf="currentUserAuthenticated === false">{{ language.ClaimNotAuthenticated }}</span>

        <div *ngIf="currentUserAuthenticated">
          <span>User authenticated as: {{ currentUserName }}</span>
          <dx-button class="refresh-btn" icon="link" (onClick)="gotoSignout()" [textContent]="language.ClaimGoToSignOut"></dx-button>
        </div>
      </div>

      <div class="claims-table-container">
        <table>
          <thead>
            <td>{{ language.ClaimType }}</td>
            <td>{{ language.ClaimUsage }}</td>
            <td>{{ language.ClaimValue }}</td>
          </thead>
          <tr *ngFor="let claim of claims">
            <td>{{ claim.Namespace }}</td>
            <td>
              {{ claim.UsageDescription }}
              <p *ngIf="roleMissing(claim)">({{ language.accountClaimsRoleRefreshRequired }})</p>
            </td>
            <td>{{ claim.Value }}</td>
          </tr>
        </table>

        <div class="claims-summary">
          <span>{{ numberOfClaimsText }}</span>
          <dx-button class="refresh-btn" icon="refresh" disable="refreshingCurrentUser" (onClick)="refreshUser()"> </dx-button>
        </div>
      </div>
    </div>
  </app-cardview>
</div>
