import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { ExogenousInputNumericOptionDto } from "../../../../employee-common/models/me/dto/ExogenousInputNumericOption-dto";
import { ExogenousInputOptionChoiceDto } from "../../../../employee-common/models/me/dto/ExogenousInputOptionChoice-dto";
import { SimulationInput } from "./_models/simulation-input.model";
import { SimulationIO } from "./_models/simulation-io.model";
import { SimulationOutput } from "./_models/simulation-output.model";
import { SimulationIODto } from "./dto/SimulationIO-dto";

@Injectable()
export class SimulateService extends PlBaseService {
  readonly endPoint = "simulation";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, private languageSelection: PlLanguageSelection, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getSimulationValues(): Observable<SimulationIO> {
    const langSelect = this.languageSelection;
    return this.getDataTransformed<SimulationIODto>(this.endPoint).pipe(map((r) => this.transformValues(langSelect, r)));
  }

  public postSimulationValues(newValues: [string, number]): Observable<SimulationIO> {
    const langSelect = this.languageSelection;
    return this.updateTransformedResponse<SimulationIODto>(newValues, this.endPoint).pipe(map((r) => this.transformValues(langSelect, r)));
  }

  private transformValues(languageSelection: PlLanguageSelection, dto: SimulationIODto): SimulationIO {
    if (dto) {
      const inputList = new Array<SimulationInput>();
      const outputList = new Array<SimulationOutput>();

      for (const jsonInput of dto.Input) {
        let valueAsNumber = 0;
        let valueAsText = "";

        if (jsonInput.Value) {
          const numericInputOption = jsonInput.Value as ExogenousInputNumericOptionDto;
          if (numericInputOption.Type === ExogenousInputNumericOptionDto.TypeName) {
            valueAsText = numericInputOption.Value;
            valueAsNumber = numericInputOption.ValueAsNumber;
          }

          const choiceInputOption = jsonInput.Value as ExogenousInputOptionChoiceDto;
          if (choiceInputOption.Type === ExogenousInputOptionChoiceDto.TypeName) {
            valueAsText = choiceInputOption.Value.Text;
            valueAsNumber = choiceInputOption.Value.Identification;
          }

          const inputItem = new SimulationInput(languageSelection, jsonInput.ShortName, jsonInput.Title, jsonInput.ValuePostfix, jsonInput.SortOrder, valueAsText, valueAsNumber);

          if (jsonInput.Value.IsVisible !== undefined) {
            inputItem.visible = jsonInput.Value.IsVisible;
          }
          if (jsonInput.Value.IsEnabled !== undefined) {
            inputItem.readonly = jsonInput.Value.IsEnabled === false;
          }

          if (numericInputOption.Type === ExogenousInputNumericOptionDto.TypeName) {
            if (numericInputOption.Lowerbound !== undefined) {
              inputItem.lowerBound = numericInputOption.Lowerbound;
            }
            if (numericInputOption.Upperbound !== undefined) {
              inputItem.upperBound = numericInputOption.Upperbound;
            }
            if (numericInputOption.StepSize !== undefined) {
              inputItem.stepSize = numericInputOption.StepSize;
            }
          }

          if (choiceInputOption.Type === ExogenousInputOptionChoiceDto.TypeName) {
            if (choiceInputOption.Values) {
              inputItem.options = choiceInputOption.Values;
            }
          }

          inputList.push(inputItem);
        }
      }

      for (const jsonOutput of dto.Output) {
        const outputItem = new SimulationOutput(
          languageSelection,
          jsonOutput.ShortName,
          jsonOutput.Title,
          jsonOutput.ValuePostfix,
          jsonOutput.SortOrder,
          jsonOutput.Original.Value,
          jsonOutput.Original.ValueAsNumber,
          jsonOutput.Simulated.Value,
          jsonOutput.Simulated.ValueAsNumber,
        );
        outputList.push(outputItem);
      }

      return new SimulationIO(inputList, outputList);
    } else {
      return null;
    }
  }
}
