import { Component, OnInit } from "@angular/core";

import { CmsLanguage } from "../../../../../employee-common/cms/language/cmslanguage.service";
import { ElementHelper } from "../../../../../angular-common/helpers/element.helper";
import { Formulas } from "../../../../../common/modelelements";
import { SourceSpecification } from "../sourcespecifications/models/source-specification.model";
import { SourceSpecificationWithKey } from "./source-specification-with-key.model";
import { SourceSpecifications } from "../sourcespecifications/models/source-specifications.model";
import { SourceSpecificationsService } from "../sourcespecifications/source-specifications.service";
import { StringComparer } from "../../../../../angular-common/stringcomparison";

@Component({
  selector: "app-source-specifications-edit",
  templateUrl: "./source-specifications-edit.component.html",
  styleUrls: ["./source-specifications-edit.component.scss"],
})
export class SourceSpecificationsEditComponent implements OnInit {
  public constructor(public language: CmsLanguage, private sourceSpecificationsService: SourceSpecificationsService) {
    this.validationCallback = this.validationCallback.bind(this);
  }

  ngOnInit(): void {
    this.sourceSpecificationsService.getAll().subscribe((sm) => {
      this.sourceSpecifications = sm.sourceSpecifications;
      this.formulas = sm.formulas;

      this.refreshList();
    });
  }

  public formulas: Formulas;
  public sourceSpecifications: SourceSpecifications;
  public list: SourceSpecificationWithKey[];
  public shortNameValidation = ElementHelper.invalidChars;

  private refreshList() {
    this.list = [];
    this.sourceSpecifications.all.forEach((sp) => this.list.push(new SourceSpecificationWithKey(sp, sp.shortName)));
  }

  public get contentLoaded(): boolean {
    return this.sourceSpecifications !== undefined && this.sourceSpecifications !== null;
  }

  public onRowUpdated(row: { data: SourceSpecificationWithKey }) {
    if (row.data.isNew) {
      this.sourceSpecificationsService.createSourceSpecification(row.data.sourceSpecification).subscribe(() => {
        row.data.originalShortName = row.data.sourceSpecification.shortName;
      });
    } else {
      this.sourceSpecificationsService.updateSourceSpecification(row.data.originalShortName, row.data.sourceSpecification).subscribe(() => {
        row.data.originalShortName = row.data.sourceSpecification.shortName;
      });
    }
  }

  public onRowRemoved(row: { data: SourceSpecificationWithKey }) {
    if (row.data.isNew === false) {
      this.sourceSpecificationsService.removeSourceSpecification(row.data.sourceSpecification).subscribe(() => {
        this.removeFromList(row.data);
      });
    } else {
      this.removeFromList(row.data);
    }
  }

  private removeFromList(sourceSpecification: SourceSpecificationWithKey) {
    this.sourceSpecifications.remove(sourceSpecification.sourceSpecification.shortName);
    this.refreshList();
  }

  public validationCallback(e): boolean {
    if (StringComparer.areEqual(e.data.originalShortName, e.value)) {
      // Name wasn't changed
      return true;
    } else {
      // Value already exists as a shortname
      const existingItem = this.list.find((item) => StringComparer.areEqual(item.originalShortName, e.value));
      return existingItem === undefined || existingItem === null;
    }
  }

  public addNew() {
    const newSpecification = new SourceSpecification();
    this.sourceSpecifications.add(newSpecification);

    this.refreshList();
  }
}
