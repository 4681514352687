import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { SimulationModelElementsService } from "./simulation-modelelements.service";

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [SimulationModelElementsService],
  exports: [],
})
export class SimulationModelElementModule {}
