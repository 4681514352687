import { Component, OnInit } from "@angular/core";

import { DateTimeUnitDto } from "./../../../../../common/models/dto/DateTimeUnit-dto";
import { ElementHelper } from "../../../../../angular-common/helpers/element.helper";
import { InspireLanguageService } from "../../shared/language/inspire-language.service";
import { LoginDataPoint } from "../models/login-data-point.model";
import { UserLoginGroup } from "./userlogins-group-model";
import { UserLoginService } from "./userlogin.service";

@Component({
  selector: "app-userlogins-chart",
  templateUrl: "./userlogins-chart.component.html",
  styleUrls: ["./userlogins-chart.component.scss"],
})
export class UserloginsChartComponent implements OnInit {
  public groupings: UserLoginGroup[];
  public currentGrouping: UserLoginGroup;
  public dataPoints: LoginDataPoint[];

  constructor(private userloginService: UserLoginService, public languageService: InspireLanguageService) {
    this.maxDate = new Date();
    this.minDate = new Date(this.maxDate.getFullYear() - 1, 1, 1, 0, 0);

    this.groupings = UserLoginGroup.create(languageService);
    this.currentGrouping = this.groupings.find((x) => x.value === DateTimeUnitDto.Month);
  }

  ngOnInit(): void {
    this.load();
  }

  private _minDate: Date;
  public get minDate(): string | number | Date {
    return this._minDate;
  }
  public set minDate(value: string | number | Date) {
    this._minDate = new Date(value);
    this.load();
  }

  private _maxDate: Date;
  public get maxDate(): string | number | Date {
    return this._maxDate;
  }
  public set maxDate(value: string | number | Date) {
    this._maxDate = new Date(value);
    this.load();
  }

  public get contentLoaded(): boolean {
    return ElementHelper.isNullOrUndefined(this.dataPoints) === false;
  }

  filterByUnit() {
    this.load();
  }

  load() {
    if (this.currentGrouping !== undefined) {
      this.userloginService.getLogins(this.currentGrouping.value, this._minDate, this._maxDate).subscribe((receivedData) => {
        this.dataPoints = receivedData;
      });
    }
  }

  customizeTooltip(arg: any) {
    return {
      text: `${arg.argumentText}: ${arg.valueText}`,
    };
  }
}
