import { NgModule } from "@angular/core";
import { DxButtonModule, DxTextBoxModule } from "devextreme-angular";
import { SearchBarComponent } from "./search-bar.component";

@NgModule({
    declarations: [SearchBarComponent],
    imports: [DxTextBoxModule, DxButtonModule],
    exports: [SearchBarComponent],
})
export class SearchBarModule {}
