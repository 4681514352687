import { Component, OnInit } from "@angular/core";

import { BehaviorSubject } from "rxjs";
import { InspireLanguageService } from "../shared/language/inspire-language.service";
import { SmartEbsHeader } from "./smart-ebs-header";
import { SmartEbsItem } from "./smart-ebs-item";

@Component({
  selector: "app-smart-ebs-designer",
  templateUrl: "smart-ebs-designer.component.html",
  styleUrls: ["smart-ebs-designer.component.scss"],
})
export class SmartEbsDesignerComponent implements OnInit {
  constructor(private language: InspireLanguageService) {
    this.showTutorial = JSON.parse(localStorage.getItem("showTutorial"));
  }

  private showTutorial = true;
  public toastvisible = false;
  public toastType = "success";
  public toastMessage = "ToastMessage";

  public allDone = false;
  public uploaded = false;

  public popupLocation: string;
  public popupPosition: string;
  public popupMessage: string;
  public popupVisible: boolean = false;

  private uploaderShown = false;
  private selecterShown = false;
  private chartShown = false;

  public ebsItems: SmartEbsItem[] = [];
  private ebsGroups: SmartEbsHeader[] = [];
  public _ebsGroups$ = new BehaviorSubject(this.ebsGroups);
  public ebsGroups$ = this._ebsGroups$.asObservable();

  ngOnInit(): void {
    if (this.showTutorial === true) {
      this.startTutorial();
    }
  }

  startTutorial() {
    if (this.uploaderShown === false) {
      this.popupLocation = "#uploader";
      this.popupPosition = "right";
      this.popupMessage = this.language.f3MeWebApiSmartEbsUploaderInfo;

      this.popupVisible = true;
      this.uploaderShown = true;
      return;
    }

    if (this.selecterShown === false) {
      this.popupLocation = "#selecter";
      this.popupPosition = "top";
      this.popupMessage = this.language.f3MeWebApiSmartEbsSelecterInfo;

      this.popupVisible = true;
      this.selecterShown = true;
      return;
    }

    if (this.chartShown === false) {
      this.popupLocation = "#chart";
      this.popupPosition = "left";
      this.popupMessage = this.language.f3MeWebApiSmartEbsChartInfo;

      this.popupVisible = true;
      this.chartShown = true;
      return;
    }

    localStorage.setItem("showTutorial", "false");
    this.showTutorial = false;
  }

  public updatedChartGroups(e: SmartEbsHeader[]) {
    this._ebsGroups$.next(e);
  }

  public arrangeComplete(e: { result: string; matched: number }) {
    if (e.result === "success") {
      this.toastMessage = this.language.f3MeWebApiSmartEbsArrangeComeplete(e.matched);
      this.toastType = "success";
      this.toastvisible = true;
    }
    setTimeout(() => (this.allDone = !this.allDone), 7000);
    setTimeout(() => (this.allDone = !this.allDone), 3000);
  }

  public uploadComplete(e: { result: string; items: SmartEbsItem[] }) {
    console.log(e);
    if (e.result === "success") {
      this.toastMessage = this.language.f3MeWebApiSmartEbsUploadComplete;
      this.toastvisible = true;
      this.uploaded = true;
      this.ebsItems = e.items;
      return;
    }
    if (e.result === "failed") {
      this.toastType = "Error";
      this.toastMessage = this.language.f3MeWebApiSmartEbsUploadFailed;
      this.toastvisible = true;
    }
  }
}
