import { Formula } from "./formula.model";
import { FormulaDto } from "../../models/dto/FormulaDto-dto";
import { ModelElements } from "../model-elements.model";

export class Formulas extends ModelElements<Formula> {
  public copyFromDto(formulasDto: FormulaDto[]) {
    if (!formulasDto || formulasDto === null) {
      return;
    }

    formulasDto.forEach((formulaDto) => {
      const newFormula = new Formula();
      newFormula.copyFromDto(formulaDto);
      this.add(newFormula);
    });

    this.sortSortOrderThenAlpha();
  }
}
