import { ValueToText } from "./smartebs-value-to-text-converter";

export class SmartEbsHeader {
  constructor(id: number, shortName: string, longName: string, color: string) {
    this.ID = id;
    this.shortName = shortName;
    this.longName = longName;
    this.ebsColor = color;
  }
  public ID: number;
  public shortName: string;
  public longName: string;
  public ebsColor: string;
  public totalValue: number;

  public get valueAsText() {
    return ValueToText.from(this.totalValue);
  }
}
