<div *ngIf="targetSelector && targetSelector.targetsAvailable">
  <div class="buttonBar target-selector-button-bar">
    <div *ngIf="!targetSelector.targetsAvailable">
      {{ language.f3MeWebApiNoTargets }}
    </div>
    <div *ngIf="targetSelector.targetsAvailable">
      {{ language.f3MeWebApiSelectTarget }}
      <div class="dx-field target-selector">
        <dx-select-box [items]="targetSelector.targets" [(value)]="targetSelector.selectedTarget" displayExpr="shortName"> </dx-select-box>
      </div>
    </div>
  </div>
</div>
