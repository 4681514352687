import { Component, OnInit } from "@angular/core";

import DxDataGrid from "devextreme/ui/data_grid";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { PlLanguageSelectionCopier } from "../../../../angular-common/language/pl-language-selection-copier";
import { InspireLanguageService } from "../shared/language/inspire-language.service";
import { SimulationLanguageEditService } from "../shared/simulationlanguage/simulation-language-edit.service";
import { SimulationLanguageItemDto } from "../simulate/dto/SimulationLanguageItem-dto";

@Component({
  selector: "app-simulation-language",
  templateUrl: "cms-simulation-language.component.html",
  styleUrls: ["cms-simulation-language.component.scss"],
})
export class SimulationLanguageComponent implements OnInit {
  constructor(public language: InspireLanguageService, private simulationService: SimulationLanguageEditService, private plLanguageSelection: PlLanguageSelection) {
    this.title = this.title.bind(this);
    this.valuePostfix = this.valuePostfix.bind(this);
  }

  public models: SimulationLanguageItemDto[] = [];

  onEditingStart(sim: SimulationLanguageItemDto) {
    PlLanguageSelectionCopier.copyFromOriginal(sim);
  }

  public title(sim: SimulationLanguageItemDto) {
    return sim.Title[this.plLanguageSelection.currentLanguage];
  }

  public valuePostfix(sim: SimulationLanguageItemDto) {
    return sim.ValuePostfix[this.plLanguageSelection.currentLanguage];
  }

  onRowUpdated(sim: SimulationLanguageItemDto, dxGrid: DxDataGrid) {
    PlLanguageSelectionCopier.copyToOriginal(sim);
    dxGrid.repaint();
    this.simulationService.updateLanguage(sim).subscribe();
  }

  ngOnInit(): void {
    this.simulationService.retrieveAll().subscribe((result) => {
      result.forEach((modelelement) => {
        this.models.push(modelelement);
      });
    });
  }
}
