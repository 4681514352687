import { Component } from "@angular/core";

@Component({
  selector: "app-error-page",
  templateUrl: "./error.page.component.html",
  styles: [
    `
      .errorDisplay {
        position: relative;
        display: block;
        width: 50%;
        height: 20%;
        margin: auto;
        margin-top: 200px;
        text-align: center;
      }

      .linkPointer {
        cursor: pointer;
      }
    `,
  ],
})
export class ErrorPageComponent {}
