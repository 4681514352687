import { Component, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { SimulationBase } from "./_models/simulation-base.model";
import { SimulationIO } from "./_models/simulation-io.model";
import { SimulateLanguage } from "./language/simulatelanguage.service";
import { SimulateService } from "./simulate.service";

@Component({
  selector: "app-simulate",
  templateUrl: "./simulate.component.html",
  styleUrls: ["./simulate.component.scss"],
})
export class SimulateComponent implements OnInit, OnDestroy {
  public simulationData: SimulationIO = null;
  private subscriptions: Subscription[] = null;
  private ignoreUpdates: boolean = false;
  private updateSubscription: Subscription = null;

  public constructor(private services: SimulateService, public languageService: SimulateLanguage) {}

  public ngOnInit(): void {
    this.getSimulationValues();
  }

  public ngOnDestroy(): void {
    this.releaseSubscriptions();
  }

  private getSimulationValues() {
    this.services.getSimulationValues().subscribe((data) => {
      this.initialize(data);
    });
  }

  private initialize(newData: SimulationIO) {
    this.releaseSubscriptions();

    this.subscriptions = [];
    for (const input of newData.input) {
      const subscription = input.valueChanged.subscribe((i) => this.postChange(i));
      this.subscriptions.push(subscription);
    }
    newData.sortEntries();
    this.simulationData = newData;
  }

  private releaseSubscriptions() {
    if (this.subscriptions) {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
      this.subscriptions = null;
    }
  }

  private postChange(input: SimulationBase) {
    if (!this.simulationData) {
      return;
    }

    const newValues = {} as [string, number];
    for (const inputValue of this.simulationData.input) {
      newValues[inputValue.shortName] = inputValue.valueAsNumber;
    }

    // Cancel/ignore previous update
    if (this.updateSubscription !== null) {
      this.updateSubscription.unsubscribe();
      this.updateSubscription = null;
    }

    const obs = this.services.postSimulationValues(newValues);
    this.updateSubscription = obs.subscribe((data) => {
      this.updateData(data);
    });
    obs.pipe(finalize(() => this.updateSubscription == null));
  }

  private updateData(newData: SimulationIO) {
    for (const newOutput of newData.output) {
      for (const currentOutput of this.simulationData.output) {
        if (newOutput.shortName === currentOutput.shortName) {
          currentOutput.simulatedValue = newOutput.simulatedValue;
          currentOutput.simulatedValueAsNumber = newOutput.simulatedValueAsNumber;
        }
      }
    }
    for (const newInput of newData.input) {
      for (const currentInput of this.simulationData.input) {
        if (newInput.shortName === currentInput.shortName) {
          let lowerBound = newInput.lowerBound;
          if (lowerBound === null || lowerBound === undefined) {
            lowerBound = 0;
          }
          let stepSize = newInput.stepSize;
          if (stepSize === null || stepSize === undefined || stepSize <= 0) {
            stepSize = 1;
          }
          let upperBound = newInput.upperBound;
          if (upperBound === null || upperBound === undefined || upperBound <= lowerBound) {
            upperBound = lowerBound;
          }

          currentInput.lowerBound = lowerBound;
          currentInput.stepSize = stepSize;
          currentInput.upperBound = upperBound;
          currentInput.readonly = newInput.readonly;
          currentInput.visible = newInput.visible;
          currentInput.value = newInput.value;
          currentInput.options = newInput.options;
        }
      }
    }
  }
}
