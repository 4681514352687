import { StringComparer } from "../../../../../../angular-common/stringcomparison";
import { SourceSpecificationDto } from "../../../../../../employee-common/models/choices/dto/SourceSpecificationDto-dto";
import { SourceSpecification } from "./source-specification.model";

export class SourceSpecifications {
  private _sourceSpecifications: SourceSpecification[];
  public constructor(specifications: SourceSpecification[]) {
    if (!specifications || specifications === null) {
      this._sourceSpecifications = [];
    } else {
      this._sourceSpecifications = specifications;
    }
  }
  public get all(): SourceSpecification[] {
    return this._sourceSpecifications;
  }

  public copyFromDto(dtos: SourceSpecificationDto[]) {
    dtos.forEach((dto) => {
      const newSpec = new SourceSpecification();
      newSpec.copyFromDto(dto);
      this._sourceSpecifications.push(newSpec);
    });
  }

  public add(sourceSpecification: SourceSpecification) {
    this._sourceSpecifications.push(sourceSpecification);
  }

  public remove(shortName: string) {
    const index = this._sourceSpecifications.findIndex((e) => StringComparer.areEqual(e.shortName, shortName));
    if (index && index > -1) {
      this._sourceSpecifications.splice(index, 1);
    }
  }
}
