import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";
import { AttachmentDto } from "../../../../common/models/dto/AttachmentDto-dto";

@Injectable()
export class FileService extends PlBaseService {
  private endPoint: string = "attachments/";
  private downloadEndpoint: string = "download";
  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  getUserFiles(userId: string) {
    return this.getData<AttachmentDto[]>(`${this.endPoint}${userId}`);
  }

  getUserFileById(userId: string, fileName: string): Observable<Blob> {
    let fileParams = new HttpParams();
    fileParams = fileParams.append("userId", userId);
    fileParams = fileParams.append("name", fileName);
    return this.getDataAsBlobWithParams(`${this.endPoint}${this.downloadEndpoint}`, fileParams);
  }
}
