import { PlLanguageSelection } from "../../../../../../angular-common/language/pl-language-selection";
import { ExogenousCategory } from "../../../../../../common/modelelements";
import { TargetOptionTranslationDto } from "../../../../../../employee-common/models/choices/dto/TargetOptionTranslation-dto";
import { TargetOptionsTranslationDto } from "../../../../../../employee-common/models/choices/dto/TargetOptionsTranslation-dto";
import { InspireLanguageService } from "../../language/inspire-language.service";
import { TargetOptionTranslation } from "./target-option-translation.model";

export class TargetOptionsTranslation {
  public constructor(private language: InspireLanguageService, private selectLanguage: PlLanguageSelection) {
    this.exogenousCategory = new ExogenousCategory();
  }

  public copyFromDto(dto: TargetOptionsTranslationDto) {
    dto.TargetOptions.forEach((to) => {
      const newOption = new TargetOptionTranslation(this.language, this.selectLanguage);
      newOption.copyFromDto(to);
      this.options.push(newOption);
    });
    this.exogenousCategory.fromDto(dto.ExogenousCategory);
    this.errorReferenceCategoryId = dto.ErrorReferenceCategoryId;
    this.warningReferenceCategoryId = dto.WarningReferenceCategoryId;
  }

  public toDto(): TargetOptionsTranslationDto {
    const dto = new TargetOptionsTranslationDto();
    dto.ErrorReferenceCategoryId = this.errorReferenceCategoryId;
    dto.ExogenousCategory = this.exogenousCategory.toDto();
    dto.WarningReferenceCategoryId = this.warningReferenceCategoryId;

    const dtoOptions: TargetOptionTranslationDto[] = [];
    this.options.forEach((o) => {
      const dtoOption = o.copyToDto();
      dtoOptions.push(dtoOption);
    });
    dto.TargetOptions = dtoOptions;

    return dto;
  }

  public options: TargetOptionTranslation[] = [];
  public exogenousCategory: ExogenousCategory;
  public errorReferenceCategoryId: number;
  public warningReferenceCategoryId: number;
}
