import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SearchResultDto } from "../../../../employee-common/models/cms/dto/SearchResult-dto";

@Injectable()
export class SearchService extends PlBaseService {
  private endPoint: string = "search";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getSearchData(query: string): Observable<SearchResultDto[]> {
    return this.getData<SearchResultDto[]>(`${this.endPoint}/${query}`);
  }
}
