import { Formulas } from "../../../../../../common/modelelements";
import { SourceSpecificationsWithMetaDataDto } from "../../../../../../employee-common/models/choices/dto/SourceSpecificationsWithMetaData-dto";
import { SourceSpecifications } from "./source-specifications.model";

export class SourceSpecificationsWithMetaData {
  public constructor() {
    this.formulas = new Formulas([]);
    this.sourceSpecifications = new SourceSpecifications([]);
  }
  public copyFromDto(dto: SourceSpecificationsWithMetaDataDto) {
    this.formulas.copyFromDto(dto.Formulas);
    this.sourceSpecifications.copyFromDto(dto.SourceSpecifications);
  }
  public formulas: Formulas;
  public sourceSpecifications: SourceSpecifications;
}
