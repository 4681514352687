import { Injectable } from '@angular/core';
import { WebLanguage } from '../../../../../common/language/weblanguage.service';
import { PlLanguageService } from '../../../../../angular-common/language/pllanguage.service';

@Injectable()
//t4Languages": "0.8.2.0
export class SimulateLanguage extends WebLanguage {
  public constructor(plLanguageService: PlLanguageService) {
    super(plLanguageService);
  }

  /**
   * f3MeWebApiMenuSimulate from Simulate.csv (in EN: Simulate )
   */
  public key_f3MeWebApiMenuSimulate = "f3MeWebApiMenuSimulate";
  /**
   * f3MeWebApiMenuSimulate from Simulate.csv (in EN: Simulate )
   */
  public get f3MeWebApiMenuSimulate(): string { return this.getStringDef(this.key_f3MeWebApiMenuSimulate, "Simulate"); }

  /**
   * f3MeWebApiSimulateHeader from Simulate.csv (in EN: Simulate )
   */
  public key_f3MeWebApiSimulateHeader = "f3MeWebApiSimulateHeader";
  /**
   * f3MeWebApiSimulateHeader from Simulate.csv (in EN: Simulate )
   */
  public get f3MeWebApiSimulateHeader(): string { return this.getStringDef(this.key_f3MeWebApiSimulateHeader, "Simulate"); }

  /**
   * f3MeWebApiSimulateHeaderEditHeader from Simulate.csv (in EN: Header for simulation )
   */
  public key_f3MeWebApiSimulateHeaderEditHeader = "f3MeWebApiSimulateHeaderEditHeader";
  /**
   * f3MeWebApiSimulateHeaderEditHeader from Simulate.csv (in EN: Header for simulation )
   */
  public get f3MeWebApiSimulateHeaderEditHeader(): string { return this.getStringDef(this.key_f3MeWebApiSimulateHeaderEditHeader, "Header for simulation"); }

  /**
   * f3MeWebApiSimulateIntro from Simulate.csv (in EN: Simulate intro )
   */
  public key_f3MeWebApiSimulateIntro = "f3MeWebApiSimulateIntro";
  /**
   * f3MeWebApiSimulateIntro from Simulate.csv (in EN: Simulate intro )
   */
  public get f3MeWebApiSimulateIntro(): string { return this.getStringDef(this.key_f3MeWebApiSimulateIntro, "Simulate intro"); }

  /**
   * f3MeWebApiSimulateIntroEditHeader from Simulate.csv (in EN: Simulation intro )
   */
  public key_f3MeWebApiSimulateIntroEditHeader = "f3MeWebApiSimulateIntroEditHeader";
  /**
   * f3MeWebApiSimulateIntroEditHeader from Simulate.csv (in EN: Simulation intro )
   */
  public get f3MeWebApiSimulateIntroEditHeader(): string { return this.getStringDef(this.key_f3MeWebApiSimulateIntroEditHeader, "Simulation intro"); }

  /**
   * f3MeWebApiMenuManagementSimEdit from Simulate.csv (in EN: Simulation )
   */
  public key_f3MeWebApiMenuManagementSimEdit = "f3MeWebApiMenuManagementSimEdit";
  /**
   * f3MeWebApiMenuManagementSimEdit from Simulate.csv (in EN: Simulation )
   */
  public get f3MeWebApiMenuManagementSimEdit(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementSimEdit, "Simulation"); }

  /**
   * f3MeWebApiMenuManagementSimulationLanguageEdit from Simulate.csv (in EN: Simulation texts )
   */
  public key_f3MeWebApiMenuManagementSimulationLanguageEdit = "f3MeWebApiMenuManagementSimulationLanguageEdit";
  /**
   * f3MeWebApiMenuManagementSimulationLanguageEdit from Simulate.csv (in EN: Simulation texts )
   */
  public get f3MeWebApiMenuManagementSimulationLanguageEdit(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementSimulationLanguageEdit, "Simulation texts"); }


}
