import { Component, OnInit } from "@angular/core";

import { ChoiceDefinition } from "../../../../employee-common/choices/targets/targetdefinition/target-definition.model";
import { ChoicesLanguage } from "../../../../employee-common/choices/language/choiceslanguage.service";
import { ChoicesService } from "../../../../employee-common/choices/targets/choices.service";
import { ICMSToTargetDictionary } from "../../../../employee-common/choices/targets/targetdefinition/cms-target-definitions.model";
import { InspireLanguageService } from "../shared/language/inspire-language.service";

@Component({
  selector: "app-myorders-component",
  templateUrl: "./myorders.component.html",
  styleUrls: ["./myorders.component.scss"],
})
export class MyOrdersComponent implements OnInit {
  public targets: ChoiceDefinition[] = [];
  public constructor(public languageService: InspireLanguageService, public choicesLanguage: ChoicesLanguage, private targetsService: ChoicesService) {}

  ngOnInit(): void {
    this.targetsService.getTargets().subscribe((result) => (this.targets = MyOrdersComponent.convertToTargetDefintion(result)));
  }

  private static convertToTargetDefintion(targetDic: ICMSToTargetDictionary) {
    const targetsNew: ChoiceDefinition[] = [];
    for (let key in targetDic) {
      let value = targetDic[key];
      value.forEach((x) => targetsNew.push(x));
    }

    return targetsNew;
  }
}
