import { Injectable } from "@angular/core";
import { LanguageServiceBase } from "../../../../../employee-common/components/language/language-base.service";
import { PersonlizedLanguageService } from "../../../../../employee-common/components/language/personlized-language.service";
import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";
import { PlLanguageService } from "../../../../../angular-common";

@Injectable()
export class InspireLanguageService extends LanguageServiceBase {
  constructor(plLanguageService: PlLanguageService, languageSelection: PlLanguageSelection, personlizedLanguageService: PersonlizedLanguageService) {
    super(plLanguageService, languageSelection, personlizedLanguageService);
  }

  /**
   * applicationTitle from f3MeWebApi.csv (in EN: Inspire )
   */
  public key_applicationTitle = "applicationTitle";
  /**
   * applicationTitle from f3MeWebApi.csv (in EN: Inspire )
   */
  public get applicationTitle(): string { return this.getStringDef(this.key_applicationTitle, "Inspire"); }

  /**
   * f3MeWebApiAppName from f3MeWebApi.csv (in EN: Inspire )
   */
  public key_f3MeWebApiAppName = "f3MeWebApiAppName";
  /**
   * f3MeWebApiAppName from f3MeWebApi.csv (in EN: Inspire )
   */
  public get f3MeWebApiAppName(): string { return this.getStringDef(this.key_f3MeWebApiAppName, "Inspire"); }

  /**
   * f3MeWebApiNL from f3MeWebApi.csv (in EN: Dutch )
   */
  public key_f3MeWebApiNL = "f3MeWebApiNL";
  /**
   * f3MeWebApiNL from f3MeWebApi.csv (in EN: Dutch )
   */
  public get f3MeWebApiNL(): string { return this.getStringDef(this.key_f3MeWebApiNL, "Dutch"); }

  /**
   * f3MeWebApiEN from f3MeWebApi.csv (in EN: English )
   */
  public key_f3MeWebApiEN = "f3MeWebApiEN";
  /**
   * f3MeWebApiEN from f3MeWebApi.csv (in EN: English )
   */
  public get f3MeWebApiEN(): string { return this.getStringDef(this.key_f3MeWebApiEN, "English"); }

  /**
   * f3MeWebApiFR from f3MeWebApi.csv (in EN: French )
   */
  public key_f3MeWebApiFR = "f3MeWebApiFR";
  /**
   * f3MeWebApiFR from f3MeWebApi.csv (in EN: French )
   */
  public get f3MeWebApiFR(): string { return this.getStringDef(this.key_f3MeWebApiFR, "French"); }

  /**
   * f3MeWebApiDE from f3MeWebApi.csv (in EN: German )
   */
  public key_f3MeWebApiDE = "f3MeWebApiDE";
  /**
   * f3MeWebApiDE from f3MeWebApi.csv (in EN: German )
   */
  public get f3MeWebApiDE(): string { return this.getStringDef(this.key_f3MeWebApiDE, "German"); }

  /**
   * f3MeWebApiErrorHeader from f3MeWebApi.csv (in EN: Error )
   */
  public key_f3MeWebApiErrorHeader = "f3MeWebApiErrorHeader";
  /**
   * f3MeWebApiErrorHeader from f3MeWebApi.csv (in EN: Error )
   */
  public get f3MeWebApiErrorHeader(): string { return this.getStringDef(this.key_f3MeWebApiErrorHeader, "Error"); }

  /**
   * f3MeWebApiErrorDescr from f3MeWebApi.csv (in EN: An error has occured: )
   */
  public key_f3MeWebApiErrorDescr = "f3MeWebApiErrorDescr";
  /**
   * f3MeWebApiErrorDescr from f3MeWebApi.csv (in EN: An error has occured: )
   */
  public get f3MeWebApiErrorDescr(): string { return this.getStringDef(this.key_f3MeWebApiErrorDescr, "An error has occured:"); }

  /**
   * f3MeWebApiFileExtNotAllowed from f3MeWebApi.csv (in EN: File type not supported, only {0} is allowed )
   */
  public key_f3MeWebApiFileExtNotAllowed = "f3MeWebApiFileExtNotAllowed";
  /**
   * f3MeWebApiFileExtNotAllowed from f3MeWebApi.csv (in EN: File type not supported, only {0} is allowed )
   */
  public f3MeWebApiFileExtNotAllowed(val1): string { return this.getStringDef("f3MeWebApiFileExtNotAllowed", "File type not supported, only {0} is allowed").replace("{0}", val1); }

  /**
   * f3MeWebApiFlexBenefitsHeader from f3MeWebApi.csv (in EN: Flex. benefits header )
   */
  public key_f3MeWebApiFlexBenefitsHeader = "f3MeWebApiFlexBenefitsHeader";
  /**
   * f3MeWebApiFlexBenefitsHeader from f3MeWebApi.csv (in EN: Flex. benefits header )
   */
  public get f3MeWebApiFlexBenefitsHeader(): string { return this.getStringDef(this.key_f3MeWebApiFlexBenefitsHeader, "Flex. benefits header"); }

  /**
   * f3MeWebApiFlexBenefitsIntro from f3MeWebApi.csv (in EN: Flex. benefits intro )
   */
  public key_f3MeWebApiFlexBenefitsIntro = "f3MeWebApiFlexBenefitsIntro";
  /**
   * f3MeWebApiFlexBenefitsIntro from f3MeWebApi.csv (in EN: Flex. benefits intro )
   */
  public get f3MeWebApiFlexBenefitsIntro(): string { return this.getStringDef(this.key_f3MeWebApiFlexBenefitsIntro, "Flex. benefits intro"); }

  /**
   * f3MeWebApiRequestHeader from f3MeWebApi.csv (in EN: Request header )
   */
  public key_f3MeWebApiRequestHeader = "f3MeWebApiRequestHeader";
  /**
   * f3MeWebApiRequestHeader from f3MeWebApi.csv (in EN: Request header )
   */
  public get f3MeWebApiRequestHeader(): string { return this.getStringDef(this.key_f3MeWebApiRequestHeader, "Request header"); }

  /**
   * f3MeWebApiRequestIntro from f3MeWebApi.csv (in EN: Request intro )
   */
  public key_f3MeWebApiRequestIntro = "f3MeWebApiRequestIntro";
  /**
   * f3MeWebApiRequestIntro from f3MeWebApi.csv (in EN: Request intro )
   */
  public get f3MeWebApiRequestIntro(): string { return this.getStringDef(this.key_f3MeWebApiRequestIntro, "Request intro"); }

  /**
   * f3MeWebApiLoginExternalSystem from f3MeWebApi.csv (in EN: Press the Login button below enter Inspire. )
   */
  public key_f3MeWebApiLoginExternalSystem = "f3MeWebApiLoginExternalSystem";
  /**
   * f3MeWebApiLoginExternalSystem from f3MeWebApi.csv (in EN: Press the Login button below enter Inspire. )
   */
  public get f3MeWebApiLoginExternalSystem(): string { return this.getStringDef(this.key_f3MeWebApiLoginExternalSystem, "Press the Login button below enter Inspire."); }

  /**
   * f3MeWebApiNoDataForUser from f3MeWebApi.csv (in EN: The system contains no data for you. )
   */
  public key_f3MeWebApiNoDataForUser = "f3MeWebApiNoDataForUser";
  /**
   * f3MeWebApiNoDataForUser from f3MeWebApi.csv (in EN: The system contains no data for you. )
   */
  public get f3MeWebApiNoDataForUser(): string { return this.getStringDef(this.key_f3MeWebApiNoDataForUser, "The system contains no data for you."); }

  /**
   * f3MeWebApiLoginUserName from f3MeWebApi.csv (in EN: Username: )
   */
  public key_f3MeWebApiLoginUserName = "f3MeWebApiLoginUserName";
  /**
   * f3MeWebApiLoginUserName from f3MeWebApi.csv (in EN: Username: )
   */
  public get f3MeWebApiLoginUserName(): string { return this.getStringDef(this.key_f3MeWebApiLoginUserName, "Username:"); }

  /**
   * f3MeWebApiLoginPassword from f3MeWebApi.csv (in EN: Password: )
   */
  public key_f3MeWebApiLoginPassword = "f3MeWebApiLoginPassword";
  /**
   * f3MeWebApiLoginPassword from f3MeWebApi.csv (in EN: Password: )
   */
  public get f3MeWebApiLoginPassword(): string { return this.getStringDef(this.key_f3MeWebApiLoginPassword, "Password:"); }

  /**
   * f3MeWebApiLoginButtonLogin from f3MeWebApi.csv (in EN: Login )
   */
  public key_f3MeWebApiLoginButtonLogin = "f3MeWebApiLoginButtonLogin";
  /**
   * f3MeWebApiLoginButtonLogin from f3MeWebApi.csv (in EN: Login )
   */
  public get f3MeWebApiLoginButtonLogin(): string { return this.getStringDef(this.key_f3MeWebApiLoginButtonLogin, "Login"); }

  /**
   * f3MeWebApiLoginUserNameRequired from f3MeWebApi.csv (in EN: Username is required. )
   */
  public key_f3MeWebApiLoginUserNameRequired = "f3MeWebApiLoginUserNameRequired";
  /**
   * f3MeWebApiLoginUserNameRequired from f3MeWebApi.csv (in EN: Username is required. )
   */
  public get f3MeWebApiLoginUserNameRequired(): string { return this.getStringDef(this.key_f3MeWebApiLoginUserNameRequired, "Username is required."); }

  /**
   * f3MeWebApiLoginPasswordRequired from f3MeWebApi.csv (in EN: Password is required. )
   */
  public key_f3MeWebApiLoginPasswordRequired = "f3MeWebApiLoginPasswordRequired";
  /**
   * f3MeWebApiLoginPasswordRequired from f3MeWebApi.csv (in EN: Password is required. )
   */
  public get f3MeWebApiLoginPasswordRequired(): string { return this.getStringDef(this.key_f3MeWebApiLoginPasswordRequired, "Password is required."); }

  /**
   * f3MeWebApiLoginLoginFailed from f3MeWebApi.csv (in EN: Incorrect username or password. )
   */
  public key_f3MeWebApiLoginLoginFailed = "f3MeWebApiLoginLoginFailed";
  /**
   * f3MeWebApiLoginLoginFailed from f3MeWebApi.csv (in EN: Incorrect username or password. )
   */
  public get f3MeWebApiLoginLoginFailed(): string { return this.getStringDef(this.key_f3MeWebApiLoginLoginFailed, "Incorrect username or password."); }

  /**
   * f3MeWebApiLoginLoginError from f3MeWebApi.csv (in EN: Unknown error. )
   */
  public key_f3MeWebApiLoginLoginError = "f3MeWebApiLoginLoginError";
  /**
   * f3MeWebApiLoginLoginError from f3MeWebApi.csv (in EN: Unknown error. )
   */
  public get f3MeWebApiLoginLoginError(): string { return this.getStringDef(this.key_f3MeWebApiLoginLoginError, "Unknown error."); }

  /**
   * f3MeWebApiLoginHeader from f3MeWebApi.csv (in EN: Login to Inspire )
   */
  public key_f3MeWebApiLoginHeader = "f3MeWebApiLoginHeader";
  /**
   * f3MeWebApiLoginHeader from f3MeWebApi.csv (in EN: Login to Inspire )
   */
  public get f3MeWebApiLoginHeader(): string { return this.getStringDef(this.key_f3MeWebApiLoginHeader, "Login to Inspire"); }

  /**
   * f3MeAppTextEditId from f3MeWebApi.csv (in EN: Target )
   */
  public key_f3MeAppTextEditId = "f3MeAppTextEditId";
  /**
   * f3MeAppTextEditId from f3MeWebApi.csv (in EN: Target )
   */
  public get f3MeAppTextEditId(): string { return this.getStringDef(this.key_f3MeAppTextEditId, "Target"); }

  /**
   * f3MeAppTextEditValue from f3MeWebApi.csv (in EN: Current content )
   */
  public key_f3MeAppTextEditValue = "f3MeAppTextEditValue";
  /**
   * f3MeAppTextEditValue from f3MeWebApi.csv (in EN: Current content )
   */
  public get f3MeAppTextEditValue(): string { return this.getStringDef(this.key_f3MeAppTextEditValue, "Current content"); }

  /**
   * f3MeAppTextEditActions from f3MeWebApi.csv (in EN: * )
   */
  public key_f3MeAppTextEditActions = "f3MeAppTextEditActions";
  /**
   * f3MeAppTextEditActions from f3MeWebApi.csv (in EN: * )
   */
  public get f3MeAppTextEditActions(): string { return this.getStringDef(this.key_f3MeAppTextEditActions, "*"); }

  /**
   * f3MeWebApiMenuManagementSimulationLanguageEditPostfix from f3MeWebApi.csv (in EN: Postfix )
   */
  public key_f3MeWebApiMenuManagementSimulationLanguageEditPostfix = "f3MeWebApiMenuManagementSimulationLanguageEditPostfix";
  /**
   * f3MeWebApiMenuManagementSimulationLanguageEditPostfix from f3MeWebApi.csv (in EN: Postfix )
   */
  public get f3MeWebApiMenuManagementSimulationLanguageEditPostfix(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementSimulationLanguageEditPostfix, "Postfix"); }

  /**
   * f3MeWebApiMenuManagementVariantSelection from f3MeWebApi.csv (in EN: Variant configuration )
   */
  public key_f3MeWebApiMenuManagementVariantSelection = "f3MeWebApiMenuManagementVariantSelection";
  /**
   * f3MeWebApiMenuManagementVariantSelection from f3MeWebApi.csv (in EN: Variant configuration )
   */
  public get f3MeWebApiMenuManagementVariantSelection(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementVariantSelection, "Variant configuration"); }

  /**
   * f3MeWebApiMenuRatings from f3MeWebApi.csv (in EN: Ratings )
   */
  public key_f3MeWebApiMenuRatings = "f3MeWebApiMenuRatings";
  /**
   * f3MeWebApiMenuRatings from f3MeWebApi.csv (in EN: Ratings )
   */
  public get f3MeWebApiMenuRatings(): string { return this.getStringDef(this.key_f3MeWebApiMenuRatings, "Ratings"); }

  /**
   * f3MeWebApiEditSelectVariant from f3MeWebApi.csv (in EN: Select new variant )
   */
  public key_f3MeWebApiEditSelectVariant = "f3MeWebApiEditSelectVariant";
  /**
   * f3MeWebApiEditSelectVariant from f3MeWebApi.csv (in EN: Select new variant )
   */
  public get f3MeWebApiEditSelectVariant(): string { return this.getStringDef(this.key_f3MeWebApiEditSelectVariant, "Select new variant"); }

  /**
   * f3MeWebApiCurrentVariant from f3MeWebApi.csv (in EN: Current variant )
   */
  public key_f3MeWebApiCurrentVariant = "f3MeWebApiCurrentVariant";
  /**
   * f3MeWebApiCurrentVariant from f3MeWebApi.csv (in EN: Current variant )
   */
  public get f3MeWebApiCurrentVariant(): string { return this.getStringDef(this.key_f3MeWebApiCurrentVariant, "Current variant"); }

  /**
   * f3MeWebApiComponent from f3MeWebApi.csv (in EN: Component )
   */
  public key_f3MeWebApiComponent = "f3MeWebApiComponent";
  /**
   * f3MeWebApiComponent from f3MeWebApi.csv (in EN: Component )
   */
  public get f3MeWebApiComponent(): string { return this.getStringDef(this.key_f3MeWebApiComponent, "Component"); }

  /**
   * f3MeWebApiSearch from f3MeWebApi.csv (in EN: Search )
   */
  public key_f3MeWebApiSearch = "f3MeWebApiSearch";
  /**
   * f3MeWebApiSearch from f3MeWebApi.csv (in EN: Search )
   */
  public get f3MeWebApiSearch(): string { return this.getStringDef(this.key_f3MeWebApiSearch, "Search"); }

  /**
   * f3MeWebApiSearchResultAmount from f3MeWebApi.csv (in EN: results with term )
   */
  public key_f3MeWebApiSearchResultAmount = "f3MeWebApiSearchResultAmount";
  /**
   * f3MeWebApiSearchResultAmount from f3MeWebApi.csv (in EN: results with term )
   */
  public get f3MeWebApiSearchResultAmount(): string { return this.getStringDef(this.key_f3MeWebApiSearchResultAmount, "results with term"); }

  /**
   * f3MeWebApiNavigate from f3MeWebApi.csv (in EN: Navigate )
   */
  public key_f3MeWebApiNavigate = "f3MeWebApiNavigate";
  /**
   * f3MeWebApiNavigate from f3MeWebApi.csv (in EN: Navigate )
   */
  public get f3MeWebApiNavigate(): string { return this.getStringDef(this.key_f3MeWebApiNavigate, "Navigate"); }

  /**
   * f3MeWebApiSmartEbs from f3MeWebApi.csv (in EN: Smart Ebs )
   */
  public key_f3MeWebApiSmartEbs = "f3MeWebApiSmartEbs";
  /**
   * f3MeWebApiSmartEbs from f3MeWebApi.csv (in EN: Smart Ebs )
   */
  public get f3MeWebApiSmartEbs(): string { return this.getStringDef(this.key_f3MeWebApiSmartEbs, "Smart Ebs"); }

  /**
   * f3MeWebApiSmartEbsUploaderInfo from f3MeWebApi.csv (in EN: Drag the file you want to upload to here )
   */
  public key_f3MeWebApiSmartEbsUploaderInfo = "f3MeWebApiSmartEbsUploaderInfo";
  /**
   * f3MeWebApiSmartEbsUploaderInfo from f3MeWebApi.csv (in EN: Drag the file you want to upload to here )
   */
  public get f3MeWebApiSmartEbsUploaderInfo(): string { return this.getStringDef(this.key_f3MeWebApiSmartEbsUploaderInfo, "Drag the file you want to upload to here"); }

  /**
   * f3MeWebApiSmartEbsSelecterInfo from f3MeWebApi.csv (in EN: After the upload is complete the EBS Items will appear hereh, you can drag them to the right to add them to a catagory )
   */
  public key_f3MeWebApiSmartEbsSelecterInfo = "f3MeWebApiSmartEbsSelecterInfo";
  /**
   * f3MeWebApiSmartEbsSelecterInfo from f3MeWebApi.csv (in EN: After the upload is complete the EBS Items will appear hereh, you can drag them to the right to add them to a catagory )
   */
  public get f3MeWebApiSmartEbsSelecterInfo(): string { return this.getStringDef(this.key_f3MeWebApiSmartEbsSelecterInfo, "After the upload is complete the EBS Items will appear hereh, you can drag them to the right to add them to a catagory"); }

  /**
   * f3MeWebApiSmartEbsChartInfo from f3MeWebApi.csv (in EN: This is where you will see the results of the catagories )
   */
  public key_f3MeWebApiSmartEbsChartInfo = "f3MeWebApiSmartEbsChartInfo";
  /**
   * f3MeWebApiSmartEbsChartInfo from f3MeWebApi.csv (in EN: This is where you will see the results of the catagories )
   */
  public get f3MeWebApiSmartEbsChartInfo(): string { return this.getStringDef(this.key_f3MeWebApiSmartEbsChartInfo, "This is where you will see the results of the catagories"); }

  /**
   * f3MeWebApiSmartEbsUploadComplete from f3MeWebApi.csv (in EN: Upload complete! Matching of EBS Items started... )
   */
  public key_f3MeWebApiSmartEbsUploadComplete = "f3MeWebApiSmartEbsUploadComplete";
  /**
   * f3MeWebApiSmartEbsUploadComplete from f3MeWebApi.csv (in EN: Upload complete! Matching of EBS Items started... )
   */
  public get f3MeWebApiSmartEbsUploadComplete(): string { return this.getStringDef(this.key_f3MeWebApiSmartEbsUploadComplete, "Upload complete! Matching of EBS Items started..."); }

  /**
   * f3MeWebApiSmartEbsUploadFailed from f3MeWebApi.csv (in EN: Upload failed. Please check the file )
   */
  public key_f3MeWebApiSmartEbsUploadFailed = "f3MeWebApiSmartEbsUploadFailed";
  /**
   * f3MeWebApiSmartEbsUploadFailed from f3MeWebApi.csv (in EN: Upload failed. Please check the file )
   */
  public get f3MeWebApiSmartEbsUploadFailed(): string { return this.getStringDef(this.key_f3MeWebApiSmartEbsUploadFailed, "Upload failed. Please check the file"); }

  /**
   * f3MeWebApiSmartEbsArrangeComeplete from f3MeWebApi.csv (in EN: Matching complete, we have found {0} matches )
   */
  public key_f3MeWebApiSmartEbsArrangeComeplete = "f3MeWebApiSmartEbsArrangeComeplete";
  /**
   * f3MeWebApiSmartEbsArrangeComeplete from f3MeWebApi.csv (in EN: Matching complete, we have found {0} matches )
   */
  public f3MeWebApiSmartEbsArrangeComeplete(val1): string { return this.getStringDef("f3MeWebApiSmartEbsArrangeComeplete", "Matching complete, we have found {0} matches").replace("{0}", val1); }

  /**
   * f3MeWebApiSmartEbsDragBox from f3MeWebApi.csv (in EN: Drag the file here, or click to select a file )
   */
  public key_f3MeWebApiSmartEbsDragBox = "f3MeWebApiSmartEbsDragBox";
  /**
   * f3MeWebApiSmartEbsDragBox from f3MeWebApi.csv (in EN: Drag the file here, or click to select a file )
   */
  public get f3MeWebApiSmartEbsDragBox(): string { return this.getStringDef(this.key_f3MeWebApiSmartEbsDragBox, "Drag the file here, or click to select a file"); }

  /**
   * f3MeWebApiMyOrdersIntro from f3MeWebApi.csv (in EN: On this page you find an overview of all placed orderd. The orders are grouped by open and processed orders.  )
   */
  public key_f3MeWebApiMyOrdersIntro = "f3MeWebApiMyOrdersIntro";
  /**
   * f3MeWebApiMyOrdersIntro from f3MeWebApi.csv (in EN: On this page you find an overview of all placed orderd. The orders are grouped by open and processed orders.  )
   */
  public get f3MeWebApiMyOrdersIntro(): string { return this.getStringDef(this.key_f3MeWebApiMyOrdersIntro, "On this page you find an overview of all placed orderd. The orders are grouped by open and processed orders. "); }

  /**
   * f3MeWebApiMenuUserInsights from f3MeWebApi.csv (in EN: Userlogin insights )
   */
  public key_f3MeWebApiMenuUserInsights = "f3MeWebApiMenuUserInsights";
  /**
   * f3MeWebApiMenuUserInsights from f3MeWebApi.csv (in EN: Userlogin insights )
   */
  public get f3MeWebApiMenuUserInsights(): string { return this.getStringDef(this.key_f3MeWebApiMenuUserInsights, "Userlogin insights"); }

}
