import { Component } from "@angular/core";

@Component({
  selector: "app-userlogins-dashboard",
  templateUrl: "./userlogins-dashboard.component.html",
  styleUrls: ["./userlogins-dashboard.component.scss"],
})
export class UserLoginsDashboardComponent {
  constructor() {}
}
