<div class="blocks">
    <h2 class="lc-group-header">{{ languageService.f3MeWebApiTargetStatusWaitingOrders }}</h2>
    <div *ngFor="let target of waitingTargets;">
        <div [ngClass]="isOddIndex === false ? 'cb' : ''" class="col-md-6 col-sm-6 col-xs-12">

            <h6 class="lc-header" style="word-wrap: break-word;">{{ target.title }}</h6>
            <div class="tdlink">
                <label class="tdhref" (click)="goToTargetDefinition(target)">{{target.title}}</label>
            </div>
        </div>
    </div>
</div>
<div class="blocks">
    <h2 class="lc-group-header">{{languageService.f3MeWebApiTargetStatusApproved}}</h2>
    <div *ngFor="let target of approvedTargets;">
        <div [ngClass]="isOddIndex === false ? 'cb' : ''" class="col-md-6 col-sm-6 col-xs-12">

            <h6 class="lc-header" style="word-wrap: break-word;">{{ target.title }}</h6>
            <div class="tdlink">
                <label class="tdhref" (click)="goToTargetDefinition(target)">{{target.title}}</label>
            </div>
        </div>
    </div>
</div>

<div class="cms-link">
    <app-cms-link [link]="filesLink"> </app-cms-link>
</div>