import { DxButtonModule, DxFormModule, DxTextBoxModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SearchResultModule } from "./search-result/search-result.module";
import { SearchScreenComponent } from "./search-screen.component";
import { SearchService } from "./search.service";

@NgModule({
  imports: [CommonModule, DxButtonModule, DxFormModule, DxTextBoxModule, SearchResultModule],
  declarations: [SearchScreenComponent],
  providers: [SearchService],
})
export class SearchScreenModule {}
