import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";

@Injectable()
export class StatusService extends PlBaseService {
  public success: boolean;

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
    this.success = false;
  }

  public loadStatus(): Promise<boolean> {
    const uri = this.retrieveBaseUriNoApi() + "status.json";
    console.log("start loading status");
    const promise = this.getDataTransformedWithoutLoader<boolean>(uri).toPromise();

    promise.then(r => (this.success = r)).catch(e => console.log("Error loading status"));

    return promise;
  }

  private retrieveBaseUriNoApi() {
    let baseUri = this.envSettings.baseUri();
    if (baseUri.length > 0) {
      if (baseUri.endsWith("api/")) {
        baseUri = baseUri.substr(0, baseUri.length - 4);
      } else if (baseUri.endsWith("api")) {
        baseUri = baseUri.substr(0, baseUri.length - 3);
      }
    }
    if (baseUri.length > 0 && baseUri[baseUri.length - 1] !== "/") {
      baseUri = baseUri + "/";
    }
    return baseUri;
  }
}
