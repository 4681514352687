import { Component, OnInit } from "@angular/core";
import { ExogenousCategories, ExogenousVariable, ExogenousVariables } from "../../../../common/modelelements";

import { map } from "rxjs/operators";
import { TargetSettings } from "../../../../employee-common/choices/targets/targetsettings";
import { InspireLanguageService } from "../shared/language/inspire-language.service";
import { ExogenousVariablesEditService } from "./service/exogenous-variables-edit.service";

@Component({
  selector: "app-targetfields-edit",
  templateUrl: "./target-fields-edit.component.html",
  styleUrls: ["./target-fields-edit.component.scss"],
})
export class TargetFieldsEditComponent implements OnInit {
  public constructor(public language: InspireLanguageService, private exogenousVariablesEditService: ExogenousVariablesEditService) {}

  ngOnInit(): void {
    this.exogenousVariablesEditService
      .getAll()
      .pipe(
        map((exogsWithCats) => {
          this.exogenousVariables = exogsWithCats.exogenousVariables;
          this.exogenousCategories = exogsWithCats.exogenousCategories;
        }),
      )
      .subscribe();
  }

  public setTarget(newTarget: TargetSettings) {
    this.target = newTarget;
    this.setExogFilter();
  }
  public target: TargetSettings;

  public exogenousVariables: ExogenousVariables;
  public exogenousCategories: ExogenousCategories;

  public get contentLoaded(): boolean {
    return this.exogenousCategories !== undefined && this.exogenousVariables !== undefined;
  }

  public setExogFilter() {
    if (this.target && this.exogenousVariables) {
      this._filteredExogenousVariables = this.exogenousVariables.getFilteredItems(this.target.exoCategoryRefId);
    } else {
      this._filteredExogenousVariables = undefined;
    }
  }

  public get filteredExogenousVariables(): ExogenousVariables {
    if (this._filteredExogenousVariables) {
      return this._filteredExogenousVariables;
    } else {
      return this.exogenousVariables;
    }
  }
  private _filteredExogenousVariables: ExogenousVariables;

  public onExogUpdated(exogenousVariable: ExogenousVariable) {
    this.exogenousVariablesEditService.updateExogenousVariable(exogenousVariable).subscribe();
  }
}
