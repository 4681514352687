import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { TargetSettingsDto } from "../../models/choices/dto/TargetSettingsDto-dto";

export class TargetSettings {
  public static isNew(value: TargetSettings): boolean {
    return value.keyId === -1 || value.keyId === undefined;
  }
  public static toDto(target: TargetSettings): TargetSettingsDto {
    const dto = new TargetSettingsDto();

    dto.KeyId = target.keyId;
    dto.Description = target.description;
    dto.ExoCategoryRefId = target.exoCategoryRefId;
    dto.IsSelected = target.isSelected;
    dto.SortOrder = target.sortOrder;
    dto.ShortName = target.shortName;
    dto.Status = target.status;
    dto.StatusChangeDate = target.statusChangeDate;
    dto.TotalRemaining = target.totalRemaining;
    dto.TotalRequired = target.totalRequired;
    dto.TotalSupplied = target.totalSupplied;
    dto.AllowSubmit = target.allowSubmit;
    dto.Title = target.titles;

    return dto;
  }

  public static createDefault(language: CmsLanguage, selectLanguage: PlLanguageSelection): TargetSettings {
    const builder = language.languageBuilder();

    const newTarget = new TargetSettings();
    newTarget.allowSubmit = "";
    newTarget.description = builder.copyLanguageItem(language.getEntry(language.key_f3MeWebApiLinkLabourConditionNoTitle));
    newTarget.exoCategoryRefId = -1;
    newTarget.shortName = "shortName";
    newTarget.keyId = -1;
    newTarget.sortOrder = 0;
    newTarget.status = "";
    newTarget.statusChangeDate = "";
    newTarget.totalRemaining = "";
    newTarget.totalRequired = "";
    newTarget.totalSupplied = "";
    newTarget.isSelected = "";
    newTarget.titles = builder.copyLanguageItem(language.getEntry(language.key_f3MeWebApiLinkLabourConditionNoTitle));
    newTarget.selectLanguage = selectLanguage;

    return newTarget;
  }

  private selectLanguage: PlLanguageSelection;
  public keyId: number;
  public description: LanguageItem;
  public exoCategoryRefId: number;
  public isSelected: string;
  public sortOrder: number;
  public shortName: string;
  public statusChangeDate: string;
  public status: string;
  public totalRemaining: string;
  public totalRequired: string;
  public totalSupplied: string;
  public allowSubmit: string;
  public exogenousCategories: string[] = [];
  public titles: LanguageItem;

  public get title(): string {
    return this.selectLanguage.currentValueFor(this.titles);
  }

  public fromDto(dto: TargetSettingsDto, selectLanguage: PlLanguageSelection) {
    this.keyId = dto.KeyId;
    this.description = dto.Description;
    this.exoCategoryRefId = dto.ExoCategoryRefId;
    this.isSelected = dto.IsSelected;
    this.sortOrder = dto.SortOrder;
    this.shortName = dto.ShortName;
    this.status = dto.Status;
    this.statusChangeDate = dto.StatusChangeDate;
    this.totalRemaining = dto.TotalRemaining;
    this.totalRequired = dto.TotalRequired;
    this.totalSupplied = dto.TotalSupplied;
    this.allowSubmit = dto.AllowSubmit;
    this.titles = dto.Title;
    this.selectLanguage = selectLanguage;
  }
}
