
export declare type TargetOptionChoiceTranslationDescriptionDto = [string, string];
// t4tsCode gen": "0.8.2.0 for file TargetOptionChoiceTranslation.cs
// Don't adjust manually!

export class TargetOptionChoiceTranslationDto {
    public CategoryId: number;
    public ReferenceId: number;
    public CoreDescription: string;
    public Description: TargetOptionChoiceTranslationDescriptionDto;
}

