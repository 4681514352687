import { PlLanguageSelection } from "../../../../../../angular-common/language/pl-language-selection";
import { ExogenousVariables } from "../../../../../../common/modelelements";
import { TargetSettings } from "../../../../../../employee-common/choices/targets/targetsettings";
import { SourceEntrySpecificationsMetaDataDto } from "../../../../../../employee-common/models/choices/dto/SourceEntrySpecificationsMetaData-dto";

export class SourceEntrySpecificationsMetaData {
  public constructor() {
    this.targets = [];
    this.exogenousVariables = new ExogenousVariables([]);
  }

  public targets: TargetSettings[];
  public exogenousVariables: ExogenousVariables;

  public copyFromDto(dto: SourceEntrySpecificationsMetaDataDto, languageSelection: PlLanguageSelection) {
    dto.Targets.forEach((targetDto) => {
      const ts = new TargetSettings();
      ts.fromDto(targetDto, languageSelection);
      this.targets.push(ts);
    });
    this.exogenousVariables.copyFromDto(dto.ExogenousVariables);
  }
}
