<div>
  <div class="row">
    <div class="col-md-4">
      <app-smart-ebs-uploader id="uploader" (uploadResult)="uploadComplete($event)"></app-smart-ebs-uploader>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <app-smart-ebs-chart id="chart" [ebsGroups]="ebsGroups$"></app-smart-ebs-chart>
    </div>
    <div *ngIf="allDone" class="pyro">
      <div class="before"></div>
      <div class="after"></div>
    </div>
  </div>
  <div class="row" [ngClass]="uploaded ? [''] : ['selecter-not-uploaded']">
    <app-smart-ebs-selecter id="selecter" (arrangeResult)="arrangeComplete($event)" (groupUpdate)="updatedChartGroups($event)" [availableItems]="ebsItems"></app-smart-ebs-selecter>
  </div>
  <dx-popover [target]="popupLocation" [position]="popupPosition" [shading]="true" shadingColor="rgba(0, 0, 0, 0.5)" [(visible)]="popupVisible" (onHidden)="startTutorial()">
    <div *dxTemplate="let data of 'content'">
      {{ popupMessage }}
    </div>
  </dx-popover>
  <dx-toast [(visible)]="toastvisible" [type]="toastType" [message]="toastMessage"></dx-toast>
</div>
