import { NgModule } from "@angular/core";
import { AngularCommonConfigurationModule } from "../../../angular-common/angular-common-config-module";
import { CommonAppConfigurationModule } from "../../../angular-common/common-app-config-module";
import { CommonAuthConfigurationModule } from "../../../angular-common/common-auth-config-module";
import { PreferedUserEmployeeLoginModule } from "../../../employee-common/management/prefered-user-employee/prefered-user-employee-login.module";
import { AppInspireModule } from "./app-inspire.module";
import { AppComponent } from "./app.component";
import { ManagementModule } from "./management/management.module";
import { SearchBarModule } from "./shared/search-bar/search-bar.module";

@NgModule({
  declarations: [AppComponent],
  imports: [AngularCommonConfigurationModule, CommonAuthConfigurationModule, CommonAppConfigurationModule, ManagementModule, AppInspireModule, SearchBarModule, PreferedUserEmployeeLoginModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
