<div *ngIf="variantConfiguration">
  <div class="pl-grid-template">
    <dx-data-grid [dataSource]="variantConfiguration" [columnAutoWidth]="true" [showBorders]="true">
      <dxi-column dataField="component" [caption]="language.f3MeWebApiComponent"></dxi-column>
      <dxi-column dataField="variantName" [caption]="language.f3MeWebApiCurrentVariant"></dxi-column>
      <dxi-column cellTemplate="buttonCellTemplate"></dxi-column>
      <div *dxTemplate="let configuration of 'buttonCellTemplate'">
        <dx-button icon="edit" [hint]="language.ButtonDelete" (click)="popupToggle(configuration)"> </dx-button>
      </div>
    </dx-data-grid>
    <dx-popup [width]="500" [height]="600" [showTitle]="true" [title]="language.f3MeWebApiEditSelectVariant"
      [dragEnabled]="true" [hideOnOutsideClick]="false" [(visible)]="popupVisible">
      <div *dxTemplate="let data of 'content'">
        <div class="buttonContainer">
          <dx-button icon="save" (click)="saveVariant()" [hint]="language.ButtonSave"></dx-button>
        </div>
        <app-modeltree [(selectedBranch)]="selectedBranch"></app-modeltree>
      </div>
    </dx-popup>
  </div>
</div>