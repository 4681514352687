import { ExogenousCategories, ExogenousCategory, ExogenousVariable, ExogenousVariables } from "../../../../../common/modelelements";

import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";
import { ExogenousEdit } from "../../../../../common/modelelements/categories/exogenous-edit.model";
import { Formula } from "../../../../../common/modelelements/formulas/formula.model";
import { TargetSettings } from "../../../../../employee-common/choices/targets/targetsettings";
import { TargetSettingsGroupingDto } from "../../../../../employee-common/models/choices/dto/TargetSettingsGroupingDto-dto";

export class TargetSettingsGrouping {
  public targetSettings: TargetSettings[] = [];
  public exogenousEdit: ExogenousEdit = new ExogenousEdit();
  public formulas: Formula[] = [];

  public fromDto(dto: TargetSettingsGroupingDto, languageSelection: PlLanguageSelection) {
    const newTargetSettings: TargetSettings[] = [];
    dto.TargetSettings.forEach((setting) => {
      const newSetting = new TargetSettings();
      newSetting.fromDto(setting, languageSelection);
      newTargetSettings.push(newSetting);
    });
    this.targetSettings = newTargetSettings;

    const newFormulas: Formula[] = [];
    dto.Formulas.forEach((formula) => {
      const newFormula = new Formula();
      newFormula.copyFromDto(formula);
      newFormulas.push(newFormula);
    });
    this.formulas = newFormulas;

    const newExoCategories: ExogenousCategory[] = [];
    dto.ExogenousVariablesEdit.ExogenousCategories.forEach((exoCat) => {
      const newExoCat = new ExogenousCategory();
      newExoCat.fromDto(exoCat);
      newExoCategories.push(newExoCat);
    });
    this.exogenousEdit.exogenousCategories = new ExogenousCategories(newExoCategories);

    const newExoVariables: ExogenousVariable[] = [];
    dto.ExogenousVariablesEdit.ExogenousVariables.forEach((exoVat) => {
      const newExoVat = new ExogenousVariable();
      newExoVat.copyFromDto(exoVat);
      newExoVariables.push(newExoVat);
    });
    this.exogenousEdit.exogenousVariables = new ExogenousVariables(newExoVariables);
  }
}
