import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoginDataPointDto } from "./../../../../../common//models/dto/LoginDataPoint-dto";

@Injectable()
export class UserLoginService extends PlBaseService {
  private endPoint: string = "userinsight";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getLogins(periodFilterId: number, startDate: Date, endDate: Date) {
    var startDateInDateTimeFormat = startDate.toISOString();
    var endDateinDateTimeFormat = endDate.toISOString();
    return this.getData<LoginDataPointDto[]>(`${this.endPoint}/${periodFilterId}?startDate=${startDateInDateTimeFormat}&endDate=${endDateinDateTimeFormat}`);
  }
}
