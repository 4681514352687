import { StringComparer } from "../../../../../../angular-common/stringcomparison";
import { SourceEntrySpecificationDto } from "../../../../../../employee-common/models/choices/dto/SourceEntrySpecificationDto-dto";

export class SourceEntrySpecification {
  public constructor() {}

  public targetShortName: string;
  public sourceShortName: string;
  public exogenousShortName: string;
  public sortOrder: number;

  public copyFromDto(dto: SourceEntrySpecificationDto) {
    this.exogenousShortName = dto.ExogenousShortName;
    this.sortOrder = dto.SortOrder;
    this.sourceShortName = dto.SourceShortName;
    this.targetShortName = dto.TargetShortName;
  }

  public toDto(): SourceEntrySpecificationDto {
    const result = new SourceEntrySpecificationDto();
    result.ExogenousShortName = this.exogenousShortName;
    result.SortOrder = this.sortOrder;
    result.SourceShortName = this.sourceShortName;
    result.TargetShortName = this.targetShortName;
    return result;
  }

  public get isValid(): boolean {
    return (
      !StringComparer.isNullUndefinedOrEmpty(this.targetShortName) && !StringComparer.isNullUndefinedOrEmpty(this.sourceShortName) && !StringComparer.isNullUndefinedOrEmpty(this.exogenousShortName)
    );
  }
}
