import { IModelWithIdDto } from './IModelWithId-dto';

// t4tsCodeFromCs": "0.8.2.0 for file CategoryBaseDto.cs
// Don't adjust manually!

export class CategoryBaseDto implements IModelWithIdDto {
    public KeyId: number;
    public ShortName: string;
    public SortOrder: number;
}

