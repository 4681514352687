import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { CommonRoutesDto } from "../../../common/models/dto/CommonRoutes-dto";
import { UserClaimDto } from "../../../common/models/dto/UserClaim-dto";
import { UserClaimStateDto } from "../../../common/models/dto/UserClaimState-dto";
import { AuthenticationClaimsService } from "../../authentication/authentication-claims.service";
import { PlAuthenticationService } from "../../authentication/authentication.service";
import { EnvironmentSettings } from "../../baseservice/environment-settings";
import { PlUserStorage } from "../../baseservice/pluserstorage";
import { RouteNavigation } from "../../baseservice/route-navigation";
import { RouteLocations } from "../../route-locations";

@Component({
  selector: "app-user-claims",
  templateUrl: "./user-claims.component.html",
  styleUrls: ["./_login.scss"],
})
export class UserClaimsComponent implements OnInit {
  public get loaded() {
    if (this._claims) {
      return true;
    } else {
      return false;
    }
  }

  private _claims: UserClaimStateDto;

  public get claims() {
    if (this._claims) {
      return this._claims.Claims;
    }
    return [];
  }

  public get numberOfClaimsText() {
    return this.language.accountClaimsNumber(this.claims.length);
  }

  constructor(
    private router: Router,
    public language: WebLanguage,
    private environmentSettings: EnvironmentSettings,
    private authService: AuthenticationClaimsService,
    private authenticationService: PlAuthenticationService,
  ) {}

  ngOnInit(): void {
    this.loadCurrentClaims();
  }

  private loadCurrentClaims() {
    this.authService.retrieveCurrentUserClaims().subscribe((r) => {
      this._claims = r;
      console.log("retrieve claims from server");
    });
  }

  public refreshingCurrentUser = false;

  public refreshUser() {
    this.refreshingCurrentUser = true;
    this.authenticationService.refreshUserData().subscribe((r) => {
      this.loadCurrentClaims();
      this.refreshingCurrentUser = false;
    });
  }

  public roleMissing(claim: UserClaimDto) {
    if (claim && claim.Namespace === UserClaimDto.RoleNameSpace) {
      if (claim.Value && claim.Value.length > 0) {
        return PlUserStorage.hasRole(claim.Value) !== true;
      }
    }
    return false;
  }

  public get hasCurrentUser() {
    return PlUserStorage.hasUser();
  }

  public get hasCookie() {
    if (this._claims) {
      return this._claims.HasSessionCookie;
    }
  }
  public get cookieRemoveText() {
    return "Clear cookie";
  }

  public goToCookieClear() {
    var location = RouteNavigation.combineLocationAndRoute(this.environmentSettings.baseUriNoneApi(), "Account/ResetCookie");
    RouteLocations.moveToLocation(location);
  }

  public get currentUserAuthenticated() {
    if (this._claims) {
      if (this._claims.Authenticated == false) {
        return false;
      }
    }
    if (PlUserStorage.user) {
      return PlUserStorage.user.Authenticated;
    }
    return false;
  }

  public get currentUserName() {
    if (PlUserStorage.user) {
      return PlUserStorage.user.Username;
    }
    return "";
  }

  public gotoSignout() {
    this.router.navigate(["/" + CommonRoutesDto.SignOff]);
  }

  public gotoSignin() {
    this.router.navigate(["/" + CommonRoutesDto.Login]);
  }
}
