import { ExogenousCategoryDto } from './../../../../common/models/dto/ExogenousCategoryDto-dto';
import { TargetOptionTranslationDto } from './TargetOptionTranslation-dto';

// t4tsCode gen": "0.8.2.0 for file TargetOptionsTranslation.cs
// Don't adjust manually!

export class TargetOptionsTranslationDto {
    public TargetOptions: TargetOptionTranslationDto [] = [];
    public ExogenousCategory: ExogenousCategoryDto;
    public ErrorReferenceCategoryId: number;
    public WarningReferenceCategoryId: number;
}

