import { Component, Input } from "@angular/core";

import { SimulationInput } from "../_models/simulation-input.model";

@Component({
  selector: "app-simulation-input-reference",
  templateUrl: "./simulation-input-reference.component.html",
  styleUrls: ["./simulation-input-reference.component.scss"],
})
export class SimulationInputReferenceComponent {
  @Input() public input: SimulationInput;

  public constructor() {}

  public get data() {
    if (this.input) {
      this.input.options;
    }
    return [];
  }

  public get readonly() {
    return this.input.readonly;
  }

  public get currentText() {
    return this.input.value;
  }

  public get currentValue() {
    if (this.input) {
      return this.input.valueAsNumber;
    }
    return 0;
  }
  public set currentValue(value: number) {
    if (this.input) {
      this.input.valueAsNumber = value;
    }
  }
}
