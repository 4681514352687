import { RouterModule, Routes } from "@angular/router";
import { DxChartModule, DxDateBoxModule, DxSelectBoxModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommonRoutesDto } from "../../../../common/models/dto/CommonRoutes-dto";
import { canActivateUserAcknowledgementGuard } from "../../../../employee-common/auth/user-acknowledgemen.guard";
import { CMSModule } from "../../../../employee-common/cms/cms.module";
import { MenuItemTypesDto } from "../../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { UserLoginService } from "./userlogins/userlogin.service";
import { UserloginsChartComponent } from "./userlogins/userlogins-chart.component";
import { UserLoginsDashboardComponent } from "./userlogins/userlogins-dashboard.component";

const routes: Routes = [
  {
    path: CommonRoutesDto.UserLoginsOverviewDashboard,
    component: UserLoginsDashboardComponent,
    data: [{ menuType: MenuItemTypesDto.Dashboarding }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, CMSModule, DxChartModule, DxSelectBoxModule, DxDateBoxModule],
  exports: [UserLoginsDashboardComponent],
  declarations: [UserLoginsDashboardComponent, UserloginsChartComponent],
  providers: [UserLoginService],
})
export class DashboardsModule {}
