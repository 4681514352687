import { Component, EventEmitter, OnInit, Output } from "@angular/core";

import { ChoicesLanguage } from "../../../../../employee-common/choices/language/choiceslanguage.service";
import { TargetSettings } from "../../../../../employee-common/choices/targets/targetsettings";
import { TargetEditTargetSelector } from "./targetedit-targetselector";
import { TargetEditService } from "./targetedit.service";

@Component({
  selector: "app-target-selector",
  templateUrl: "target-selector.component.html",
  styleUrls: ["target-selector.component.scss"],
})
export class TargetSelectorComponent implements OnInit {
  constructor(targetEditService: TargetEditService, public language: ChoicesLanguage) {
    this.targetSelector = new TargetEditTargetSelector(targetEditService);
  }

  ngOnInit() {
    this.targetSelector.onSelectedTargetChanged.subscribe(() => {
      this.selectedTargetChanged();
    });
    this.targetSelector.init().subscribe();
  }

  private selectedTargetChanged(): void {
    this.selectedTargetHasChanged.emit(this.targetSelector.selectedTarget);
  }

  public targetSelector: TargetEditTargetSelector;

  @Output()
  public selectedTargetHasChanged = new EventEmitter<TargetSettings>();
}
