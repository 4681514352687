<div *ngIf="contentLoaded">
  <div class="buttonBar">
    <dx-button (click)="addNew()" icon="fas fa-plus" [text]="language.ButtonNew"></dx-button>
  </div>

  <dx-data-grid [dataSource]="list" autoWidth="true" [showBorders]="true" (onRowUpdated)="onRowUpdated($event)"
    (onRowRemoved)="onRowRemoved($event)" [masterDetail]="{ enabled: true, template: 'detailTemplate' }">
    <dxo-editing [useIcons]="true" mode="row" [allowUpdating]="true" [allowDeleting]="true"> </dxo-editing>
    <dxi-column dataField="sourceSpecification.shortName" [caption]="language.modelShortName">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule type="custom" [validationCallback]="validationCallback" [message]="language.ShortNameExists">
      </dxi-validation-rule>
      <dxi-validation-rule type="pattern" [pattern]="shortNameValidation" [message]="language.InvalidShortname">
      </dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="sourceSpecification.resultValue" [caption]="language.f3MeWebApiEditSourcesResultValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="formulas.all" valueExpr="shortName" displayExpr="displayName"> </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="sourceSpecification.sortOrder" dataType="number" [caption]="language.modelSortOrder">
    </dxi-column>

    <div *dxTemplate="let detail of 'detailTemplate'">
      <div>
        <app-source-entry-specifications-edit [sourceShortName]="detail.key.originalShortName">
        </app-source-entry-specifications-edit>
      </div>
    </div>
  </dx-data-grid>
</div>