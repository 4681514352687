import { VariantDto } from "../../../../../common/models/dto/VariantDTO-dto";
import { VariantModelConfigurationDto } from "../../../../../employee-common/models/cms/dto/VariantModelConfigurationDto-dto";

export class VariantConfiguration {
  public variantId: number;
  public component: string;
  public variantName: string;

  public static toDto(variant: VariantConfiguration): VariantModelConfigurationDto {
    const dto = new VariantModelConfigurationDto();
    dto.Component = variant.component;
    dto.Variant = new VariantDto();
    dto.Variant.KeyId = variant.variantId;
    dto.Variant.LongName = variant.variantName;

    return dto;
  }
  constructor() {}

  public copyFromDto(dto: VariantModelConfigurationDto) {
    if (dto) {
      this.component = dto.Component;
      if (dto.Variant) {
        this.variantId = dto.Variant.KeyId;
        this.variantName = dto.Variant.LongName;
      }
    }
  }
}
