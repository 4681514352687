import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";

import { DxDataGridComponent } from "devextreme-angular";
import { IArrangeResult } from "./iarrange-result";
import { SmartEbsHeader } from "./smart-ebs-header";
import { SmartEbsItem } from "./smart-ebs-item";
import { SmartMapping } from "./smartmapping";

@Component({
  selector: "app-smart-ebs-selecter",
  templateUrl: "smart-ebs-selecter.component.html",
  styleUrls: ["smart-ebs-selecter.component.scss"],
})
export class SmartEbsSelecterComponent implements OnInit {
  @ViewChildren(DxDataGridComponent) dataGrids: QueryList<DxDataGridComponent>;

  @Output() arrangeResult = new EventEmitter<IArrangeResult>(true);
  @Output() groupUpdate = new EventEmitter<SmartEbsHeader[]>(true);

  public headers: SmartEbsHeader[] = [];
  public headerIds: number[] = [];
  public _availableItems: SmartEbsItem[] = [];

  constructor() {
    this.onAdd = this.onAdd.bind(this);
  }

  @Input()
  set availableItems(v: SmartEbsItem[]) {
    if (v.length <= 0) {
      return;
    }

    this._availableItems = v;
    setTimeout(() => {
      this.smartArrange();
    }, 4000);
  }

  get availableItems() {
    return this._availableItems;
  }

  ngOnInit(): void {
    this.createHeaders();
    this.calculateTotals();
  }

  private calculateTotals() {
    this.headers.forEach((header) => {
      let total = 0;
      const selectedItems = this.availableItems.filter((x) => x.selectedCatagory === header.ID);
      selectedItems.forEach((item) => {
        total = total + item.value;
      });

      header.totalValue = total;
    });
    this.groupUpdate.emit(this.headers);
  }

  private smartArrange() {
    let matched = 0;

    this.availableItems.forEach((ebsItem) => {
      if (SmartMapping.fixedIncome.some((el) => ebsItem.longName.toLocaleLowerCase().includes(el))) {
        ebsItem.selectedCatagory = 1;
        matched++;
      }
      if (SmartMapping.variablePay.some((el) => ebsItem.longName.toLocaleLowerCase().includes(el))) {
        ebsItem.selectedCatagory = 2;
        matched++;
      }
      if (SmartMapping.flexbudget.some((el) => ebsItem.longName.toLocaleLowerCase().includes(el))) {
        ebsItem.selectedCatagory = 3;
        matched++;
      }
      if (SmartMapping.insurances.some((el) => ebsItem.longName.toLocaleLowerCase().includes(el))) {
        ebsItem.selectedCatagory = 4;
        matched++;
      }
      if (SmartMapping.pension.some((el) => ebsItem.longName.toLocaleLowerCase().includes(el))) {
        ebsItem.selectedCatagory = 5;
        matched++;
      }
      if (SmartMapping.benefits.some((el) => ebsItem.longName.toLocaleLowerCase().includes(el))) {
        ebsItem.selectedCatagory = 6;
        matched++;
      }
    });

    this.arrangeResult.emit({ result: "success", matched: matched });
    this.calculateTotals();

    // We need to use this to kick the datagrids into refreshing, because we use multiple grids.
    this.dataGrids.forEach((grid) => {
      grid.instance.refresh();
    });
  }

  public onAdd(e) {
    const itemId = e.itemData.keyId;
    const newHeaderId = e.toData.ID;

    const item = this.availableItems.find((x) => x.keyId === itemId);
    item.selectedCatagory = newHeaderId;

    this.calculateTotals();

    e.fromComponent.refresh();
    e.toComponent.refresh();
  }

  private createHeaders() {
    const catagoryNames = ["Fixed Income", "Variable Pay", "Flexbudget", "Insurances", "Pension", "Benefits"];
    const sdWorxColors = ["#E4003A", "#F8AD07", "#870B58", "#E94E0F", "#303642", "#555D71", "#898E9A", "#7783A0"];
    let i = 0;
    for (let index = 0; index < catagoryNames.length; index++) {
      const color = sdWorxColors[i];
      const header = new SmartEbsHeader(index + 1, catagoryNames[index], catagoryNames[index], color);
      this.headers.push(header);
      i++;
      if (i === sdWorxColors.length) {
        i = 0;
      }
    }

    this.headers.forEach((header) => {
      this.headerIds.push(header.ID);
    });
  }
}
