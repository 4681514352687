import { LanguageItem } from "../../../../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../../../../angular-common/language/pl-language-selection";
import { LanguageBuilder } from "../../../../../../common/language/language-builder";
import { ExogenousVariable } from "../../../../../../common/modelelements";
import { TargetOptionTranslationDto } from "../../../../../../employee-common/models/choices/dto/TargetOptionTranslation-dto";
import { InspireLanguageService } from "../../language/inspire-language.service";

export class TargetOptionTranslation {
  public constructor(private language: InspireLanguageService, private selectLanguage: PlLanguageSelection) {
    this.exogenousVariable = new ExogenousVariable();
    this.name = language.languageBuilder().createLanguageItem();
    this.description = language.languageBuilder().createLanguageItem();
  }

  public copyFromDto(dto: TargetOptionTranslationDto) {
    this.exogenousVariable.copyFromDto(dto.ExogenousVariable);
    LanguageBuilder.copy(dto.Name, this.name);
    LanguageBuilder.copy(dto.Description, this.description);
  }

  public copyToDto(): TargetOptionTranslationDto {
    const result = new TargetOptionTranslationDto();
    result.ExogenousVariable = this.exogenousVariable.copyToDto();
    result.Name = LanguageBuilder.duplicate(this.name);
    result.Description = LanguageBuilder.duplicate(this.description);
    return result;
  }

  public exogenousVariable: ExogenousVariable;
  public name: LanguageItem;
  public description: LanguageItem;
  public nameCopy: LanguageItem;
  public descriptionCopy: LanguageItem;

  public get nameDisplay(): string {
    return this.selectLanguage.currentValueFor(this.name);
  }

  public get descDisplay(): string {
    return this.selectLanguage.currentValueFor(this.description);
  }

  public startEditing() {
    this.nameCopy = LanguageBuilder.duplicate(this.name);
    this.descriptionCopy = LanguageBuilder.duplicate(this.description);
  }

  public finishEditing() {
    LanguageBuilder.copy(this.nameCopy, this.name);
    LanguageBuilder.copy(this.descriptionCopy, this.description);
  }
}
