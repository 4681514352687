import { Component } from "@angular/core";
import DxDataGrid from "devextreme/ui/data_grid";
import { ElementHelper } from "../../../../angular-common/helpers/element.helper";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { PlLanguageSelectionCopier } from "../../../../angular-common/language/pl-language-selection-copier";
import { ExogenousCategoryDto } from "../../../../common/models/dto/ExogenousCategoryDto-dto";
import { ChoicesLanguage } from "../../../../employee-common/choices/language/choiceslanguage.service";
import { TargetSettings } from "../../../../employee-common/choices/targets/targetsettings";
import { TargetOptionTranslationDto } from "../../../../employee-common/models/choices/dto/TargetOptionTranslation-dto";
import { TargetOptionsTranslationDto } from "../../../../employee-common/models/choices/dto/TargetOptionsTranslation-dto";
import { TargetTranslationsService } from "../shared/targettranslations/target-translations.service";

@Component({
  selector: "app-target-translations",
  templateUrl: "target-translations.components.html",
  styleUrls: ["target-translations.components.scss"],
})
export class TargetTranslationsComponent {
  constructor(public language: ChoicesLanguage, private targetTranslationsService: TargetTranslationsService, private plLanguageSelection: PlLanguageSelection) {
    this.getCategoryDisplayValue = this.getCategoryDisplayValue.bind(this);
    this.name = this.name.bind(this);
    this.description = this.description.bind(this);
  }

  public name(target: TargetOptionTranslationDto) {
    return target.Name[this.plLanguageSelection.currentLanguage];
  }

  public description(target: TargetOptionTranslationDto) {
    return target.Description[this.plLanguageSelection.currentLanguage];
  }

  public setTarget(newTarget: TargetSettings) {
    this.target = newTarget;
    if (this.hasTarget) {
      this.loadTargetOptions();
    }
  }
  public target: TargetSettings;

  public get hasTarget(): boolean {
    return ElementHelper.isNullOrUndefined(this.target) === false;
  }

  public get hasTargetOptions(): boolean {
    return ElementHelper.isNullOrUndefined(this.targetOptions) === false;
  }

  public targetOptions: TargetOptionsTranslationDto;

  private loadTargetOptions() {
    this.targetTranslationsService.retrieve(this.target.keyId).subscribe((opt) => {
      this.targetOptions = opt.toDto();
    });
  }

  public getCategoryDisplayValue(rowData: any) {
    if (this.hasTargetOptions) {
      return this.targetOptions.ExogenousCategory.ShortName;
    }
    return "";
  }

  public hasReferenceCategory(exog: ExogenousCategoryDto): boolean {
    return ElementHelper.isNullOrUndefined(exog) === false && exog.KeyId > 0;
  }

  onEditingStart(option: TargetOptionsTranslationDto) {
    PlLanguageSelectionCopier.copyFromOriginal(option);
  }

  onRowUpdated(option: TargetOptionTranslationDto, dxGrid: DxDataGrid) {
    PlLanguageSelectionCopier.copyToOriginal(option);
    dxGrid.repaint();
    this.targetTranslationsService.save(option).subscribe();
  }
}
