<div *ngIf="visible" class="maindiv">
  <dx-button (click)="showPopup()" [text]="headerButton"></dx-button>

  <dx-popup [showTitle]="true" [title]="popupTitle" [(visible)]="popupVisible" [width]="'auto'" [height]="'auto'"
    [minWidth]="320">
    <div *dxTemplate="let data of 'content'">
      <div>
        <span class="line">{{ columnHeader }}</span>
        <select class="selectinput line" [(ngModel)]="selectedColumn">
          <option *ngFor="let c of lookupColumns" [ngValue]="c">{{ c.caption }}</option>
        </select>
        <br />

        <span class="line">{{ fromHeader }}</span>
        <select class="selectinput line" [(ngModel)]="fromValue">
          <option *ngFor="let c of selectedColumn.lookup.dataSource" [ngValue]="c">{{ c.Name }}</option>
        </select>
        <br />

        <span class="line">{{ toHeader }}</span>
        <select class="selectinput line" [(ngModel)]="toValue">
          <option *ngFor="let c of selectedColumn.lookup.dataSource" [ngValue]="c">{{ c.Name }}</option>
        </select>
        <br />

        <p class="line extrainfo">{{ extraInfo }}</p>
        <br />

        <span>
          <dx-button [text]="languageService.ButtonOk" (click)="closePopup(true)" icon="check"
            [disabled]="!selectionIsValid"></dx-button>
          <dx-button [text]="languageService.ButtonCancel" (click)="closePopup(false)" icon="remove"></dx-button>
        </span>
      </div>
    </div>
  </dx-popup>
</div>