import { Component, Input } from "@angular/core";
import { SimulationIO } from "../_models/simulation-io.model";

@Component({
  selector: "app-simultation-inputs",
  templateUrl: "./simultation-inputs.component.html",
  styleUrls: ["./simultation-inputs.component.scss"],
})
export class SliderComponent {
  public constructor() {}
  @Input() public simulationData: SimulationIO;
}
