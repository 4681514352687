<div class="cms-edit-simmodels">
  <div class="buttons align-right">
    <p class="savewarning" *ngIf="hasChanges">*Click to save changes</p>
    <div class="saveContainer">
      <dx-button [disabled]="!hasChanges" (click)="onSave()" icon="save" [text]="language.ButtonSave"></dx-button>
    </div>

    <div *ngIf="currentConfig">
      <app-cardview>
        <div>
          <h2>{{ language.f3MeWebApiSimulateEditInputHeader }}</h2>
          <app-list-selector
            [itemsInUse]="currentConfig.inputs"
            [availableItems]="availableInputOptions"
            [displayValue]="displayValue"
            [searchValue]="searchValue"
            [moveImplementation]="moveImplementationInput"
            (valuesChanged)="inputChanged()"
            (sortOrderChanged)="inputSortOrderChanged($event)"
          ></app-list-selector>
        </div>
      </app-cardview>
      <app-cardview>
        <div>
          <h2>{{ language.f3MeWebApiSimulateEditOutputHeader }}</h2>
          <app-list-selector
            [itemsInUse]="currentConfig.outputs"
            [availableItems]="availableOutputOptions"
            [displayValue]="displayValue"
            [searchValue]="searchValue"
            [moveImplementation]="moveImplementationOutput"
            (valuesChanged)="outputChanged()"
            (sortOrderChanged)="outputSortOrderChanged($event)"
          ></app-list-selector>
        </div>
      </app-cardview>
    </div>
  </div>
</div>
