import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RouteNavigation } from "../../../../angular-common/baseservice/route-navigation";
import { ChoicesRoutesDto } from "../../../../employee-common/models/choices/dto/ChoicesRoutes-dto";

@Injectable()
export class FilesRouteNavigation extends RouteNavigation {
  constructor(router2: Router) {
    super(router2);
  }

  public toUserFiles(userId: string) {
    super.navigate(ChoicesRoutesDto.UserFiles, userId);
  }
}
