<div>
  <div *ngIf="!chartReady" class="widget-container flex-box">
    <img src="../../assets/images/SmartEbsChartDummy.png" alt="" />
  </div>
  <div *ngIf="chartReady" class="overview doughnut">
    <figure>
      <dx-pie-chart
        class="full-width"
        [dataSource]="groupsForChart"
        [series]="series"
        [legend]="legend"
        [size]="size"
        [palette]="palette"
        [tooltip]="tooltip"
        [type]="'donut'"
        [innerRadius]="0.72"
        [startAngle]="-270"
      ></dx-pie-chart>
    </figure>
    <div class="valueText">€{{ totalAsText }}</div>
  </div>
</div>
