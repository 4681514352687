import { RouterModule, Routes } from "@angular/router";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxRadioGroupModule } from "devextreme-angular";
import { canActivateUserAcknowledgementGuard } from "../../../../employee-common/auth/user-acknowledgemen.guard";
import { MenuItemTypesDto } from "../../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { ManagementRoutesDto } from "../models/dto/ManagementRoutes-dto";
import { ManagementSettingsComponent } from "./management-settings.components";

const routes: Routes = [
  {
    path: ManagementRoutesDto.ManagementSettings,
    component: ManagementSettingsComponent,
    data: [
      {
        menuType: MenuItemTypesDto.Management,
      },
    ],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, DxRadioGroupModule],
  declarations: [ManagementSettingsComponent],
  exports: [ManagementSettingsComponent],
})
export class ManagementSettingsModule {}
