import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";
import { SimulationLanguageItemDto } from "../../simulate/dto/SimulationLanguageItem-dto";
import { InspireLanguageService } from "../language/inspire-language.service";

@Injectable()
export class SimulationLanguageEditService extends PlBaseService {
  private endPoint = "simulationlanguageedit";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator, private language: InspireLanguageService, private selectLanguage: PlLanguageSelection) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveAll(): Observable<SimulationLanguageItemDto[]> {
    return this.getDataTransformed<SimulationLanguageItemDto[]>(this.endPoint);
  }

  public retrieveSingle(id: number) {
    const url = `${this.endPoint}/${id}`;
    return this.getDataTransformed<SimulationLanguageItemDto>(url);
  }

  public updateLanguage(simItem: SimulationLanguageItemDto): Observable<SimulationLanguageItemDto> {
    return this.updateTransformedResponse<SimulationLanguageItemDto>(simItem, this.endPoint);
  }
}
