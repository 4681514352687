import { Component, EventEmitter, Input, Output } from "@angular/core";

import { ColumnItemDto } from "../../../../common/models/dto/ColumnItem-dto";
import { InspireLanguageService } from "../shared/language/inspire-language.service";
import { LookUpItemDto } from "../../../../common/models/dto/LookUpItem-dto";
import { confirm } from "devextreme/ui/dialog";

@Component({
  selector: "app-change-multiple-lookup-columns",
  templateUrl: "./change-multiple-lookup-columns.component.html",
  styleUrls: ["./change-multiple-lookup-columns.component.scss"],
  providers: [],
})

// With this class a user can select a column with a lookup and change all visible rows at once. It's meant to be generic
// but for now we implement it for the approve targets screen.
export class ChangeMultipleLookupColumnsComponent {
  public constructor(public languageService: InspireLanguageService) {}

  @Input()
  public get columns(): ColumnItemDto[] {
    return this._columns;
  }
  public set columns(newColumns: ColumnItemDto[]) {
    this._columns = newColumns;
    this.initialize();
  }
  private _columns: ColumnItemDto[];

  @Input() public headerButton: string;
  @Input() public popupTitle: string;
  @Input() public columnHeader: string;
  @Input() public fromHeader: string;
  @Input() public toHeader: string;
  @Input() public extraInfo: string;
  @Input() public confirmBeforeShowPopup: boolean = false;
  @Input() public confirmMessageBeforeShowPopup: string;

  private initialize() {
    if (this.columns === null || this.columns === undefined || this.columns.length === 0) {
      this.visible = false;
      return;
    }

    this.lookupColumns.splice(0, this.lookupColumns.length - 1);
    this.columns.forEach((c) => {
      if (c.lookup !== null && c.lookup !== undefined && c.lookup.dataSource.length >= 2) {
        this.lookupColumns.push(c);
      }
    });

    this.visible = this.lookupColumns.length > 0;
    if (this.lookupColumns.length > 0) {
      this.selectedColumn = this.lookupColumns[0];
    }
  }

  public lookupColumns: ColumnItemDto[] = [];

  public get selectedColumn(): ColumnItemDto {
    return this._selectedColumn;
  }
  public set selectedColumn(newSelectedColumn: ColumnItemDto) {
    this._selectedColumn = newSelectedColumn;

    if (this.selectedColumn !== null && this.selectedColumn !== undefined) {
      this.fromValue = this.selectedColumn.lookup.dataSource[0];
      this.toValue = this.selectedColumn.lookup.dataSource[1];
    }
  }
  private _selectedColumn: ColumnItemDto;

  public fromValue: LookUpItemDto;
  public toValue: LookUpItemDto;

  public visible: boolean = false;
  public popupVisible: boolean = false;

  public showPopup() {
    if (this.confirmBeforeShowPopup) {
      const question = confirm(this.confirmMessageBeforeShowPopup, this.popupTitle);
      question.then((answer) => {
        if (answer) {
          this.popupVisible = true;
        }
      });
    } else {
      this.popupVisible = true;
    }
  }

  public closePopup(apply: boolean) {
    this.popupVisible = false;
    if (apply && this.selectionIsValid) {
      const valueToEmit = {
        column: this.selectedColumn,
        from: this.fromValue,
        to: this.toValue,
      };
      this.apply.emit(valueToEmit);
    }
  }

  public get selectionIsValid(): boolean {
    if (this.selectedColumn && this.fromValue && this.toValue && this.fromValue !== this.toValue) {
      return true;
    }
    return false;
  }

  @Output() apply = new EventEmitter();
}
