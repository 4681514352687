<app-cmspage-header [innerText]="choicesLanguage.f3MeWebApiUserFilesIntro"></app-cmspage-header>

<dx-data-grid id="gridContainer" [dataSource]="data" [allowColumnReordering]="true" [showBorders]="true">
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 200]"></dxo-pager>

  <dxi-column type="buttons">
    <dxi-button name="download" icon="download" [onClick]="downloadCellFile"></dxi-button>
  </dxi-column>
  <dxi-column caption="{{ languageService.Extension }}" dataField="GroupName" dataType="string"
    [groupIndex]="0"></dxi-column>
  <dxi-column caption="{{ languageService.File }}" dataField="Name" dataType="string"></dxi-column>
  <dxi-column caption="{{ languageService.UploadDate }}" dataField="UploadDate" dataType="date"></dxi-column>
</dx-data-grid>