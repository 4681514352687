import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { DxButtonModule } from "devextreme-angular";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { ChoicesModule } from "../../../../employee-common/choices/targets/choices.module";
import { ChoicesService } from "../../../../employee-common/choices/targets/choices.service";
import { CMSModule } from "../../../../employee-common/cms/cms.module";
import { CmsPageHeaderModule } from "../../../../employee-common/cms/cmspageheaders/cmspage-header.module";
import { InspireCmsLinkModule } from "../shared/links/inspire-cms-link.module";
import { MyOrdersTopicComponent } from "./myorders-overview/myorders-overview-topic";
import { MyOrdersComponent } from "./myorders.component";

@NgModule({
  declarations: [MyOrdersComponent, MyOrdersTopicComponent],
  imports: [CommonModule, CMSModule, HttpClientModule, CmsPageHeaderModule, ChoicesModule, DxButtonModule, InspireCmsLinkModule],
  providers: [ChoicesService, PlLanguageSelection],
  exports: [MyOrdersComponent],
})
export class MyordersModule {}
