
// t4tsCode gen": "0.8.2.0 for file SourceEntrySpecificationDto.cs
// Don't adjust manually!

export class SourceEntrySpecificationDto {
    public TargetShortName: string;
    public SourceShortName: string;
    public ExogenousShortName: string;
    public SortOrder: number;
}

