import { FieldChangedDataDto } from "../../../../../common/models/dto/FieldChangedData-dto";
export class ClientChangedData {
  public constructor() {}

  identification: string;
  column: string;
  value: string;

  public toDTO(): FieldChangedDataDto {
    return {
      Column: this.column,

      Identification: this.identification,

      Value: this.value,
    };
  }

  public isSameIdentificationAndColumn(other: ClientChangedData) {
    return this.isSame(this.column, other.column) && this.isSame(this.identification, other.identification);
  }

  private isSame(value: string, other: string) {
    const valueUndefined = value === undefined || value === null;
    const otherUndefined = other === undefined || other === null;

    if (valueUndefined && otherUndefined) {
      return true;
    }

    if (valueUndefined === false && otherUndefined === false) {
      return value.toLowerCase() === other.toLowerCase();
    }

    return false;
  }
}
