<div *ngIf="availableItems && headers">
  <dx-box class="selectBox" direction="row" width="100%">
    <dxi-item [ratio]="2">
      <dx-data-grid #selectgrid [dataSource]="availableItems" [showColumnLines]="false" [filterValue]="['selectedCatagory', 'noneof', headerIds]">
        <dxo-row-dragging [data]="availableItems" group="tasksGroup" [onAdd]="onAdd"></dxo-row-dragging>
        <dxi-column dataField="longName"></dxi-column>
        <dxi-column dataField="selectedCatagory" [visible]="false"></dxi-column>
      </dx-data-grid>
    </dxi-item>
    <dxi-item [ratio]="1"></dxi-item>
    <dxi-item class="accordion-box" [ratio]="2">
      <dx-accordion [dataSource]="headers" [multiple]="true" [collapsible]="true" >
        <div *dxTemplate="let header of 'title'">
          <span class="ebs-rectangle" style.border-color="{{ header.ebsColor }}" style.background-color="{{ header.ebsColor }}"></span> {{ header.longName }}
          <span class="ebs-header-total">€{{ header.valueAsText }}</span>
        </div>
        <div *dxTemplate="let header of 'item'">
          <dx-data-grid [dataSource]="availableItems" [showColumnHeaders]="false" [showColumnLines]="false" [filterValue]="['selectedCatagory', '=', header.ID]">
            <dxo-row-dragging [data]="header" group="tasksGroup" [onAdd]="onAdd"></dxo-row-dragging>
            <dxi-column dataField="longName"></dxi-column>
            <dxi-column dataField="selectedCatagory" [visible]="false"></dxi-column>
            <dxi-column width="20%" dataField="valueAsText"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-accordion>
    </dxi-item>
  </dx-box>
</div>
