import { SimulationInput } from "./simulation-input.model";
import { SimulationOutput } from "./simulation-output.model";
import { Sorter } from "../../../../../angular-common";

export class SimulationIO {
  public constructor(public input: SimulationInput[], public output: SimulationOutput[]) {}

  public sortEntries() {
    Sorter.sortSortOrderThenAlpha(this.input, (x) => x.shortName);
    Sorter.sortSortOrderThenAlpha(this.output, (x) => x.shortName);
  }
}
