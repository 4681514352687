import { ExogenousCategories, ExogenousVariables } from "../../../../../common/modelelements";
import { ExogenousVariablesEditDto } from "../../../../../common/models/dto/ExogenousVariablesEditDto-dto";

export class ExogenousVariablesWithCategories {
  public constructor() {
    this.exogenousVariables = new ExogenousVariables([]);
    this.exogenousCategories = new ExogenousCategories([]);
  }
  public copyFromDto(dto: ExogenousVariablesEditDto) {
    this.exogenousVariables.copyFromDto(dto.ExogenousVariables);
    this.exogenousCategories.copyFromDto(dto.ExogenousCategories);
  }

  public exogenousVariables: ExogenousVariables;
  public exogenousCategories: ExogenousCategories;
}
