import { alert, confirm } from "devextreme/ui/dialog";
import { from, Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

import { ApproveTargetsService } from "..";
import { ChoicesLanguage } from "../../../../../employee-common/choices/language/choiceslanguage.service";
import { InspireLanguageService } from "../../shared/language/inspire-language.service";
import { ClientChangedData } from "./client-changed-data";

export class ClientChangedDataList {
  public constructor(private choicesLanguage: ChoicesLanguage, private inspireLanguage: InspireLanguageService, private approveTargetsService: ApproveTargetsService) {}

  private _allChanges: ClientChangedData[] = [];

  // Adds a change, if a change was already made for this field/id the existing change is overwritten
  public addOrReplace(identification: string, dataField: string, value: string) {
    const newChange = this.createChange(identification, dataField, value);
    const existingChange = this._allChanges.find((x) => x.isSameIdentificationAndColumn(newChange));

    // a change already exists for this column so a second change was made to this column
    if (existingChange && existingChange !== null) {
      existingChange.value = newChange.value;
    } else {
      this._allChanges.push(newChange);
    }
  }

  // Adds a change
  public add(identification: string, dataField: string, value: string) {
    this._allChanges.push(this.createChange(identification, dataField, value));
  }

  private createChange(identification: string, dataField: string, value: string): ClientChangedData {
    const result = new ClientChangedData();
    result.identification = identification;
    result.column = dataField;
    result.value = value;
    return result;
  }

  public get hasChanges(): boolean {
    return this._allChanges.length > 0;
  }

  public get changeCount(): number {
    return this._allChanges.length;
  }

  public clearChanges() {
    this._allChanges = [];
  }

  public submit(): Observable<boolean> {
    const obs = this.approveTargetsService.postChanges(this._allChanges).pipe(
      map((result) => {
        if (!result.isSuccess) {
          alert(result.message, this.inspireLanguage.applicationTitle);
        } else {
          this.clearChanges();
        }

        return result.isSuccess;
      }),
    );

    return obs;
  }

  public submitWithConfirm(): Observable<boolean> {
    if (this.hasChanges) {
      let confirmation = from(confirm(this.choicesLanguage.f3MeWebApiApproveTargetsChangeMultipleConfirm(this.changeCount), this.choicesLanguage.f3MeWebApiApproveTargetsConfirm));
      confirmation = confirmation.pipe(
        mergeMap((result) => {
          if (result) {
            return this.submit();
          } else {
            return of(false);
          }
        }),
      );
      return confirmation;
    } else {
      alert(this.choicesLanguage.f3MeWebApiApproveTargetsChangeMultipleNothingToChange, this.choicesLanguage.f3MeWebApiApproveTargetsConfirm);
      return of(false);
    }
  }
}
