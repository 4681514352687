<div *ngIf="data && hasStartDate">
  <div class="box">
    <span class="header-text">{{ choicesLanguage.f3MeWebApiApproveTargetsHeader }}</span>
    <div class="date-boxes">
      <dx-button (click)="dateFilter.resetFilter()" class="rightalign-withmargin" icon="fas fa-undo"
        [text]="choicesLanguage.f3MeWebApiApproveTargetsResetFilter"></dx-button>
      <dx-button [disabled]="!dateFilter.isValid || !hasStartDate" (click)="dateFilter.applyFilter()"
        class="rightalign-withmargin" icon="refresh" [text]="choicesLanguage.f3MeWebApiApproveTargetsApplyFilter">
      </dx-button>
      <dx-date-box class="dx-datebox rightalign-withmargin" [disabled]="!hasStartDate" [(value)]="dateFilter.dateTo"
        type="date"> </dx-date-box>
      <dx-date-box class="dx-datebox rightalign-withmargin" [disabled]="!hasStartDate" [(value)]="dateFilter.dateFrom"
        type="date"> </dx-date-box>
      <span *ngIf="dateFilter.isValid === false" class="invalid-date-filter"
        [title]="choicesLanguage.f3MeWebApiApproveTargetsInvalidDateFilter">!</span>
    </div>
  </div>
</div>

<div *ngIf="data">
  <dx-data-grid [dataSource]="data.all" [columns]="$any(gridConfiguration.columns)" [rowAlternationEnabled]="true"
    [showBorders]="true" (onRowUpdated)="rowUpdated($event)" id="gridContainer" [columnResizingMode]="'widget'"
    [allowColumnResizing]="true" [columnAutoWidth]="true">
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 100, 500, 1000, 10000]"></dxo-pager>
    <dxo-editing mode="cell" [allowUpdating]="true"> </dxo-editing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-scrolling [showScrollbar]="'always'"></dxo-scrolling>
    <div *dxTemplate="let data of 'cellTemplate'">
      <span *ngIf="!isActionColumn(data)">{{ data | lookupCellTemplate }}</span>
      <span *ngIf="isActionColumn(data)">
        <div class="cms-link">
          <dx-button *ngIf="gridConfiguration.hasUserIdentificationConfigured" icon="chevronnext"
            [text]="choicesLanguage.f3MeWebApiLinkUserFiles" (onClick)="goToFiles(data)"></dx-button>
        </div>
      </span>
    </div>
  </dx-data-grid>
</div>

<div class="box">
  <p class="save-header">{{ choicesLanguage.f3MeWebApiApproveTargetsConfirmHeader }}</p>

  <div class="action-buttons">
    <dx-button [disabled]="!canSubmit" icon="save" (click)="submit()"
      [text]="choicesLanguage.f3MeWebApiApproveTargetsConfirm"> </dx-button>
    <app-change-multiple-lookup-columns [columns]="columns" class="action-button"
      [headerButton]="choicesLanguage.f3MeWebApiApproveTargetsChangeMultipleButton"
      [popupTitle]="choicesLanguage.f3MeWebApiApproveTargetsChangeMultipleTitle"
      [columnHeader]="choicesLanguage.f3MeWebApiApproveTargetsChangeMultipleColumn"
      [fromHeader]="choicesLanguage.f3MeWebApiApproveTargetsChangeMultipleFrom"
      [toHeader]="choicesLanguage.f3MeWebApiApproveTargetsChangeMultipleTo"
      [extraInfo]="choicesLanguage.f3MeWebApiApproveTargetsChangeMultipleExtraInfo"
      [confirmMessageBeforeShowPopup]="choicesLanguage.f3MeWebApiApproveTargetsChangeMultipleExistingChanges"
      [confirmBeforeShowPopup]="canSubmit" (apply)="changeMultiple($event)">
    </app-change-multiple-lookup-columns>
  </div>
</div>