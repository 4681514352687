import { catchError, map, mergeMap, tap } from "rxjs/operators";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { confirm } from "devextreme/ui/dialog";
import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { WebLanguage } from "../../common/language/weblanguage.service";
import { UserClaimStateDto } from "../../common/models/dto/UserClaimState-dto";
import { TokenData } from "../baseservice/_models/token.model";
import { EnvironmentSettings } from "../baseservice/environment-settings";
import { HeaderInterceptor } from "../baseservice/httpinterceptor";
import { PlUserStorage } from "../baseservice/pluserstorage";
import { RouteNavigation } from "../baseservice/route-navigation";
import { ErrorDectedType } from "../errorhandler/ErrorDetectedType";
import { ErrorDetection } from "../errorhandler/ErrorDetection";
import { ElementHelper } from "../helpers/element.helper";
import { UserInformationService } from "./user-information.service";

// eslint-disable-next-line @typescript-eslint/naming-convention
declare var pl_hr_f3_endpointConfiguration: any;

@Injectable()
export class PlAuthenticationService extends UserInformationService {
  public constructor(private routeNavigation: RouteNavigation, public http: HttpClient, public envSettings: EnvironmentSettings, private language: WebLanguage) {
    super(http, envSettings);
  }

  public _username: string;

  public windowsLogin(): Observable<boolean> {
    const getCurrentUser = this.retrieveCurrentUserValue(PlUserStorage.token);
    const getsuserMapped = getCurrentUser.pipe(
      map((res) => {
        if (res === false) {
          console.log("external login: Move to external login location");
          const routeToGoTo = (pl_hr_f3_endpointConfiguration || {}).LoginRoute || "/account/login";
          this.moveToLocation(routeToGoTo);
        }
        return res;
      }),
    );
    return getsuserMapped;
  }

  private moveToLocation(path: string) {
    let uri = path;
    if (path.startsWith("http") === false) {
      const location = (window as any).location;
      if (path.length > 0 && path[0] !== "/") {
        path = "/" + path;
      }
      uri = location.protocol + "//" + location.host + path;
    }
    // console.log("would move to: " + uri);
    (window as any).location = uri;
  }

  private windowsLogOff(): Observable<boolean> {
    PlUserStorage.logOut();
    this.moveToLocation("/account/login");
    return of(true);
  }

  public login(username, password): Observable<boolean> {
    this._username = username;
    const dataToSend = "grant_type=password&username=" + username + "&password=" + password;
    const tokenUri = this.envSettings.combinePath(this.envSettings.authUri(), "token");

    let temptoken: TokenData;
    const obs = this.http
      .post(tokenUri, dataToSend)
      .pipe(
        catchError((error) => {
          if (ErrorDetection.findErrorType(error, this.envSettings) == ErrorDectedType.ApiUnAuthorized) {
            console.log("Unauthorized to retrieve token with given credentials.");
            return of(new TokenData());
          } else {
            console.log("Error on token retrieval:", error);
          }
        }),
      )
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          const token = response as TokenData;
          temptoken = token;
          return temptoken !== undefined;
        }),
      );
    return this.afterLoginCall(obs, () => temptoken);
  }

  private tokenExpirationTimeoutId: any;

  private hasToken(): boolean {
    if (this.envSettings.useExternalAuthentication() === true || PlUserStorage.hasUser() === false) {
      this.cleanTokenExpirationMonitor();
      return false;
    }
    return true;
  }

  public refreshUserData(): Observable<boolean> {
    if (this.hasToken() === false) {
      return this.reloadCurrentUser();
    }

    const tokenUri = this.envSettings.combinePath(this.envSettings.authUri(), "token");

    return this.http
      .get<TokenData>(tokenUri)
      .pipe(
        catchError((error) => {
          console.log("Error on token retrieval", error);
          return of(new TokenData());
        }),
      )
      .pipe(
        mergeMap((tokenData) => {
          var retrieveResult = this.retrieveCurrentUserValue(tokenData);
          this.monitorTokenExpirationFromToken(tokenData);
          return retrieveResult;
        }),
      );
  }

  private cleanTokenExpirationMonitor() {
    if (ElementHelper.isNullOrUndefined(this.tokenExpirationTimeoutId) === false) {
      clearTimeout(this.tokenExpirationTimeoutId);
    }
  }

  private monitorTokenExpirationFromToken(tokenData: TokenData) {
    if (tokenData) {
      this.monitorTokenExpiration(tokenData.expires_in);
    }
  }

  private monitorTokenExpiration(expiresIn: number) {
    const thresholdInSeconds = 60;
    let timeoutValue = expiresIn - thresholdInSeconds;

    if (timeoutValue <= 0) {
      timeoutValue = 0;
    }

    this.cleanTokenExpirationMonitor();

    if (this.hasToken() === false) {
      return;
    }

    this.tokenExpirationTimeoutId = setTimeout(() => {
      const result = confirm(this.language.RefreshTokenConfirmationMessage, this.language.RefreshTokenConfirmationTitle);
      result.then((dialogResult) => {
        if (dialogResult === true) {
          console.log("Refreshing token.");
          this.refreshUserData().subscribe();
        }
      });
    }, timeoutValue * 1000);
  }

  public afterLoginCall(loginAction: Observable<boolean>, temptoken: () => TokenData): Observable<boolean> {
    const obs = loginAction.pipe(
      mergeMap((loginSucces) => {
        return this.afterLoginCallOnResultValue(loginSucces, temptoken);
      }),
    );
    return obs;
  }

  private afterLoginCallOnResultValue(loginSucces: boolean, temptoken: () => TokenData) {
    if (loginSucces) {
      const tokenValue = temptoken();
      if (tokenValue) {
        return this.retrieveCurrentUserValue(tokenValue).pipe(
          tap(() => {
            if (tokenValue) {
              this.monitorTokenExpirationFromToken(tokenValue);
            }
          }),
        );
      } else {
        console.log("No token available");
        return of(false);
      }
    } else {
      return of<boolean>(false);
    }
  }

  public retrieveCurrentUserClaims(): Observable<UserClaimStateDto> {
    // Due to a cyclic dependency we can not user our 'Services' class but have to use
    // http directly.
    console.log("retieve user claims");

    const apiUri = this.envSettings.combinePath(this.envSettings.baseUri(), "claims");
    const header = HeaderInterceptor.getOptionsCustomToken(PlUserStorage.token);

    const userDataGet = this.http.get<UserClaimStateDto>(apiUri, header);

    return userDataGet;
  }

  public logout(): void {
    console.log("log out user");
    this.resetUserStorage();

    if (this.envSettings.useExternalAuthentication()) {
      this.windowsLogOff().subscribe((res) => {
        this.goToLogin();
      });
    } else {
      this.goToLogin();
    }
  }

  public resetUserStorage(): void {
    this.cleanTokenExpirationMonitor();
    PlUserStorage.logOut();
  }

  private goToLogin() {
    this.routeNavigation.navigate(this.envSettings.loginRoute());
  }
}
