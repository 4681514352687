<table class="table no-border with-head">
  <thead>
    <tr>
      <td>{{ languageService.f3MeWebApiSimulateParts }}</td>
      <td>{{ languageService.f3MeWebApiSimulateNow }}</td>
      <td>{{ languageService.f3MeWebApiSimulateSimulated }}</td>
    </tr>
  </thead>
  <tbody *ngFor="let value of simulationData.output">
    <tr>
      <td>
        <strong>{{ value.title }}</strong>
      </td>
      <td>
        <strong>{{ value.value }}</strong>
        {{ value.valuePostfix }}
      </td>
      <td>
        <strong>{{ value.simulatedValue }}</strong>
        {{ value.valuePostfix }}
      </td>
    </tr>
  </tbody>
</table>