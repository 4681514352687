import { DxAccordionModule, DxBoxModule, DxButtonModule, DxDataGridModule, DxFileUploaderModule, DxPieChartModule, DxPopoverModule, DxProgressBarModule, DxToastModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SmartEbsChartComponent } from "./smart-ebs-chart.component";
import { SmartEbsDesignerComponent } from "./smart-ebs-designer.component";
import { SmartEbsSelecterComponent } from "./smart-ebs-selecter.component";
import { SmartEbsUploaderComponent } from "./smart-ebs-uploader.component";

@NgModule({
  imports: [CommonModule, DxPopoverModule, DxFileUploaderModule, DxProgressBarModule, DxAccordionModule, DxDataGridModule, DxBoxModule, DxPieChartModule, DxToastModule, DxButtonModule],
  exports: [],
  declarations: [SmartEbsDesignerComponent, SmartEbsUploaderComponent, SmartEbsSelecterComponent, SmartEbsChartComponent],
  providers: [SmartEbsDesignerComponent],
})
export class SmartEbsModule {}
