
// t4tsCode gen": "0.8.2.0 for file SourceSpecificationDto.cs
// Don't adjust manually!

export class SourceSpecificationDto {
    public ShortName: string;
    public SortOrder: number;
    public ResultValue: string;
}

