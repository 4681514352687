import { Component, Input } from "@angular/core";

import { CmsLanguage } from "../../../../../employee-common/cms/language/cmslanguage.service";
import { SimulationIO } from "../_models/simulation-io.model";

@Component({
  selector: "app-simulate-header",
  templateUrl: "./simulate-header.component.html",
  styleUrls: ["./simulate-header.component.scss"],
})
export class SimulateHeaderComponent {
  public constructor(public languageService: CmsLanguage) {}

  @Input() public simulationData: SimulationIO;
}
