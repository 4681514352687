import { DateTimeUnitDto } from "../../../../../common/models/dto/DateTimeUnit-dto";
import { InspireLanguageService } from "../../shared/language/inspire-language.service";

export class UserLoginGroup {
  constructor(public value: DateTimeUnitDto, public text: string) {}

  public static create(language: InspireLanguageService): UserLoginGroup[] {
    const values: UserLoginGroup[] = [];

    values.push(new UserLoginGroup(DateTimeUnitDto.Hour, language.UserLoginGroupByUnitsHour));
    values.push(new UserLoginGroup(DateTimeUnitDto.Day, language.UserLoginGroupByUnitsDay));
    values.push(new UserLoginGroup(DateTimeUnitDto.Month, language.UserLoginGroupByUnitsMonth));
    values.push(new UserLoginGroup(DateTimeUnitDto.Year, language.UserLoginGroupByUnitsYear));

    return values;
  }
}
