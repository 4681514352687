<div class="container">
  <h1>{{ language.f3MeWebApiSearch }}</h1>
  <form (submit)="search($event)">
    <dx-text-box [placeholder]="language.f3MeWebApiSearch + '...'" [(value)]="searchValue"></dx-text-box>
    <dx-button icon="search" [useSubmitBehavior]="true"> </dx-button>
  </form>
  <span class="result-amount" *ngIf="searchedValue"> {{ results?.length }} {{ language.f3MeWebApiSearchResultAmount }} "{{ searchedValue }}" </span>

  <app-search-result *ngFor="let result of results" [searchResult]="result"></app-search-result>
</div>
