import { LanguageItem } from "../../../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";
import { SimulationBase } from "./simulation-base.model";

export class SimulationOutput extends SimulationBase {
  public constructor(
    languageSelection: PlLanguageSelection,
    public shortName: string,
    title: LanguageItem,
    valuePostfix: LanguageItem,
    public sortOrder: number,
    public value: string,
    valueAsNumber: number,
    public simulatedValue: string,
    public simulatedValueAsNumber: number,
  ) {
    super(languageSelection, shortName, title, valuePostfix, sortOrder, value, valueAsNumber);
  }
}
