import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";
import { TargetOptionChoiceTranslationDto } from "../../../../../employee-common/models/choices/dto/TargetOptionChoiceTranslation-dto";
import { TargetOptionChoicesTranslationDto } from "../../../../../employee-common/models/choices/dto/TargetOptionChoicesTranslation-dto";
import { InspireLanguageService } from "../language/inspire-language.service";
import { TargetOptionChoicesTranslation } from "./models/target-option-choices-translation.model";

@Injectable()
export class TargetTranslationsChoicesService extends PlBaseService {
  private endPoint = "targettranslationchoices";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator, private language: InspireLanguageService, private selectLanguage: PlLanguageSelection) {
    super(http, envSettings, loadIndicator);
  }

  public retrieve(referenceCategoryId: number): Observable<TargetOptionChoicesTranslation> {
    const thisService = this;
    return this.getDataTransformed<TargetOptionChoicesTranslationDto>(this.endPoint + "/" + referenceCategoryId).pipe(map((r) => thisService.extract(r)));
  }

  private extract(dto: TargetOptionChoicesTranslationDto): TargetOptionChoicesTranslation {
    if (dto) {
      const result = new TargetOptionChoicesTranslation(this.language, this.selectLanguage);
      result.copyFromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  public save(dto: TargetOptionChoiceTranslationDto): Observable<void> {
    const url = this.endPoint;
    return this.update(dto, url);
  }
}
