import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";
import { TargetOptionTranslationDto } from "../../../../../employee-common/models/choices/dto/TargetOptionTranslation-dto";
import { TargetOptionsTranslationDto } from "../../../../../employee-common/models/choices/dto/TargetOptionsTranslation-dto";
import { InspireLanguageService } from "../language/inspire-language.service";
import { TargetOptionsTranslation } from "./models/target-options-translation.model";

@Injectable()
export class TargetTranslationsService extends PlBaseService {
  private endPoint = "targettranslation";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator, private language: InspireLanguageService, private selectLanguage: PlLanguageSelection) {
    super(http, envSettings, loadIndicator);
  }

  public retrieve(targetId: number): Observable<TargetOptionsTranslation> {
    const thisService = this;
    return this.getDataTransformed<TargetOptionsTranslationDto>(this.endPoint + "/" + targetId).pipe(map((r) => thisService.extract(r)));
  }

  private extract(dto: TargetOptionsTranslationDto): TargetOptionsTranslation {
    if (dto) {
      const result = new TargetOptionsTranslation(this.language, this.selectLanguage);
      result.copyFromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  public save(dto: TargetOptionTranslationDto): Observable<void> {
    const url = this.endPoint;
    return this.update(dto, url);
  }
}
