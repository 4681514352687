import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ExogenousCategories, ExogenousVariable, ExogenousVariables } from "../../../common/modelelements";

import { WebLanguage } from "../../../common/language/weblanguage.service";

@Component({
  selector: "app-triggers-edit",
  templateUrl: "./triggers-edit.component.html",
  styleUrls: ["./triggers-edit.component.scss"],
})
export class TriggersEditComponent {
  public constructor(public language: WebLanguage) {
    this.getCategoryDisplayValue = this.getCategoryDisplayValue.bind(this);
    this.exogUpdated = new EventEmitter<ExogenousVariable>();
  }

  @Input()
  public exogenousVariables: ExogenousVariables;

  @Input()
  public exogenousCategories: ExogenousCategories;

  public getCategoryDisplayValue(rowData: any) {
    if (!this.exogenousCategories || this.exogenousCategories === null) {
      return this.language.edittriggerNocategory;
    }
    const categoryId = rowData.categoryId;
    const category = this.exogenousCategories.find(categoryId);
    if (category && category !== null) {
      return category.shortName;
    }
    return this.language.edittriggerNocategory;
  }

  public onEditorPreparing(e) {
    if (e.parentType === "dataRow" && e.dataField !== "sortOrder") {
      e.editorName = "dxTextArea";
    } else {
      e.editorName = "dxNumberBox";
    }
  }

  public onRowUpdated(e) {
    this.exogUpdated.emit(e.data);
  }

  @Output()
  public exogUpdated: EventEmitter<ExogenousVariable>;
}
