import { ExogenousInputChoiceOptionValueDto } from './ExogenousInputChoiceOptionValue-dto';
import { ExogenousInputOptionDto } from './ExogenousInputOption-dto';

// t4tsCode gen": "0.8.2.0 for file ExogenousInputOptionChoice.cs
// Don't adjust manually!

export class ExogenousInputOptionChoiceDto extends ExogenousInputOptionDto {
    public Value: ExogenousInputChoiceOptionValueDto;
    public Values: ExogenousInputChoiceOptionValueDto [] = [];
    public static TypeName: string = "choice";
}

