import { Component, Input, OnInit } from "@angular/core";

import { InspireLanguageService } from "../shared/language/inspire-language.service";
import { LanguageItem } from "../../../../angular-common/language/language-dto.model";
import { LanguageMetaDataDto } from "../../../../common/models/dto/LanguageMetaData-dto";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";

@Component({
  selector: "app-target-description",
  templateUrl: "cms-target-description.component.html",
  styleUrls: ["cms-target-description.component.scss"],
})
export class TargetDescriptionLanguageItemEditComponent implements OnInit {
  constructor(public languageSelection: PlLanguageSelection, public language: InspireLanguageService) {}

  public toolBarItems: any = ["bold", "italic", "color", "background", "link"];
  public languages: string[] = [];

  @Input() target: LanguageItem;

  ngOnInit() {
    this.languageSelection.languages.Languages.forEach((l) => {
      this.languages.push(l.Id);
    });
    if (this.languages.length > 0) {
      this.selectedItem = this.languages[0];
    }
  }

  customizeLabel(data: LanguageMetaDataDto) {
    return data.Id;
  }

  private _selectedItem: string;
  public get selectedItem(): string {
    return this._selectedItem;
  }
  public set selectedItem(v: string) {
    this._selectedItem = v;
  }
}
