import { LanguageItem } from "../../../../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../../../../angular-common/language/pl-language-selection";
import { LanguageBuilder } from "../../../../../../common/language/language-builder";
import { TargetOptionChoiceTranslationDto } from "../../../../../../employee-common/models/choices/dto/TargetOptionChoiceTranslation-dto";
import { InspireLanguageService } from "../../language/inspire-language.service";

export class TargetOptionChoiceTranslation {
  public constructor(private language: InspireLanguageService, private selectLanguage: PlLanguageSelection) {
    this.description = language.languageBuilder().createLanguageItem();
  }

  public description: LanguageItem;
  public categoryId: number;
  public referenceId: number;
  public coreDescription: string;
  public descriptionCopy: LanguageItem;

  public copyFromDto(dto: TargetOptionChoiceTranslationDto) {
    LanguageBuilder.copy(dto.Description, this.description);
    this.categoryId = dto.CategoryId;
    this.coreDescription = dto.CoreDescription;
    this.referenceId = dto.ReferenceId;
  }

  public copyToDto(): TargetOptionChoiceTranslationDto {
    const result = new TargetOptionChoiceTranslationDto();
    result.Description = LanguageBuilder.duplicate(this.description);
    result.CategoryId = this.categoryId;
    result.ReferenceId = this.referenceId;
    result.CoreDescription = this.coreDescription;
    return result;
  }
}
