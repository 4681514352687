<div *ngIf="contentLoaded" class="outer-div panel-with-header-and-content">
    <div class="inner-div">
        <dx-chart [dataSource]="dataPoints" [title]="languageService.UserLoginDashboardName">
            <dxo-zoom-and-pan argumentAxis="both"></dxo-zoom-and-pan>
            <dxo-export [enabled]="true"></dxo-export>
            <dxi-series argumentField="Text" valueField="Value" type="bar"></dxi-series>
            <dxo-scroll-bar [visible]="false" position="bottom"> </dxo-scroll-bar>
            <dxo-legend [visible]="false"></dxo-legend>
            <dxo-tooltip [enabled]="true" location="edge" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
        </dx-chart>
        <div class="action">
            <div class="dx-field">
                <div class="dx-field-label">{{languageService.UserLoginFrom}}</div>
                <div class="dx-field-value">
                    <dx-date-box [(value)]="minDate" type="date"> </dx-date-box>
                </div>
                <div class="dx-field-label">{{languageService.UserLoginTo}}</div>
                <div class="dx-field-value">
                    <dx-date-box [(value)]="maxDate" type="date"> </dx-date-box>
                </div>
                <div class="dx-field-label">{{languageService.UserLoginGroupByTitle}}:</div>
                <dx-select-box id="FilterBox" [dataSource]="groupings" [(value)]="currentGrouping" displayExpr="text"
                    (onValueChanged)="filterByUnit()"></dx-select-box>
            </div>
        </div>

    </div>
</div>