import { Component, Input, OnInit } from "@angular/core";

import { AttachmentDto } from "../../../../common/models/dto/AttachmentDto-dto";
import { ChoicesLanguage } from "../../../../employee-common/choices/language/choiceslanguage.service";
import { FileService } from "./files.service";
import { InspireLanguageService } from "../shared/language/inspire-language.service";
import themes from "devextreme/ui/themes";

@Component({
  selector: "app-files",
  templateUrl: "./files.component.html",
  styleUrls: ["./files.component.scss"],
})
export class FilesComponent implements OnInit {
  @Input() private userId: string;

  data: AttachmentDto[];
  allMode: string;
  checkBoxesMode: string;

  downloadSelectedButton: string = "Download selected";

  constructor(private fileService: FileService, public languageService: InspireLanguageService, public choicesLanguage: ChoicesLanguage) {
    this.downloadCellFile = this.downloadCellFile.bind(this);
    this.allMode = "allPages";
    this.checkBoxesMode = themes.current().startsWith("material") ? "always" : "onClick";
  }

  ngOnInit() {
    this.loadFiles();
  }

  downloadCellFile(e) {
    let dataDto: AttachmentDto = e.row.data;
    var fileName = dataDto.Name;
    this.download(fileName);
  }
  download(fileId: string) {
    this.fileService.getUserFileById(this.userId.toString(), fileId).subscribe((x) => {
      const blob = new Blob([x], { type: x.type });

      const anchorElement = window.document.createElement("a");
      anchorElement.href = window.URL.createObjectURL(blob);
      anchorElement.download = fileId;
      document.body.appendChild(anchorElement);

      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(anchorElement.href);
    });
  }

  loadFiles() {
    this.fileService.getUserFiles(this.userId).subscribe((x) => {
      this.data = x;
    });
  }
}
