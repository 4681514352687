import { Observable, Subject } from "rxjs";

import { map } from "rxjs/operators";
import { TargetSettings } from "../../../../../employee-common/choices/targets/targetsettings";
import { TargetEditService } from "./targetedit.service";

export class TargetEditTargetSelector {
  public constructor(private targetEditService: TargetEditService) {
    this._selectedTargetChanged = new Subject<void>();
    this.onSelectedTargetChanged = this._selectedTargetChanged.asObservable();
  }

  public init(): Observable<void> {
    const retrieveTargets = this.targetEditService.retrieveTargetsGrouping().pipe(
      map((targets) => {
        this._targets = targets.targetSettings;
        if (this.targetsAvailable) {
          this.selectedTarget = this._targets[0];
        }
      }),
    );

    return retrieveTargets;
  }

  public get targets(): TargetSettings[] {
    return this._targets;
  }
  private _targets: TargetSettings[];

  public get selectedTarget(): TargetSettings {
    return this._selectedTarget;
  }
  public set selectedTarget(_newTarget: TargetSettings) {
    this._selectedTarget = _newTarget;
    this._selectedTargetChanged.next();
  }
  private _selectedTarget: TargetSettings;

  public get targetsAvailable(): boolean {
    return this._targets !== undefined && this._targets.length > 0;
  }

  private _selectedTargetChanged: Subject<void>;
  public onSelectedTargetChanged;
}
