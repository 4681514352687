import { Sorter } from "../../../angular-common/sorting";
import { ExogenousCategoryDto } from "../../models/dto/ExogenousCategoryDto-dto";
import { ExogenousCategory } from "./exogenous-category.model";

export class ExogenousCategories {
  public constructor(private _categories: ExogenousCategory[]) {
    if (_categories === undefined || _categories === null) {
      _categories = [];
    }

    this._categories = this._categories.sort(Sorter.compareSortOrder);
  }

  public find(categoryId: number): ExogenousCategory {
    if (categoryId === undefined || categoryId === null) {
      return undefined;
    }

    return this._categories.find(c => {
      return c.keyId === categoryId;
    });
  }

  public get all(): ExogenousCategory[] {
    return this._categories;
  }

  public add(category: ExogenousCategory) {
    this._categories.push(category);
  }

  public copyFromDto(exogenouscategoriesDto: ExogenousCategoryDto[]) {
    if (!exogenouscategoriesDto || exogenouscategoriesDto === null) {
      return;
    }

    exogenouscategoriesDto.forEach(exogCatDto => {
      const newCat = new ExogenousCategory();
      newCat.fromDto(exogCatDto);
      this.add(newCat);
    });
  }
}
