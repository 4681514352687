import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";
import { SimulationGroupingDto } from "../../simulate/dto/SimulationGrouping-dto";
import { SimulationModelElement } from "./simulation-modelelement";
import { SimulationModelGrouping } from "./simulation-modelgrouping";

@Injectable()
export class SimulationModelElementsService extends PlBaseService {
  private endPoint: string = "simulationedit";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getAllData(): Observable<SimulationModelGrouping> {
    const thisService = this;
    const result = this.getDataTransformed<SimulationGroupingDto>(this.endPoint).pipe(map((r) => thisService.extractAllData(r)));
    return result;
  }

  private extractAllData(dto: SimulationGroupingDto): SimulationModelGrouping {
    if (dto) {
      const result = new SimulationModelGrouping();
      result.copyFromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  public saveOptions(simulationModelElement: SimulationModelElement): Observable<any> {
    const url = this.endPoint;
    return this.update(simulationModelElement.toDto(simulationModelElement), url);
  }
}
