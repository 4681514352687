import { Component, OnInit, ViewChild } from "@angular/core";

import { DxAccordionComponent } from "devextreme-angular";
import { confirm } from "devextreme/ui/dialog";
import { PaginatedDxData } from "../../../../angular-common";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { ModelElement } from "../../../../common/modelelements";
import { ExogenousEdit } from "../../../../common/modelelements/categories/exogenous-edit.model";
import { Formula } from "../../../../common/modelelements/formulas/formula.model";
import { TargetSettings } from "../../../../employee-common/choices/targets/targetsettings";
import { CmsLanguage } from "../../../../employee-common/cms/language/cmslanguage.service";
import { SharedExpressions } from "../../../../employee-common/cms/shared/shared-expressions";
import { TargetEditService } from "../shared/target-edit/targetedit.service";

@Component({
  selector: "app-cms-target-edit",
  templateUrl: "./cms-target-edit.component.html",
  styleUrls: ["./cms-target-edit.component.scss"],
})
export class TargetEditComponent implements OnInit {
  public targets: TargetSettings[] = [];
  public exoEdit: ExogenousEdit;
  public formulas: ModelElement[];
  public paginatedFormulas: PaginatedDxData = null;
  public contentLoaded: boolean = false;
  public statusWithoutSuffix: string;
  public _selectedIndex: number = -1;
  @ViewChild(DxAccordionComponent) accordion: DxAccordionComponent;

  public popupVisible: boolean = false;

  constructor(private targetEditService: TargetEditService, public language: CmsLanguage, private selectLanguage: PlLanguageSelection) {}

  public get selectedIndex(): number {
    return this._selectedIndex;
  }
  public set selectedIndex(v: number) {
    this._selectedIndex = v;
  }

  ngOnInit(): void {
    this.retreiveData();
    this.contentLoaded = true;
  }

  private retreiveData() {
    this.exoEdit = new ExogenousEdit();
    this.targetEditService.retrieveTargetsGrouping().subscribe((m) => {
      this.targets = m.targetSettings;
      this.exoEdit = m.exogenousEdit;

      const newFormulas: Formula[] = [];
      m.formulas.forEach((formula) => {
        newFormulas.push(formula);
      });
      this.formulas = newFormulas;
      this.paginatedFormulas = new PaginatedDxData(this.formulas);
    });
  }

  public deleteTarget(value: TargetSettings) {
    if (TargetSettings.isNew(value)) {
      this.resetIndex();
      this.targets.splice(
        this.targets.findIndex((x) => x.keyId === value.keyId),
        1,
      );
    } else {
      confirm(this.language.f3MeWebApiTargetDelete, this.language.getStringDef("applicationTitle")).then((answer) => {
        if (answer === true) {
          this.resetIndex();
          this.targetEditService.deleteTarget(value).subscribe((m) => {
            this.targets.splice(
              this.targets.findIndex((x) => x.keyId === value.keyId),
              1,
            );
          });
        }
      });
    }
  }

  private resetIndex() {
    this.selectedIndex = -1;
  }

  colapseItem() {
    this.accordion.instance.collapseItem(this.selectedIndex);
  }

  public get hasNewTarget(): boolean {
    const result = this.targets.find((t) => TargetSettings.isNew(t));
    if (result) {
      return true;
    } else {
      return false;
    }
  }

  public newTarget() {
    this.colapseItem();
    this.targets.push(TargetSettings.createDefault(this.language, this.selectLanguage));
    if (this.targets.length > 0) {
      this.selectedIndex = this.targets.findIndex((t) => TargetSettings.isNew(t));
    }
  }

  public saveTarget(value: TargetSettings) {
    var cleanedName = SharedExpressions.cleanUpShortName(value.shortName);
    this.popupVisible = SharedExpressions.checkforCleanedShortName(value.shortName, cleanedName);
    value.shortName = cleanedName;

    if (TargetSettings.isNew(value)) {
      this.targetEditService.inserTarget(value).subscribe((m) => {
        value.keyId = m.keyId;
      });
    } else {
      this.targetEditService.updateTarget(value).subscribe((m) => {});
    }
  }
}
