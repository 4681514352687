import { PlLanguageSelection } from "../../../../../../angular-common/language/pl-language-selection";
import { TargetOptionChoicesTranslationDto } from "../../../../../../employee-common/models/choices/dto/TargetOptionChoicesTranslation-dto";
import { InspireLanguageService } from "../../language/inspire-language.service";
import { TargetOptionChoiceTranslation } from "./target-option-choice-translation.model";

export class TargetOptionChoicesTranslation {
  public constructor(private language: InspireLanguageService, private selectLanguage: PlLanguageSelection) {}
  public translations: TargetOptionChoiceTranslation[] = [];

  public copyFromDto(dto: TargetOptionChoicesTranslationDto) {
    dto.Translations.forEach((t) => {
      const choice = new TargetOptionChoiceTranslation(this.language, this.selectLanguage);
      choice.copyFromDto(t);
      this.translations.push(choice);
    });
  }
}
