import { ModelElementSpecification } from "../../../../../common/modelelements/modelelementsspecification";
import { SimulationGroupingDto } from "../../simulate/dto/SimulationGrouping-dto";
import { SimulationModelElement } from "./simulation-modelelement";

export class SimulationModelGrouping {
  public constructor() {}

  public inputOptions: ModelElementSpecification[] = [];
  public outputOptions: ModelElementSpecification[] = [];
  public currentConfiguration: SimulationModelElement = new SimulationModelElement();

  public copyFromDto(dto: SimulationGroupingDto) {
    const newInputOptions: ModelElementSpecification[] = [];
    dto.InputModelElements.forEach((element) => {
      const newInputOption = new ModelElementSpecification();
      newInputOption.copyFromDto(element);
      newInputOptions.push(newInputOption);
    });
    this.inputOptions = newInputOptions;

    const newOutputOptions: ModelElementSpecification[] = [];
    dto.OutputModelElements.forEach((element) => {
      const newOutputOption = new ModelElementSpecification();
      newOutputOption.copyFromDto(element);
      newOutputOptions.push(newOutputOption);
    });
    this.outputOptions = newOutputOptions;

    this.currentConfiguration.copyFromDto(dto.SimulationConfiguration);
  }
}
