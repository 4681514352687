
// t4tsCodeFromCs": "0.8.2.0 for file DateTimeUnit.cs
// Don't adjust manually!
export enum DateTimeUnitDto {
        None = 0,
        Hour = 1,
        Day = 2,
        Month = 3,
        Year = 4,
}

