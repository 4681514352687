<div *ngIf="contentLoaded">
  <div class="buttonBar">
    <dx-button [disabled]="hasNewTarget" (click)="newTarget()" icon="fas fa-plus" [hint]="language.ButtonNew"
      [text]="language.ButtonNew"></dx-button>
  </div>
  <dx-accordion [dataSource]="targets" [collapsible]="true" [(selectedIndex)]="selectedIndex">
    <div *dxTemplate="let target of 'title'">
      <h2>{{ target.title }}</h2>
    </div>
    <div *dxTemplate="let item of 'item'">
      <dx-button icon="save" (click)="saveTarget(item)" [hint]="language.ButtonSave"></dx-button>
      <dx-button (click)="deleteTarget(item)" icon="fas fa-trash" [hint]="language.ButtonDelete"> </dx-button>
      <app-target-content [target]="item" [exoEdit]="exoEdit" [paginatedFormulas]="paginatedFormulas">
      </app-target-content>
    </div>
  </dx-accordion>
</div>

<dx-popup [width]="300" [height]="200" [showTitle]="true" [title]="language.InvalidShortname" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="popupVisible">
  <label>{{ language.ShortnameModifiedNotification }}</label>
</dx-popup>