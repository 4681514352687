import { CommonModule } from "@angular/common";
import { DxSelectBoxModule } from "devextreme-angular";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TargetEditService } from "./targetedit.service";
import { TargetSelectorComponent } from "./target-selector.component";

@NgModule({
  declarations: [TargetSelectorComponent],
  imports: [CommonModule, HttpClientModule, DxSelectBoxModule],
  exports: [TargetSelectorComponent],
  providers: [TargetEditService],
})
export class TargetEditModule {}
