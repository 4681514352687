<header class="page-header" *ngIf="!isLoginPage">
  <div class="container">
    <div class="row">
      <nav class="navbar navbar-default">
        <app-user-employee-login></app-user-employee-login>
        <div class="container-fluid">
          <app-company-logo *ngIf="hasUser"></app-company-logo>
          <app-personal-menu [isLoginPage]="isLoginPage"></app-personal-menu>
          <app-portal-menu [isLoginPage]="isLoginPage"></app-portal-menu>
          <app-search-bar *ngIf="searchEnabled()" (search)="search($event)"></app-search-bar>
        </div>
      </nav>
    </div>
  </div>
</header>
<div [ngClass]="isLoginPage === false ? 'container main' : ''">
  <div [ngClass]="isLoginPage === false ? 'row' : ''">
    <app-main-menu *ngIf="!isLoginPage" [isLoginPage]="isLoginPage"></app-main-menu>
    <div>
      <!-- Routed views go here -->
      <router-outlet></router-outlet>
    </div>
    <app-loadindicator-component></app-loadindicator-component>
  </div>
</div>

<app-pl-errorfeedback></app-pl-errorfeedback>
