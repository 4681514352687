import { Component } from "@angular/core";

@Component({
  selector: "app-notfound-page",
  template: `
    <div class="errorDisplay">
      <h2>Error: page not found, error code 404.....</h2>
      <p class="linkPointer">
        <a routerLink="/home" routerLinkActive="active-link">click here to go back.</a>
      </p>
      <p></p>
    </div>
  `,
  styles: [
    `
      .errorDisplay {
        position: relative;
        display: block;
        width: 50%;
        height: 20%;
        margin: auto;
        margin-top: 200px;
        text-align: center;
      }

      .linkPointer {
        cursor: pointer;
      }
    `,
  ],
})
export class PageNotFoundComponent {}
