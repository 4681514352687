import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserClaimStateDto } from "../../common/models/dto/UserClaimState-dto";
import { EnvironmentSettings } from "../baseservice/environment-settings";
import { PlBaseService } from "../baseservice/plbase.service";
import { PlLoadIndicator } from "../loadindicator/loadindicator";

@Injectable()
export class AuthenticationClaimsService extends PlBaseService {
  private endPoint: string = "claims";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveCurrentUserClaims(): Observable<UserClaimStateDto> {
    return this.getData<UserClaimStateDto>(this.endPoint);
  }
}
