import { SourceEntrySpecificationDto } from "../../../../../../employee-common/models/choices/dto/SourceEntrySpecificationDto-dto";
import { SourceEntrySpecification } from "./source-entry-specification.model";

export class SourceEntrySpecifications {
  private _sourceEntrySpecifications: SourceEntrySpecification[];
  public constructor(sourceEntrySpecifications: SourceEntrySpecification[]) {
    if (!sourceEntrySpecifications || sourceEntrySpecifications === null) {
      this._sourceEntrySpecifications = [];
    } else {
      this._sourceEntrySpecifications = sourceEntrySpecifications;
    }
  }

  public get all(): SourceEntrySpecification[] {
    return this._sourceEntrySpecifications;
  }

  public copyFromDto(dtos: SourceEntrySpecificationDto[]) {
    dtos.forEach((dto) => {
      const newSpec = new SourceEntrySpecification();
      newSpec.copyFromDto(dto);
      this._sourceEntrySpecifications.push(newSpec);
    });
  }

  public toDto() {
    const result: SourceEntrySpecificationDto[] = [];
    this._sourceEntrySpecifications.forEach((entry) => {
      result.push(entry.toDto());
    });
    return result;
  }

  public retrieveMaxSortOrder(): number {
    let result = 0;
    this._sourceEntrySpecifications.forEach((entry) => {
      if (entry.sortOrder > result) {
        result = entry.sortOrder;
      }
    });
    return result;
  }

  public add(entry: SourceEntrySpecification) {
    this._sourceEntrySpecifications.push(entry);
  }

  public get entriesAreValid(): boolean {
    if (!this._sourceEntrySpecifications || this._sourceEntrySpecifications === null || this._sourceEntrySpecifications.length === 0) {
      return true;
    }

    return this._sourceEntrySpecifications.find((entry) => entry.isValid === false) === undefined;
  }
}
