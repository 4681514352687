import { Component, Input } from "@angular/core";

import { PaginatedDxData } from "../../../../angular-common";
import { ExogenousEdit } from "../../../../common/modelelements/categories/exogenous-edit.model";
import { TargetSettings } from "../../../../employee-common/choices/targets/targetsettings";
import { CmsLanguage } from "../../../../employee-common/cms/language/cmslanguage.service";

@Component({
  selector: "app-target-content",
  templateUrl: "./cms-target-content.component.html",
  styleUrls: ["./cms-target-content.component.scss"],
})
export class TargetContentComponent {
  constructor(public language: CmsLanguage) {}

  @Input() target: TargetSettings;
  @Input() exoEdit: ExogenousEdit;
  @Input() paginatedFormulas: PaginatedDxData;
}
