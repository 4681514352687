import { ExogenousCategoryDto } from "../../models/dto/ExogenousCategoryDto-dto";
import { CategoryBase } from "./category-base.model";

export class ExogenousCategory extends CategoryBase {
  public toDto(): ExogenousCategoryDto {
    const dto = new ExogenousCategoryDto();
    dto.KeyId = this.keyId;
    dto.ShortName = this.shortName;
    dto.SortOrder = this.sortOrder;

    return dto;
  }

  public fromDto(dto: ExogenousCategoryDto) {
    this.keyId = dto.KeyId;
    this.shortName = dto.ShortName;
    this.sortOrder = dto.SortOrder;
  }
}
