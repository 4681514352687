import { Component, EventEmitter, Output } from "@angular/core";

import { IUploadResult } from "./iupload-result";
import { InspireLanguageService } from "../shared/language/inspire-language.service";
import { SmartEbsItem } from "./smart-ebs-item";
import { SmartJsonReader } from "./json-reader";

@Component({
  selector: "app-smart-ebs-uploader",
  templateUrl: "smart-ebs-uploader.component.html",
  styleUrls: ["smart-ebs-uploader.component.scss"],
})
export class SmartEbsUploaderComponent {
  isDropZoneActive = false;
  @Output() uploadResult = new EventEmitter<IUploadResult>(true);
  constructor(public language: InspireLanguageService) {
    this.onDropZoneEnter = this.onDropZoneEnter.bind(this);
    this.onDropZoneLeave = this.onDropZoneLeave.bind(this);
    this.onUploadStarted = this.onUploadStarted.bind(this);
  }

  public files: string[] = [];

  onDropZoneEnter(e) {
    if (e.dropZoneElement.id === "dropzone-external") this.isDropZoneActive = true;
  }

  onDropZoneLeave(e) {
    if (e.dropZoneElement.id === "dropzone-external") this.isDropZoneActive = false;
  }

  onUploadStarted(e: { file: File }) {
    console.log("Upload started", e.file);
    this.files.push(e.file.name);

    let items: SmartEbsItem[] = [];

    if (e.file.type === "application/json") {
      e.file.arrayBuffer().then((v) => {
        const decodedString = String.fromCharCode.apply(null, new Uint8Array(v));
        items = SmartJsonReader.createSmartEbsFromJson(JSON.parse(decodedString));
      });
    } else {
      items = this.createItems();
    }
    setTimeout(() => {
      this.uploadResult.emit({ result: "success", items: items });
    }, 3000);
  }

  private createItems(): SmartEbsItem[] {
    const items: SmartEbsItem[] = [];
    const names = [
      "Base Salary",
      "Holiday allowance",
      "End Year Allowance",
      "Personal Allowance",
      "Group Bonus",
      "Individual Bonus",
      "Health Insurance",
      "Travel Insurance",
      "Other insurance",
      "Pension",
      "Employer contribution",
      "Additional insured",
      "Mobility",
      "Health",
      "Education",
      "Leave",
      "Telecom",
      "IT related",
    ];

    for (let index = 0; index < names.length; index++) {
      const ebsItem = new SmartEbsItem(index + 1, names[index], names[index], undefined);
      items.push(ebsItem);
    }

    return items;
  }
}
