import { Component, OnDestroy } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { ISearchResult } from "./search-result/search-result.component";
import { InspireLanguageService } from "../shared/language/inspire-language.service";
import { Link } from "../../../../employee-common/components/links/link";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { SearchResultDto } from "../../../../employee-common/models/cms/dto/SearchResult-dto";
import { SearchService } from "./search.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-search-screen",
  templateUrl: "./search-screen.component.html",
  styleUrls: ["./search-screen.component.scss"],
})
export class SearchScreenComponent implements OnDestroy {
  public searchValue: string;
  public searchedValue: string;
  public results: ISearchResult[];
  public subscriptions = new Subscription();

  constructor(public language: InspireLanguageService, private route: ActivatedRoute, private searchService: SearchService, private selectLanguage: PlLanguageSelection) {
    this.route.params.subscribe((params) => {
      if (params.query) {
        this.searchValue = params.query;
        this.search();
      }
    });
  }

  public search(e?) {
    if (this.searchValue) {
      this.subscriptions.add(this.searchService.getSearchData(this.searchValue).subscribe((results) => (this.results = this.createResults(results))));
      this.searchedValue = this.searchValue;
    }
    if (e) {
      e.preventDefault();
    }
  }

  private createResults(results: SearchResultDto[]): ISearchResult[] {
    const lan = this.selectLanguage.currentLanguage;
    return results.map((result) => {
      const link = new Link(this.selectLanguage);
      link.copyFromDTO(result.Link);
      const titleWithFallback = this.selectLanguage.currentValueForLanguage(result.Title, lan);
      return { title: titleWithFallback, summary: result.Summary[lan], link };
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
