import { Component, OnInit } from "@angular/core";

import { ModelTreeBranch } from "../../../../angular-common/components/modeltree/modeltreeservice/models/modeltree-branch";
import { InspireLanguageService } from "../shared/language/inspire-language.service";
import { VariantConfiguration } from "../shared/variantselection/variant-configuration";
import { VariantSelectionService } from "../shared/variantselection/variantselection.service";

@Component({
  selector: "app-variant-selection",
  templateUrl: "./cms-variant-selection.component.html",
  styleUrls: ["./cms-variant-selection.component.scss"],
})
export class VariantSelectionComponent implements OnInit {
  public variantConfiguration: VariantConfiguration[] = [];
  private selectedVariant: VariantConfiguration;
  public popupVisible: boolean = false;
  constructor(public language: InspireLanguageService, private variantSelectionService: VariantSelectionService) {}

  ngOnInit(): void {
    this.getCurrentConfig();
  }
  getCurrentConfig() {
    this.variantSelectionService.retrieveSettings().subscribe((r) => {
      r.forEach((config) => {
        this.variantConfiguration.push(config);
      });
    });
  }

  popupToggle(rowData: { data: VariantConfiguration }) {
    this.selectedVariant = rowData.data;
    this.popupVisible = true;
  }

  saveVariant() {
    this.selectedVariant.variantId = this.selectedBranch.element.id;
    this.variantSelectionService.storeVariant(this.selectedVariant).subscribe((r) => {
      this.selectedVariant.variantName = r.Variant.LongName;
    });
    this.popupVisible = false;
  }

  public get selectedBranch(): ModelTreeBranch {
    return this._selectedBranch;
  }
  public set selectedBranch(newBranch: ModelTreeBranch) {
    this._selectedBranch = newBranch;
  }
  private _selectedBranch: ModelTreeBranch;
}
