<dx-form [colCount]="1" [(formData)]="target">
  <dxi-item itemType="group" caption=" ">
    <dxi-item dataField="shortName" [label]="{ text: language.ShortName }"></dxi-item>
    <dxi-item itemType="group" caption="Titles">
      <div *dxTemplate>
        <app-target-title [target]="target.titles"></app-target-title>
      </div>
    </dxi-item>
    <dxi-item dataField="description" [label]="{ text: language.description }">
      <div *dxTemplate>
        <app-target-description [target]="target.description"></app-target-description>
      </div>
    </dxi-item>
    <dxi-item
      dataField="exoCategoryRefId"
      [label]="{
        text: language.f3MeWebApiTargetExogenousCategory
      }"
      editorType="dxSelectBox"
      [editorOptions]="{
        dataSource: exoEdit.exogenousCategories.all,
        displayExpr: 'shortName',
        valueExpr: 'keyId'
      }"
    ></dxi-item>
    <dxi-item
      dataField="status"
      [label]="{ text: language.f3MeWebApiTargetStatusExog }"
      editorType="dxLookup"
      [editorOptions]="{
        dataSource: exoEdit.exogenousVariables.all,
        displayExpr: 'displayName',
        valueExpr: 'shortName'
      }"
    >
    </dxi-item>
    <dxi-item
      dataField="statusChangeDate"
      [label]="{ text: language.f3MeWebApiTargetStatusExogDate }"
      editorType="dxLookup"
      [editorOptions]="{
        dataSource: exoEdit.exogenousVariables.all,
        displayExpr: 'displayName',
        valueExpr: 'shortName'
      }"
    ></dxi-item>
    <dxi-item itemType="group" caption="Totals">
      <dxi-item
        dataField="totalRemaining"
        editorType="dxLookup"
        [label]="{
          text: language.f3MeWebApiTargetRemaining,
          showColon: false
        }"
        [editorOptions]="{
          dataSource: paginatedFormulas,
          displayExpr: 'displayName',
          valueExpr: 'shortName',
          showClearButton: true
        }"
      ></dxi-item>
      <dxi-item
        dataField="totalRequired"
        editorType="dxLookup"
        [label]="{
          text: language.f3MeWebApiTargetRequired,
          showColon: false
        }"
        [editorOptions]="{
          dataSource: paginatedFormulas,
          displayExpr: 'displayName',
          valueExpr: 'shortName',
          showClearButton: true
        }"
      ></dxi-item>
      <dxi-item
        dataField="totalSupplied"
        editorType="dxLookup"
        [label]="{
          text: language.f3MeWebApiTargetInvested,
          showColon: false
        }"
        [editorOptions]="{
          dataSource: paginatedFormulas,
          displayExpr: 'displayName',
          valueExpr: 'shortName',
          showClearButton: true
        }"
      ></dxi-item>
    </dxi-item>
    <dxi-item
      dataField="allowSubmit"
      editorType="dxLookup"
      [label]="{ text: language.f3MeWebApiTargetAllowSubmit }"
      [editorOptions]="{
        dataSource: paginatedFormulas,
        displayExpr: 'displayName',
        valueExpr: 'shortName',
        showClearButton: true
      }"
    ></dxi-item>
    <dxi-item dataField="sortOrder" editorType="dxNumberBox" [editorOptions]="{ showSpinButtons: 'true' }" [label]="{ text: language.SortOrder }"></dxi-item>
  </dxi-item>
</dx-form>
