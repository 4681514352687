import { Component, Input, OnInit } from "@angular/core";

import { ReplaySubject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { SimulationInput } from "../_models/simulation-input.model";

@Component({
  selector: "app-simulate-slider-input",
  templateUrl: "./simulate-input-slider.component.html",
  styleUrls: ["./simulate-input-slider.component.scss"],
})
export class DXInputSliderComponent implements OnInit {
  @Input() public input: SimulationInput;

  private _newValue: ReplaySubject<number>;

  public constructor() {}

  public get upperBound() {
    if (this.input) {
      if (this.input.valueAsNumber > this.input.upperBound) {
        return this.input.valueAsNumber;
      } else if (this.input.lowerBound > this.input.upperBound) {
        return this.input.lowerBound;
      } else {
        return this.input.upperBound;
      }
    }
    return 0;
  }

  public get lowerBound() {
    if (this.input) {
      if (this.input.valueAsNumber < this.input.lowerBound) {
        return this.input.valueAsNumber;
      } else if (this.input.upperBound < this.input.lowerBound) {
        return this.input.upperBound;
      } else {
        return this.input.lowerBound;
      }
    }
    return 0;
  }

  public get stepSize() {
    if (this.input) {
      if (this.lowerBound === this.upperBound) {
        return 0;
      } else {
        return this.input.stepSize;
      }
    }
    return 0;
  }

  public ngOnInit(): void {
    this._newValue = new ReplaySubject<number>(1);

    this._newValue.pipe(debounceTime(500)).subscribe((val) => {
      if (this.input) {
        this.input.valueAsNumber = val;
      }
    });
  }

  public valueChanged(newValue: number): void {
    if (this.input) {
      if (newValue !== undefined && isNaN(newValue) === false) {
        this._newValue.next(newValue);
      }
    }
  }
}
