import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { PlUserStorage } from "../../../angular-common/baseservice/pluserstorage";
import { CmsFeaturesModel } from "../../../employee-common/cms/cms-features-model";
import { CmsLanguage } from "../../../employee-common/cms/language/cmslanguage.service";
import { ImageService } from "../../../employee-common/components/images/image.service";
import { MenuTypeProvider } from "../../../employee-common/components/menus/menu-type-provider";
import { CmsRoutesDto } from "../../../employee-common/models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../../../employee-common/models/cms/dto/MenuItemTypes-dto";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "body",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  private menuTypeSubscription: Subscription = null;
  private urlBackgroundImage: string = "";

  @HostBinding("style.background-image") public backgroundImage = "";

  constructor(private menuTypeProvider: MenuTypeProvider, public imageService: ImageService, public cmsLanguage: CmsLanguage, private router: Router, private featureSettings: CmsFeaturesModel) {
    document.title = cmsLanguage.applicationTitle;
  }

  public get isLoginPage(): boolean {
    return this.menuTypeProvider.currentMenuItemType === MenuItemTypesDto.Login;
  }

  public get hasUser(): boolean {
    if (PlUserStorage.user) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.urlBackgroundImage = this.imageService.urlLogin;
    this.subscribeToMenuTypeChanges();
  }

  ngOnDestroy() {
    if (this.menuTypeSubscription) {
      this.menuTypeSubscription.unsubscribe();
    }
  }

  public searchEnabled() {
    if (this.featureSettings.searchEnabled()) {
      return true;
    }
    return false;
  }

  public search(value: string): void {
    this.router.navigate([CmsRoutesDto.Search, { query: value || "" }]);
  }

  private subscribeToMenuTypeChanges() {
    this.menuTypeSubscription = this.menuTypeProvider.menuItemType.subscribe((type) => {
      this.setBackGroundMatchingCurrentLocation(type);
      this.setBrowserTabTitle(type);
    });
  }

  private setBackGroundMatchingCurrentLocation(menuType: MenuItemTypesDto) {
    let img = "";

    if (menuType === MenuItemTypesDto.Login) {
      if (this.urlBackgroundImage !== "") {
        img = "url(" + this.urlBackgroundImage + ")";
      }
    }

    this.backgroundImage = img;
  }

  public currentMenuType() {
    return this.menuTypeProvider.currentMenuItemType;
  }

  private setBrowserTabTitle(menuType: MenuItemTypesDto) {
    let title = this.cmsLanguage.applicationTitle;

    if (menuType === MenuItemTypesDto.Management) {
      title = `${title} - ${this.cmsLanguage.f3MeWebApiMenuManagementHeader}`;
    }

    if (menuType === MenuItemTypesDto.Technical) {
      title = `${title} - ${this.cmsLanguage.ApiMenuTechnicalManagement}`;
    }

    if (menuType === MenuItemTypesDto.CMS) {
      title = `${title} - ${this.cmsLanguage.f3MeWebApiAppManagementHeader}`;
    }

    document.title = title;
  }
}
