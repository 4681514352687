import { Component, Input } from "@angular/core";

import { InspireLanguageService } from "../shared/language/inspire-language.service";
import { LanguageItem } from "../../../../angular-common/language/language-dto.model";
import { LanguageMetaDataDto } from "../../../../common/models/dto/LanguageMetaData-dto";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";

@Component({
  selector: "app-target-title",
  templateUrl: "cms-target-title.component.html",
  styleUrls: ["cms-target-title.component.scss"],
})
export class TargetLanguageItemEditComponent {
  constructor(public languageSelection: PlLanguageSelection, public language: InspireLanguageService) {}

  @Input() target: LanguageItem;

  customizeLabel(data: LanguageMetaDataDto) {
    return data.Id;
  }
}
