
// t4tsCodeFromCs": "0.8.2.0 for file ManagementRoutes.cs
// Don't adjust manually!

export class ManagementRoutesDto {
    public static ManagementSettings: string = "managementsettings";
    public static VariantSelection: string = "variantmodelconfiguration";
    public static SmartEbs: string = "SmartEbs";
    public static SimulationLanguageEdit: string = "simulationlanguageedit";
}

