import { CommonModule } from "@angular/common";
import { DxSelectBoxModule } from "devextreme-angular";
import { ExogenousVariablesEditService } from "./service/exogenous-variables-edit.service";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TargetEditModule } from "../shared/target-edit/targetedit.module";
import { TargetFieldsEditComponent } from "./target-fields-edit.component";
import { TriggersEditModule } from "../../../../angular-common";

@NgModule({
  declarations: [TargetFieldsEditComponent],
  imports: [CommonModule, FormsModule, HttpClientModule, TriggersEditModule, DxSelectBoxModule, TargetEditModule],
  providers: [ExogenousVariablesEditService],
  exports: [TargetFieldsEditComponent],
})
export class TargetFieldsEditModule {}
