import { VariantDto } from './../../../../common/models/dto/VariantDTO-dto';

// t4tsCode gen": "0.8.2.0 for file VariantModelConfigurationDto.cs
// Don't adjust manually!

export class VariantModelConfigurationDto {
    public Component: string;
    public Variant: VariantDto;
}

