import { ValueToText } from "./smartebs-value-to-text-converter";

export class SmartEbsItem {
  constructor(id: number, shortName: string, longName: string, value: number) {
    this.keyId = id;
    this.shortName = shortName;
    this.longName = longName;

    if (value === undefined) {
      this.value = Math.floor(Math.random() * 1000);
    } else {
      this.value = value;
    }
  }
  public keyId: number;
  public shortName: string;
  public longName: string;
  public selectedCatagory: number = 0;
  public value: number = 0;

  public get valueAsText() {
    return ValueToText.from(this.value);
  }
}
