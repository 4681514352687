import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SafeDataDirectiveModule } from "../../../../../angular-common/utils/safe-data-directive-module";
import { InspireCmsLinkModule } from "../../shared/links/inspire-cms-link.module";
import { SearchResultComponent } from "./search-result.component";

@NgModule({
  imports: [CommonModule, InspireCmsLinkModule, SafeDataDirectiveModule],
  declarations: [SearchResultComponent],
  exports: [SearchResultComponent],
})
export class SearchResultModule {}
