import { ModelElementDto } from './../../../../../common/models/dto/ModelElementDto-dto';

// t4tsCode gen": "0.8.2.0 for file SimulationModelElements.cs
// Don't adjust manually!

export class SimulationModelElementsDto {
    public Inputs: ModelElementDto [] = [];
    public Outputs: ModelElementDto [] = [];
}

