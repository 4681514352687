import { ExogenousInputOptionDto } from './ExogenousInputOption-dto';

// t4tsCode gen": "0.8.2.0 for file ExogenousInputNumericOption.cs
// Don't adjust manually!

export class ExogenousInputNumericOptionDto extends ExogenousInputOptionDto {
    public Value: string;
    public ValueAsNumber: number;
    public StepSize: number;
    public Lowerbound: number;
    public Upperbound: number;
    public Unit: string;
    public static TypeName: string = "number";
}

