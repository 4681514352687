import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TargetTranslationsChoicesService } from "./target-translations-choices.service";
import { TargetTranslationsService } from "./target-translations.service";

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  exports: [],
  providers: [TargetTranslationsService, TargetTranslationsChoicesService],
})
export class TargetTranslationsServiceModule {}
