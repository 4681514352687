import { Component, OnInit } from "@angular/core";

import { TargetSettings } from "../../../../employee-common/choices/targets/targetsettings";
import { TargetEditService } from "../shared/target-edit/targetedit.service";

@Component({
  selector: "app-arrange-labourconditions-with-choices",
  templateUrl: "./cms-labourcondtion-link-withchoices-edit.component.html",
})
export class CmsLabourConditionLinkEditWithChoicesComponent implements OnInit {
  public targets: TargetSettings[] = [];

  constructor(private targetService: TargetEditService) {
    this.targets = [];
  }

  ngOnInit() {
    return this.targetService.retrieveTargetsGrouping().subscribe((r) => {
      this.targets = r.targetSettings;
    });
  }
}
