<div>
  <app-target-selector (selectedTargetHasChanged)="setTarget($event)"></app-target-selector>
  <div *ngIf="hasTarget && hasTargetOptions">
    <div class="panel-with-header-and-content">
      <div class="panel-content">
        <dx-data-grid
          [dataSource]="targetOptions.TargetOptions"
          redrawOnResize="true"
          [rowAlternationEnabled]="true"
          [showColumnHeaders]="true"
          height="100%"
          [allowColumnResizing]="true"
          (onEditingStart)="onEditingStart($event.data)"
          (onRowUpdated)="onRowUpdated($event.data, $event.component)"
        >
          <dxo-scrolling mode="virtual"></dxo-scrolling>
          <dxo-editing [allowUpdating]="true" [useIcons]="true" mode="row" [allowDeleting]="false"> </dxo-editing>
          <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

          <dxi-column [allowEditing]="false" dataField="ExogenousVariable.LongName" dataType="string" [caption]="language.modelName"> </dxi-column>
          <dxi-column
            [allowEditing]="false"
            dataField="ExogenousVariable.CategoryId"
            dataType="number"
            [groupIndex]="0"
            [calculateDisplayValue]="getCategoryDisplayValue"
            [caption]="language.edittriggerCategory"
          >
          </dxi-column>

          <dxi-column dataField="Name" [caption]="language.modelName" editCellTemplate="nameEditCellTemplate" [calculateDisplayValue]="name"></dxi-column>
          <dxi-column dataField="Description" [caption]="language.modelDescription" editCellTemplate="descrEditCellTemplate" [calculateDisplayValue]="description"></dxi-column>
          <dxi-column [caption]="language.f3MeWebApiTranslateTargetChoices" cellTemplate="choicesCellTemplate"></dxi-column>

          <div *dxTemplate="let d of 'choicesCellTemplate'">
            <div *ngIf="hasReferenceCategory(d.data.ExogenousVariable) ">
              <app-target-translations-choices [caption]="language.f3MeWebApiTranslateTargetChoicesEdit" [referenceCategoryId]="d.data.ExogenousVariable.ReferenceCategoryId">
              </app-target-translations-choices>
            </div>
          </div>
          <div *dxTemplate="let d of 'nameEditCellTemplate'">
            <app-languageitem-editor-grid [cellInfo]="d" [item]="d.data.NameCopy"> </app-languageitem-editor-grid>
          </div>
          <div *dxTemplate="let d of 'descrEditCellTemplate'">
            <app-languageitem-editor-grid [cellInfo]="d" [item]="d.data.DescriptionCopy"> </app-languageitem-editor-grid>
          </div>
        </dx-data-grid>
      </div>
    </div>
    <div class="panel-with-header-and-content fixed-categories">
      <div class="panel-content">
        <span class="fixed-cat-header"> {{language.f3MeWebApiTranslateTargetFixedCategories}} </span>
        <app-target-translations-choices [caption]="language.Errors" [referenceCategoryId]="targetOptions.ErrorReferenceCategoryId"></app-target-translations-choices>
        <app-target-translations-choices [caption]="language.Warnings" [referenceCategoryId]="targetOptions.WarningReferenceCategoryId"></app-target-translations-choices>
      </div>
    </div>
  </div>
</div>
