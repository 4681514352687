<div class="widget-container flex-box">
  <div id="dropzone-external" class="flex-box" [ngClass]="isDropZoneActive ? ['drop-zone-border', 'dropzone-active'] : ['dx-theme-border-color']">
    <div id="dropzone-text" class="flex-box">
      <div *ngIf="files.length == 0">{{ this.language.f3MeWebApiSmartEbsDragBox }}</div>
      <div *ngIf="files.length > 0">
        <div *ngFor="let file of files"><i class="fas fa-check"></i> {{ file }}</div>
      </div>
    </div>
  </div>
  <dx-file-uploader
    #fileUploader
    dialogTrigger="#dropzone-external"
    dropZone="#dropzone-external"
    [multiple]="false"
    uploadMode="instantly"
    [visible]="false"
    (onDropZoneEnter)="onDropZoneEnter($event)"
    (onDropZoneLeave)="onDropZoneLeave($event)"
    (onUploadStarted)="onUploadStarted($event)"
  ></dx-file-uploader>
</div>
