import { Component, OnInit, ViewChild } from "@angular/core";

import { DxDataGridComponent } from "devextreme-angular";
import { ColumnItemDto } from "../../../../common/models/dto/ColumnItem-dto";
import { DataSetWellKnownVariablesDto } from "../../../../common/models/dto/DataSetWellKnownVariables-dto";
import { ChoicesLanguage } from "../../../../employee-common/choices/language/choiceslanguage.service";
import { CmsLanguage } from "../../../../employee-common/cms/language/cmslanguage.service";
import { EmployeeGridConfiguration } from "../../../../employee-common/components/employee-grid/employee-grid-configuration";
import { EmployeeGridDateFilter } from "../../../../employee-common/components/employee-grid/employee-grid-date-filter";
import { FilesRouteNavigation } from "../files/files-route-navigation";
import { InspireLanguageService } from "../shared/language/inspire-language.service";
import { ClientChangedDataList } from "./_model/client-changed-data-list";
import { DataRow } from "./_model/data-row";
import { DataRows } from "./_model/data-rows";
import { ApproveTargetsService } from "./approve-targets.service";

@Component({
  selector: "app-approve-targets-component",
  templateUrl: "./approve-targets.component.html",
  styleUrls: ["./approve-targets.component.scss"],
  providers: [],
})
export class ApproveTargetsComponent implements OnInit {
  public constructor(
    public cmsLanguage: CmsLanguage,
    public choicesLanguage: ChoicesLanguage,
    public inspireLanguage: InspireLanguageService,
    private approveTargetsService: ApproveTargetsService,
    private filesRouteNavigation: FilesRouteNavigation,
  ) {
    this.dateFilter = new EmployeeGridDateFilter();
    this.gridConfiguration = new EmployeeGridConfiguration(this.cmsLanguage, this.dateFilter);
    this.manualChanges = new ClientChangedDataList(this.choicesLanguage, this.inspireLanguage, approveTargetsService);
  }

  // In version 17.2 we need to set a filter through code so we need access to the grid. In version 18+ we can use the filterValue property.
  @ViewChild(DxDataGridComponent)
  public get dataGrid(): DxDataGridComponent {
    return this._dataGrid;
  }
  public set dataGrid(newGrid: DxDataGridComponent) {
    this._dataGrid = newGrid;
    this.gridConfiguration.dxGrid = this._dataGrid;
  }
  private _dataGrid: DxDataGridComponent;

  public ngOnInit(): void {
    this.refresh(true);
  }

  public get hasStartDate() {
    return this.columns.find((c) => c.dataField.toUpperCase() == DataSetWellKnownVariablesDto.StartDateColumnName.toUpperCase()) !== undefined;
  }

  public isActionColumn(data) {
    if (data.column && this.gridConfiguration.isActionColumn(data.column.dataField)) {
      return true;
    }
    return false;
  }

  public goToFiles(data: any) {
    let userId = data.data[this.gridConfiguration.userIdFieldName];
    if (userId) {
      this.filesRouteNavigation.toUserFiles(userId);
    } else {
      console.log("user not found");
    }
  }

  private refresh(reInitializeGrid: boolean) {
    this.approveTargetsService.getEmployeeDataForGrid().subscribe((t) => {
      if (reInitializeGrid) {
        this.gridConfiguration.initialize(t.columns);
        this.columns = t.columns;
      }
      this.data = t.data;
      this.manualChanges.clearChanges();
    });
  }

  // Submits manual changes made by a user, clear the list of changes afterwards
  public submit() {
    this.manualChanges.submit().subscribe((result) => {
      if (result) {
        this.data.clearChanges();
      }
    });
  }

  public get canSubmit(): boolean {
    return this.manualChanges.hasChanges;
  }

  public dateFilter: EmployeeGridDateFilter;
  public gridConfiguration: EmployeeGridConfiguration;
  public data: DataRows;
  public columns: ColumnItemDto[];
  private manualChanges: ClientChangedDataList;

  public rowUpdated(event: any) {
    const row = event.key as DataRow;
    if (row && row !== null) {
      for (const key in event.data) {
        if (key) {
          this.changeRow(row, key, event.data[key]);
        }
      }
    }
  }

  private changeRow(row: DataRow, dataField: string, value: string) {
    if (this.gridConfiguration.isEditableColumn(dataField) === false) {
      return;
    }

    row[dataField] = value;
    row.addToChangedColumns(dataField);
    this.manualChanges.addOrReplace(row.identification, dataField, value);
  }

  // Creates a list of changes for a specified dataField (= column). If a row has a current value a change is recorded
  // for that row with a new value.
  private gridDataToChangeList(dataField: string, currentValueForDataField: string, newValueForDataField: string) {
    return this._dataGrid.instance
      .selectAll()
      .then((s) => {
        const result = new ClientChangedDataList(this.choicesLanguage, this.inspireLanguage, this.approveTargetsService);
        const selectedRows = this._dataGrid.instance.getSelectedRowsData() as DataRow[];
        selectedRows.forEach((row) => {
          if (row[dataField] === currentValueForDataField) {
            result.add(row.identification, dataField, newValueForDataField);
          }
        });
        return result;
      })
      .then((result) => {
        this._dataGrid.instance.deselectAll();
        return result;
      });
  }

  public changeMultiple(event) {
    this.gridDataToChangeList(event.column.dataField, event.from.ID, event.to.ID).then((changeList) => {
      changeList.submitWithConfirm().subscribe((result) => {
        if (result) {
          this.refresh(false);
        }
      });
    });
  }
}
