import { DxButtonModule, DxDataGridModule, DxDateBoxModule, DxPopupModule, DxTemplateModule, DxTooltipModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { EmployeeGridModule } from "../../../../employee-common/components/employee-grid/employee-grid.module";
import { FilesRouteNavigation } from "../files/files-route-navigation";
import { ApproveTargetsComponent } from "./approve-targets.component";
import { ChangeMultipleLookupColumnsComponent } from "./change-multiple-lookup-columns.component";

@NgModule({
  declarations: [ApproveTargetsComponent, ChangeMultipleLookupColumnsComponent],
  imports: [CommonModule, FormsModule, HttpClientModule, DxTooltipModule, DxTemplateModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxPopupModule, EmployeeGridModule],
  providers: [FilesRouteNavigation],
  exports: [ApproveTargetsComponent],
  schemas: [],
})
export class ApproveTargetsModule {}
