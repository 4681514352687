import { Component, Input } from "@angular/core";

import { Router } from "@angular/router";
import { PlUserStorage } from "../../../../../angular-common/baseservice/pluserstorage";
import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";
import { ChoiceDefinition } from "../../../../../employee-common/choices/targets/targetdefinition/target-definition.model";
import { CmsLanguage } from "../../../../../employee-common/cms/language/cmslanguage.service";
import { Link } from "../../../../../employee-common/components/links/link";
import { LinkHandler } from "../../../../../employee-common/components/links/linkhandler";
import { ChoicesRoutesDto } from "../../../../../employee-common/models/choices/dto/ChoicesRoutes-dto";

@Component({
  selector: "app-myorders-topic",
  templateUrl: "./myorders-overview-topic.html",
  styleUrls: ["./myorders-overview-topic.scss"],
})
export class MyOrdersTopicComponent {
  public linkhandler: LinkHandler;
  public filesLink: Link;

  constructor(private router: Router, public languageService: CmsLanguage, private plLanguageSelection: PlLanguageSelection) {
    this.linkhandler = new LinkHandler(this.router);
    this.filesLink = Link.createDefaultInternalLink(this.plLanguageSelection, languageService.f3MeWebApiMyFiles);
    this.filesLink.location = `${ChoicesRoutesDto.UserFiles}/${PlUserStorage.user.Username}`;
  }

  public goToTargetDefinition(td: ChoiceDefinition) {
    this.linkhandler.go(td.link);
  }

  @Input()
  public get targets(): ChoiceDefinition[] {
    return this._targets;
  }

  public get approvedTargets(): ChoiceDefinition[] {
    return this._targets.filter((x) => x.IsApproved == true);
  }

  public get waitingTargets(): ChoiceDefinition[] {
    return this._targets.filter((x) => x.isWaiting == true);
  }

  public set targets(targetsDefinition: ChoiceDefinition[]) {
    this._targets = targetsDefinition;
  }

  private _targets: ChoiceDefinition[];

  public get isOddIndex(): boolean {
    return Math.abs(this.targets.length % 2) === 1;
  }
}
