
// t4tsCode gen": "0.8.2.0 for file ChoicesRoutes.cs
// Don't adjust manually!

export class ChoicesRoutesDto {
    public static SourcesEdit: string = "source";
    public static MyOrders: string = "myorders";
    public static UserFiles: string = "userfiles";
    public static Approve: string = "approve";
    public static TargetEdit: string = "targetedit";
    public static TargetFieldsEdit: string = "targetfieldsedit";
    public static TargetTranslations: string = "targettranslations";
}

