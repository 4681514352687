import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxDataGridModule, DxSelectBoxModule, DxTemplateModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { canActivateAuthGuard } from "../../../../angular-common/authentication-guard/authentication-guard";
import { CMSModule } from "../../../../employee-common/cms/cms.module";
import { CmsPageHeaderModule } from "../../../../employee-common/cms/cmspageheaders/cmspage-header.module";
import { ChoicesRoutesDto } from "../../../../employee-common/models/choices/dto/ChoicesRoutes-dto";
import { MenuItemTypesDto } from "../../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { FileUserScreenComponent } from "./files-route/files-user-screen.component";
import { FilesComponent } from "./files.component";
import { FileService } from "./files.service";

const routes: Routes = [
  {
    canActivate: [canActivateAuthGuard],
    path: ChoicesRoutesDto.UserFiles,
    component: FileUserScreenComponent,
    data: [
      {
        menuType: MenuItemTypesDto.Unknown,
        inheritRolesFrom: ChoicesRoutesDto.UserFiles,
      },
    ],
  },
  {
    canActivate: [canActivateAuthGuard],
    path: ChoicesRoutesDto.UserFiles + "/:userId",
    component: FileUserScreenComponent,
    data: [
      {
        menuType: MenuItemTypesDto.Unknown,
        inheritRolesFrom: ChoicesRoutesDto.UserFiles,
      },
    ],
  },
];

@NgModule({
  declarations: [FilesComponent, FileUserScreenComponent],
  imports: [RouterModule.forChild(routes), FormsModule, CMSModule, HttpClientModule, CmsPageHeaderModule, DxDataGridModule, DxTemplateModule, DxSelectBoxModule, DxButtonModule, CommonModule],
  providers: [FileService],
  exports: [FileUserScreenComponent],
})
export class FilesModule {}
