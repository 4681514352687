import { ExogenousVariableDto } from './../../../../common/models/dto/ExogenousVariableDto-dto';

export declare type TargetOptionTranslationNameDto = [string, string];
export declare type TargetOptionTranslationDescriptionDto = [string, string];
// t4tsCode gen": "0.8.2.0 for file TargetOptionTranslation.cs
// Don't adjust manually!

export class TargetOptionTranslationDto {
    public ExogenousVariable: ExogenousVariableDto;
    public Name: TargetOptionTranslationNameDto;
    public Description: TargetOptionTranslationDescriptionDto;
}

