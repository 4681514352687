import { Injectable } from "@angular/core";
import { LanguageServiceBase } from "../../components/language/language-base.service";
import { PersonlizedLanguageService } from "../../components/language/personlized-language.service";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageService } from "../../../angular-common";

@Injectable()
//t4Languages": "0.8.2.0
export class ChoicesLanguage extends LanguageServiceBase {
  public constructor(plLanguageService: PlLanguageService, languageSelection: PlLanguageSelection, personlizedLanguageService: PersonlizedLanguageService) {
    super(plLanguageService, languageSelection, personlizedLanguageService);
  }

  /**
   * f3MeWebApiApproveTargetsHeader from Choices.csv (in EN: Checking and modifying of orders )
   */
  public key_f3MeWebApiApproveTargetsHeader = "f3MeWebApiApproveTargetsHeader";
  /**
   * f3MeWebApiApproveTargetsHeader from Choices.csv (in EN: Checking and modifying of orders )
   */
  public get f3MeWebApiApproveTargetsHeader(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsHeader, "Checking and modifying of orders"); }

  /**
   * f3MeWebApiApproveTargetsConfirmHeader from Choices.csv (in EN: * = a modified value. Click Save to make the change permanent. You can also change multiple orders simultaneously: )
   */
  public key_f3MeWebApiApproveTargetsConfirmHeader = "f3MeWebApiApproveTargetsConfirmHeader";
  /**
   * f3MeWebApiApproveTargetsConfirmHeader from Choices.csv (in EN: * = a modified value. Click Save to make the change permanent. You can also change multiple orders simultaneously: )
   */
  public get f3MeWebApiApproveTargetsConfirmHeader(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsConfirmHeader, "* = a modified value. Click Save to make the change permanent. You can also change multiple orders simultaneously:"); }

  /**
   * f3MeWebApiApproveTargetsConfirm from Choices.csv (in EN: Save )
   */
  public key_f3MeWebApiApproveTargetsConfirm = "f3MeWebApiApproveTargetsConfirm";
  /**
   * f3MeWebApiApproveTargetsConfirm from Choices.csv (in EN: Save )
   */
  public get f3MeWebApiApproveTargetsConfirm(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsConfirm, "Save"); }

  /**
   * f3MeWebApiApproveTargetsInvalidDateFilter from Choices.csv (in EN: Invalid dates )
   */
  public key_f3MeWebApiApproveTargetsInvalidDateFilter = "f3MeWebApiApproveTargetsInvalidDateFilter";
  /**
   * f3MeWebApiApproveTargetsInvalidDateFilter from Choices.csv (in EN: Invalid dates )
   */
  public get f3MeWebApiApproveTargetsInvalidDateFilter(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsInvalidDateFilter, "Invalid dates"); }

  /**
   * f3MeWebApiApproveTargetsApplyFilter from Choices.csv (in EN: Apply )
   */
  public key_f3MeWebApiApproveTargetsApplyFilter = "f3MeWebApiApproveTargetsApplyFilter";
  /**
   * f3MeWebApiApproveTargetsApplyFilter from Choices.csv (in EN: Apply )
   */
  public get f3MeWebApiApproveTargetsApplyFilter(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsApplyFilter, "Apply"); }

  /**
   * f3MeWebApiApproveTargetsResetFilter from Choices.csv (in EN: Reset )
   */
  public key_f3MeWebApiApproveTargetsResetFilter = "f3MeWebApiApproveTargetsResetFilter";
  /**
   * f3MeWebApiApproveTargetsResetFilter from Choices.csv (in EN: Reset )
   */
  public get f3MeWebApiApproveTargetsResetFilter(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsResetFilter, "Reset"); }

  /**
   * f3MeWebApiApproveTargetsChangeMultipleButton from Choices.csv (in EN: Change multiple... )
   */
  public key_f3MeWebApiApproveTargetsChangeMultipleButton = "f3MeWebApiApproveTargetsChangeMultipleButton";
  /**
   * f3MeWebApiApproveTargetsChangeMultipleButton from Choices.csv (in EN: Change multiple... )
   */
  public get f3MeWebApiApproveTargetsChangeMultipleButton(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsChangeMultipleButton, "Change multiple..."); }

  /**
   * f3MeWebApiApproveTargetsChangeMultipleTitle from Choices.csv (in EN: Change orders )
   */
  public key_f3MeWebApiApproveTargetsChangeMultipleTitle = "f3MeWebApiApproveTargetsChangeMultipleTitle";
  /**
   * f3MeWebApiApproveTargetsChangeMultipleTitle from Choices.csv (in EN: Change orders )
   */
  public get f3MeWebApiApproveTargetsChangeMultipleTitle(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsChangeMultipleTitle, "Change orders"); }

  /**
   * f3MeWebApiApproveTargetsChangeMultipleColumn from Choices.csv (in EN: Select target to change: )
   */
  public key_f3MeWebApiApproveTargetsChangeMultipleColumn = "f3MeWebApiApproveTargetsChangeMultipleColumn";
  /**
   * f3MeWebApiApproveTargetsChangeMultipleColumn from Choices.csv (in EN: Select target to change: )
   */
  public get f3MeWebApiApproveTargetsChangeMultipleColumn(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsChangeMultipleColumn, "Select target to change:"); }

  /**
   * f3MeWebApiApproveTargetsChangeMultipleFrom from Choices.csv (in EN: Change status from: )
   */
  public key_f3MeWebApiApproveTargetsChangeMultipleFrom = "f3MeWebApiApproveTargetsChangeMultipleFrom";
  /**
   * f3MeWebApiApproveTargetsChangeMultipleFrom from Choices.csv (in EN: Change status from: )
   */
  public get f3MeWebApiApproveTargetsChangeMultipleFrom(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsChangeMultipleFrom, "Change status from:"); }

  /**
   * f3MeWebApiApproveTargetsChangeMultipleTo from Choices.csv (in EN: To: )
   */
  public key_f3MeWebApiApproveTargetsChangeMultipleTo = "f3MeWebApiApproveTargetsChangeMultipleTo";
  /**
   * f3MeWebApiApproveTargetsChangeMultipleTo from Choices.csv (in EN: To: )
   */
  public get f3MeWebApiApproveTargetsChangeMultipleTo(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsChangeMultipleTo, "To:"); }

  /**
   * f3MeWebApiApproveTargetsChangeMultipleExtraInfo from Choices.csv (in EN: Note that only visible rows will be changed. )
   */
  public key_f3MeWebApiApproveTargetsChangeMultipleExtraInfo = "f3MeWebApiApproveTargetsChangeMultipleExtraInfo";
  /**
   * f3MeWebApiApproveTargetsChangeMultipleExtraInfo from Choices.csv (in EN: Note that only visible rows will be changed. )
   */
  public get f3MeWebApiApproveTargetsChangeMultipleExtraInfo(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsChangeMultipleExtraInfo, "Note that only visible rows will be changed."); }

  /**
   * f3MeWebApiApproveTargetsChangeMultipleExistingChanges from Choices.csv (in EN: You have made changes manually, if you continue these will be lost. Continue? )
   */
  public key_f3MeWebApiApproveTargetsChangeMultipleExistingChanges = "f3MeWebApiApproveTargetsChangeMultipleExistingChanges";
  /**
   * f3MeWebApiApproveTargetsChangeMultipleExistingChanges from Choices.csv (in EN: You have made changes manually, if you continue these will be lost. Continue? )
   */
  public get f3MeWebApiApproveTargetsChangeMultipleExistingChanges(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsChangeMultipleExistingChanges, "You have made changes manually, if you continue these will be lost. Continue?"); }

  /**
   * f3MeWebApiApproveTargetsChangeMultipleNothingToChange from Choices.csv (in EN: No orders were found that meet the selected criteria. )
   */
  public key_f3MeWebApiApproveTargetsChangeMultipleNothingToChange = "f3MeWebApiApproveTargetsChangeMultipleNothingToChange";
  /**
   * f3MeWebApiApproveTargetsChangeMultipleNothingToChange from Choices.csv (in EN: No orders were found that meet the selected criteria. )
   */
  public get f3MeWebApiApproveTargetsChangeMultipleNothingToChange(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsChangeMultipleNothingToChange, "No orders were found that meet the selected criteria."); }

  /**
   * f3MeWebApiApproveTargetsChangeMultipleConfirm from Choices.csv (in EN: Save changes for {0} orders? )
   */
  public key_f3MeWebApiApproveTargetsChangeMultipleConfirm = "f3MeWebApiApproveTargetsChangeMultipleConfirm";
  /**
   * f3MeWebApiApproveTargetsChangeMultipleConfirm from Choices.csv (in EN: Save changes for {0} orders? )
   */
  public f3MeWebApiApproveTargetsChangeMultipleConfirm(val1): string { return this.getStringDef("f3MeWebApiApproveTargetsChangeMultipleConfirm", "Save changes for {0} orders?").replace("{0}", val1); }

  /**
   * f3MeWebApiNoTargets from Choices.csv (in EN: No targets available. )
   */
  public key_f3MeWebApiNoTargets = "f3MeWebApiNoTargets";
  /**
   * f3MeWebApiNoTargets from Choices.csv (in EN: No targets available. )
   */
  public get f3MeWebApiNoTargets(): string { return this.getStringDef(this.key_f3MeWebApiNoTargets, "No targets available."); }

  /**
   * f3MeWebApiSelectTarget from Choices.csv (in EN: Select target: )
   */
  public key_f3MeWebApiSelectTarget = "f3MeWebApiSelectTarget";
  /**
   * f3MeWebApiSelectTarget from Choices.csv (in EN: Select target: )
   */
  public get f3MeWebApiSelectTarget(): string { return this.getStringDef(this.key_f3MeWebApiSelectTarget, "Select target:"); }

  /**
   * f3MeWebApiTranslateTargetOriginalDescription from Choices.csv (in EN: Original description )
   */
  public key_f3MeWebApiTranslateTargetOriginalDescription = "f3MeWebApiTranslateTargetOriginalDescription";
  /**
   * f3MeWebApiTranslateTargetOriginalDescription from Choices.csv (in EN: Original description )
   */
  public get f3MeWebApiTranslateTargetOriginalDescription(): string { return this.getStringDef(this.key_f3MeWebApiTranslateTargetOriginalDescription, "Original description"); }

  /**
   * f3MeWebApiTranslateTargetChoices from Choices.csv (in EN: Choices )
   */
  public key_f3MeWebApiTranslateTargetChoices = "f3MeWebApiTranslateTargetChoices";
  /**
   * f3MeWebApiTranslateTargetChoices from Choices.csv (in EN: Choices )
   */
  public get f3MeWebApiTranslateTargetChoices(): string { return this.getStringDef(this.key_f3MeWebApiTranslateTargetChoices, "Choices"); }

  /**
   * f3MeWebApiTranslateTargetChoicesEdit from Choices.csv (in EN: Choices edit )
   */
  public key_f3MeWebApiTranslateTargetChoicesEdit = "f3MeWebApiTranslateTargetChoicesEdit";
  /**
   * f3MeWebApiTranslateTargetChoicesEdit from Choices.csv (in EN: Choices edit )
   */
  public get f3MeWebApiTranslateTargetChoicesEdit(): string { return this.getStringDef(this.key_f3MeWebApiTranslateTargetChoicesEdit, "Choices edit"); }

  /**
   * f3MeWebApiTranslateTargetFixedCategories from Choices.csv (in EN: Click here to change errors and warnings: )
   */
  public key_f3MeWebApiTranslateTargetFixedCategories = "f3MeWebApiTranslateTargetFixedCategories";
  /**
   * f3MeWebApiTranslateTargetFixedCategories from Choices.csv (in EN: Click here to change errors and warnings: )
   */
  public get f3MeWebApiTranslateTargetFixedCategories(): string { return this.getStringDef(this.key_f3MeWebApiTranslateTargetFixedCategories, "Click here to change errors and warnings:"); }

  /**
   * f3MeWebApiTargetSelectSourcesEditHeader from Choices.csv (in EN: Target source selection )
   */
  public key_f3MeWebApiTargetSelectSourcesEditHeader = "f3MeWebApiTargetSelectSourcesEditHeader";
  /**
   * f3MeWebApiTargetSelectSourcesEditHeader from Choices.csv (in EN: Target source selection )
   */
  public get f3MeWebApiTargetSelectSourcesEditHeader(): string { return this.getStringDef(this.key_f3MeWebApiTargetSelectSourcesEditHeader, "Target source selection"); }

  /**
   * f3MeWebApiMenuManagementSourcesEdit from Choices.csv (in EN: Sources )
   */
  public key_f3MeWebApiMenuManagementSourcesEdit = "f3MeWebApiMenuManagementSourcesEdit";
  /**
   * f3MeWebApiMenuManagementSourcesEdit from Choices.csv (in EN: Sources )
   */
  public get f3MeWebApiMenuManagementSourcesEdit(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementSourcesEdit, "Sources"); }

  /**
   * statusChangesInTargetStatusTitle from Choices.csv (in EN: status change )
   */
  public key_statusChangesInTargetStatusTitle = "statusChangesInTargetStatusTitle";
  /**
   * statusChangesInTargetStatusTitle from Choices.csv (in EN: status change )
   */
  public get statusChangesInTargetStatusTitle(): string { return this.getStringDef(this.key_statusChangesInTargetStatusTitle, "status change"); }

  /**
   * statusChangesInTargetStatusContentBefore from Choices.csv (in EN: This e-mail is to inform you that the status of one or more of your targets has changed. We request you pay attention to this. )
   */
  public key_statusChangesInTargetStatusContentBefore = "statusChangesInTargetStatusContentBefore";
  /**
   * statusChangesInTargetStatusContentBefore from Choices.csv (in EN: This e-mail is to inform you that the status of one or more of your targets has changed. We request you pay attention to this. )
   */
  public get statusChangesInTargetStatusContentBefore(): string { return this.getStringDef(this.key_statusChangesInTargetStatusContentBefore, "This e-mail is to inform you that the status of one or more of your targets has changed. We request you pay attention to this."); }

  /**
   * statusChangesInTargetStatusTargetName from Choices.csv (in EN: Targetname:{0} )
   */
  public key_statusChangesInTargetStatusTargetName = "statusChangesInTargetStatusTargetName";
  /**
   * statusChangesInTargetStatusTargetName from Choices.csv (in EN: Targetname:{0} )
   */
  public statusChangesInTargetStatusTargetName(val1): string { return this.getStringDef("statusChangesInTargetStatusTargetName", "Targetname:{0}").replace("{0}", val1); }

  /**
   * statusChangesInTargetStatusOldStatus from Choices.csv (in EN: Old status:{0} )
   */
  public key_statusChangesInTargetStatusOldStatus = "statusChangesInTargetStatusOldStatus";
  /**
   * statusChangesInTargetStatusOldStatus from Choices.csv (in EN: Old status:{0} )
   */
  public statusChangesInTargetStatusOldStatus(val1): string { return this.getStringDef("statusChangesInTargetStatusOldStatus", "Old status:{0}").replace("{0}", val1); }

  /**
   * statusChangesInTargetStatusNewStatus from Choices.csv (in EN: New status:{0} )
   */
  public key_statusChangesInTargetStatusNewStatus = "statusChangesInTargetStatusNewStatus";
  /**
   * statusChangesInTargetStatusNewStatus from Choices.csv (in EN: New status:{0} )
   */
  public statusChangesInTargetStatusNewStatus(val1): string { return this.getStringDef("statusChangesInTargetStatusNewStatus", "New status:{0}").replace("{0}", val1); }

  /**
   * statusChangesInTargetStatusStatusDate from Choices.csv (in EN: Status change date:{0} )
   */
  public key_statusChangesInTargetStatusStatusDate = "statusChangesInTargetStatusStatusDate";
  /**
   * statusChangesInTargetStatusStatusDate from Choices.csv (in EN: Status change date:{0} )
   */
  public statusChangesInTargetStatusStatusDate(val1): string { return this.getStringDef("statusChangesInTargetStatusStatusDate", "Status change date:{0}").replace("{0}", val1); }

  /**
   * statusChangesInFieldValue from Choices.csv (in EN: Value of {0} has changed from {1} to {2} )
   */
  public key_statusChangesInFieldValue = "statusChangesInFieldValue";
  /**
   * statusChangesInFieldValue from Choices.csv (in EN: Value of {0} has changed from {1} to {2} )
   */
  public statusChangesInFieldValue(val1, val2, val3): string { return this.getStringDef("statusChangesInFieldValue", "Value of {0} has changed from {1} to {2}").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * f3MeWebApiLinkUserFiles from Choices.csv (in EN: User Files )
   */
  public key_f3MeWebApiLinkUserFiles = "f3MeWebApiLinkUserFiles";
  /**
   * f3MeWebApiLinkUserFiles from Choices.csv (in EN: User Files )
   */
  public get f3MeWebApiLinkUserFiles(): string { return this.getStringDef(this.key_f3MeWebApiLinkUserFiles, "User Files"); }

  /**
   * f3MeWebApiUserFilesIntro from Choices.csv (in EN: In this page you have an overview of all files of the user )
   */
  public key_f3MeWebApiUserFilesIntro = "f3MeWebApiUserFilesIntro";
  /**
   * f3MeWebApiUserFilesIntro from Choices.csv (in EN: In this page you have an overview of all files of the user )
   */
  public get f3MeWebApiUserFilesIntro(): string { return this.getStringDef(this.key_f3MeWebApiUserFilesIntro, "In this page you have an overview of all files of the user"); }

  /**
   * f3MeWebApiMyOrders from Choices.csv (in EN: My Orders )
   */
  public key_f3MeWebApiMyOrders = "f3MeWebApiMyOrders";
  /**
   * f3MeWebApiMyOrders from Choices.csv (in EN: My Orders )
   */
  public get f3MeWebApiMyOrders(): string { return this.getStringDef(this.key_f3MeWebApiMyOrders, "My Orders"); }

  /**
   * managementSaveNone from Choices.csv (in EN: No changes )
   */
  public key_managementSaveNone = "managementSaveNone";
  /**
   * managementSaveNone from Choices.csv (in EN: No changes )
   */
  public get managementSaveNone(): string { return this.getStringDef(this.key_managementSaveNone, "No changes"); }

  /**
   * managementSaveChangesSucces from Choices.csv (in EN: Data saved for selected users ({0}). )
   */
  public key_managementSaveChangesSucces = "managementSaveChangesSucces";
  /**
   * managementSaveChangesSucces from Choices.csv (in EN: Data saved for selected users ({0}). )
   */
  public managementSaveChangesSucces(val1): string { return this.getStringDef("managementSaveChangesSucces", "Data saved for selected users ({0}).").replace("{0}", val1); }

  /**
   * managementSaveChangesSuccesAndNotified from Choices.csv (in EN: {0} people have been sent an email. )
   */
  public key_managementSaveChangesSuccesAndNotified = "managementSaveChangesSuccesAndNotified";
  /**
   * managementSaveChangesSuccesAndNotified from Choices.csv (in EN: {0} people have been sent an email. )
   */
  public managementSaveChangesSuccesAndNotified(val1): string { return this.getStringDef("managementSaveChangesSuccesAndNotified", "{0} people have been sent an email.").replace("{0}", val1); }

  /**
   * managementSaveChangesFailedPartial from Choices.csv (in EN: Data saved for {0} out of {1} users. Problem occured while processing {2}, the rest has not been processed yet. )
   */
  public key_managementSaveChangesFailedPartial = "managementSaveChangesFailedPartial";
  /**
   * managementSaveChangesFailedPartial from Choices.csv (in EN: Data saved for {0} out of {1} users. Problem occured while processing {2}, the rest has not been processed yet. )
   */
  public managementSaveChangesFailedPartial(val1, val2, val3): string { return this.getStringDef("managementSaveChangesFailedPartial", "Data saved for {0} out of {1} users. Problem occured while processing {2}, the rest has not been processed yet.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * managementNoEmailForEmployee from Choices.csv (in EN: For user {0} no mail adress is configured. )
   */
  public key_managementNoEmailForEmployee = "managementNoEmailForEmployee";
  /**
   * managementNoEmailForEmployee from Choices.csv (in EN: For user {0} no mail adress is configured. )
   */
  public managementNoEmailForEmployee(val1): string { return this.getStringDef("managementNoEmailForEmployee", "For user {0} no mail adress is configured.").replace("{0}", val1); }

  /**
   * statusRequestForApprovalMailTitle from Choices.csv (in EN: Request approval )
   */
  public key_statusRequestForApprovalMailTitle = "statusRequestForApprovalMailTitle";
  /**
   * statusRequestForApprovalMailTitle from Choices.csv (in EN: Request approval )
   */
  public get statusRequestForApprovalMailTitle(): string { return this.getStringDef(this.key_statusRequestForApprovalMailTitle, "Request approval"); }

  /**
   * statusRequestForApprovalMailContent from Choices.csv (in EN: Dear Sir / Madam,We have received your requests. Your applications are also attached to this e-mail. If - after verification - it appears that your application needs to be modified, you will be informed of this.Your requests will be processed as soon as possible. The effectuation of your choices will be visible on your payslip. Depending on the choosen sources this will be in a certain month.We trust to have informed you sufficiently. )
   */
  public key_statusRequestForApprovalMailContent = "statusRequestForApprovalMailContent";
  /**
   * statusRequestForApprovalMailContent from Choices.csv (in EN: Dear Sir / Madam,We have received your requests. Your applications are also attached to this e-mail. If - after verification - it appears that your application needs to be modified, you will be informed of this.Your requests will be processed as soon as possible. The effectuation of your choices will be visible on your payslip. Depending on the choosen sources this will be in a certain month.We trust to have informed you sufficiently. )
   */
  public get statusRequestForApprovalMailContent(): string { return this.getStringDef(this.key_statusRequestForApprovalMailContent, "Dear Sir / Madam,We have received your requests. Your applications are also attached to this e-mail. If - after verification - it appears that your application needs to be modified, you will be informed of this.Your requests will be processed as soon as possible. The effectuation of your choices will be visible on your payslip. Depending on the choosen sources this will be in a certain month.We trust to have informed you sufficiently."); }

  /**
   * statusRequestForApprovalNoTargetChanges from Choices.csv (in EN: Your request has not been sent, there where no changes in the targets. )
   */
  public key_statusRequestForApprovalNoTargetChanges = "statusRequestForApprovalNoTargetChanges";
  /**
   * statusRequestForApprovalNoTargetChanges from Choices.csv (in EN: Your request has not been sent, there where no changes in the targets. )
   */
  public get statusRequestForApprovalNoTargetChanges(): string { return this.getStringDef(this.key_statusRequestForApprovalNoTargetChanges, "Your request has not been sent, there where no changes in the targets."); }

  /**
   * f3MeWebApiMenuManagementTargetTranslations from Choices.csv (in EN: Translate targets )
   */
  public key_f3MeWebApiMenuManagementTargetTranslations = "f3MeWebApiMenuManagementTargetTranslations";
  /**
   * f3MeWebApiMenuManagementTargetTranslations from Choices.csv (in EN: Translate targets )
   */
  public get f3MeWebApiMenuManagementTargetTranslations(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementTargetTranslations, "Translate targets"); }

  /**
   * f3MeWebApiMenuManagementTargetEdit from Choices.csv (in EN: Targets )
   */
  public key_f3MeWebApiMenuManagementTargetEdit = "f3MeWebApiMenuManagementTargetEdit";
  /**
   * f3MeWebApiMenuManagementTargetEdit from Choices.csv (in EN: Targets )
   */
  public get f3MeWebApiMenuManagementTargetEdit(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementTargetEdit, "Targets"); }

  /**
   * f3MeWebApiMenuManagementTargetFieldEdit from Choices.csv (in EN: Triggers )
   */
  public key_f3MeWebApiMenuManagementTargetFieldEdit = "f3MeWebApiMenuManagementTargetFieldEdit";
  /**
   * f3MeWebApiMenuManagementTargetFieldEdit from Choices.csv (in EN: Triggers )
   */
  public get f3MeWebApiMenuManagementTargetFieldEdit(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementTargetFieldEdit, "Triggers"); }

  /**
   * f3MeWebApiMenuTargets from Choices.csv (in EN: Orders )
   */
  public key_f3MeWebApiMenuTargets = "f3MeWebApiMenuTargets";
  /**
   * f3MeWebApiMenuTargets from Choices.csv (in EN: Orders )
   */
  public get f3MeWebApiMenuTargets(): string { return this.getStringDef(this.key_f3MeWebApiMenuTargets, "Orders"); }

  /**
   * f3MeWebApiMenuTargetsApprove from Choices.csv (in EN: Approve )
   */
  public key_f3MeWebApiMenuTargetsApprove = "f3MeWebApiMenuTargetsApprove";
  /**
   * f3MeWebApiMenuTargetsApprove from Choices.csv (in EN: Approve )
   */
  public get f3MeWebApiMenuTargetsApprove(): string { return this.getStringDef(this.key_f3MeWebApiMenuTargetsApprove, "Approve"); }


}
