import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SourceSpecificationsWithMetaDataDto } from "../../../../../employee-common/models/choices/dto/SourceSpecificationsWithMetaData-dto";
import { SourceSpecification } from "./models/source-specification.model";
import { SourceSpecificationsWithMetaData } from "./models/source-specifications-with-metadata.model";

@Injectable()
export class SourceSpecificationsService extends PlBaseService {
  private endPoint: string = "SourceSpecification";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getAll(): Observable<SourceSpecificationsWithMetaData> {
    const thisService = this;
    return this.getDataTransformed<SourceSpecificationsWithMetaDataDto>(this.endPoint).pipe(map((r) => thisService.transform(r)));
  }

  private transform(dto: SourceSpecificationsWithMetaDataDto): SourceSpecificationsWithMetaData {
    if (dto) {
      const result: SourceSpecificationsWithMetaData = new SourceSpecificationsWithMetaData();
      result.copyFromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  public updateSourceSpecification(originalShortName: string, sourceSpecification: SourceSpecification): Observable<any> {
    const url = this.endPoint + "/" + encodeURI(originalShortName);
    return this.update(sourceSpecification.toDto(), url);
  }

  public createSourceSpecification(sourceSpecification: SourceSpecification): Observable<any> {
    return this.createData(this.endPoint, sourceSpecification.toDto());
  }

  public removeSourceSpecification(sourceSpecification: SourceSpecification): Observable<any> {
    const url = this.endPoint + "/" + encodeURI(sourceSpecification.shortName);
    return this.remove(url);
  }
}
