import { Component, Input } from "@angular/core";

import DxDataGrid from "devextreme/ui/data_grid";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { PlLanguageSelectionCopier } from "../../../../angular-common/language/pl-language-selection-copier";
import { ChoicesLanguage } from "../../../../employee-common/choices/language/choiceslanguage.service";
import { TargetOptionChoiceTranslationDto } from "../../../../employee-common/models/choices/dto/TargetOptionChoiceTranslation-dto";
import { TargetOptionChoicesTranslationDto } from "../../../../employee-common/models/choices/dto/TargetOptionChoicesTranslation-dto";
import { TargetTranslationsChoicesService } from "../shared/targettranslations/target-translations-choices.service";

@Component({
  selector: "app-target-translations-choices",
  templateUrl: "target-translations-choices.component.html",
  styleUrls: ["target-translations-choices.component.scss"],
})
export class TargetTranslationsChoicesComponent {
  constructor(public language: ChoicesLanguage, private targetTranslationsChoicesService: TargetTranslationsChoicesService, private languageSelection: PlLanguageSelection) {
    this.description = this.description.bind(this);
  }

  description(t: TargetOptionChoiceTranslationDto) {
    return t.Description[this.languageSelection.currentLanguage];
  }

  @Input()
  public get referenceCategoryId(): number {
    return this._referenceCategoryId;
  }
  public set referenceCategoryId(newReferenceCategoryId: number) {
    this._referenceCategoryId = newReferenceCategoryId;
  }
  private _referenceCategoryId: number;

  @Input()
  public caption: string;

  public choices: TargetOptionChoicesTranslationDto = new TargetOptionChoicesTranslationDto();
  public popupVisible: boolean = false;

  public showEditPopup() {
    this.targetTranslationsChoicesService.retrieve(this.referenceCategoryId).subscribe((data) => {
      this.popupVisible = true;
      this.choices = new TargetOptionChoicesTranslationDto();
      data.translations.forEach((t) => {
        this.choices.Translations.push(t.copyToDto());
      });
    });
  }

  public closePopup() {
    this.popupVisible = false;
  }

  onEditingStart(option: TargetOptionChoiceTranslationDto) {
    PlLanguageSelectionCopier.copyFromOriginal(option);
  }

  onRowUpdated(option: TargetOptionChoiceTranslationDto, dxGrid: DxDataGrid) {
    PlLanguageSelectionCopier.copyToOriginal(option);
    dxGrid.repaint();
    this.targetTranslationsChoicesService.save(option).subscribe();
  }
}
