import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxDataGridModule, DxPopupModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { canActivateUserAcknowledgementGuard } from "../../../../employee-common/auth/user-acknowledgemen.guard";
import { LanguageItemEditorGridModule } from "../../../../employee-common/cms/languageitem-editor-grid/languageitem-editor-grid.module";
import { ChoicesRoutesDto } from "../../../../employee-common/models/choices/dto/ChoicesRoutes-dto";
import { MenuItemTypesDto } from "../../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { TargetEditModule } from "./../shared/target-edit/targetedit.module";
import { TargetTranslationsServiceModule } from "./../shared/targettranslations/target-translations-service.module";
import { TargetTranslationsChoicesComponent } from "./target-translations-choices.component";
import { TargetTranslationsComponent } from "./target-translations.components";

const routes: Routes = [
  {
    path: ChoicesRoutesDto.TargetTranslations,
    component: TargetTranslationsComponent,
    data: [
      {
        menuType: MenuItemTypesDto.Management,
      },
    ],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, TargetEditModule, TargetTranslationsServiceModule, DxDataGridModule, LanguageItemEditorGridModule, DxPopupModule, DxButtonModule],
  declarations: [TargetTranslationsComponent, TargetTranslationsChoicesComponent],
  exports: [TargetTranslationsComponent],
})
export class TargetTranslationsModule {}
