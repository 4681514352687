import { Component, Input } from "@angular/core";

import { concat } from "rxjs";
import { map } from "rxjs/operators";
import { StringComparer } from "../../../../../angular-common/stringcomparison/stringcomparer";
import { ExogenousVariables } from "../../../../../common/modelelements";
import { TargetSettings } from "../../../../../employee-common/choices/targets/targetsettings";
import { CmsLanguage } from "../../../../../employee-common/cms/language/cmslanguage.service";
import { SourceEntrySpecification } from "../sourceentryspecifications/models/source-entry-specification.model";
import { SourceEntrySpecifications } from "../sourceentryspecifications/models/source-entry-specifications.model";
import { SourceEntrySpecificationsService } from "../sourceentryspecifications/source-entry-specifications.service";

@Component({
  selector: "app-source-entry-specifications-edit",
  templateUrl: "./source-entry-specifications-edit.component.html",
  styleUrls: ["./source-entry-specifications-edit.component.scss"],
})
export class SourceEntrySpecificationsEditComponent {
  public constructor(public language: CmsLanguage, private sourceEntrySpecificationsService: SourceEntrySpecificationsService) {}

  @Input()
  public get sourceShortName(): string {
    return this._sourceShortname;
  }
  public set sourceShortName(newSourceShortName: string) {
    this._sourceShortname = newSourceShortName;
    this.loadSourceEntries();
  }
  private _sourceShortname: string;

  private loadSourceEntries() {
    const retrieveMetaData = this.sourceEntrySpecificationsService.getMetaData().pipe(
      map((metaData) => {
        this.targets = metaData.targets;
        this.exogenousVariables = metaData.exogenousVariables;
        this.exogenousVariables.sortSortOrderThenAlpha();
      }),
    );
    const retrieveEntries = this.sourceEntrySpecificationsService.getSourceEntrySpecifications(this.sourceShortName).pipe(
      map((entries) => {
        this.entries = entries;
      }),
    );
    concat(retrieveMetaData, retrieveEntries).subscribe();
  }

  public targets: TargetSettings[];
  public exogenousVariables: ExogenousVariables;
  public entries: SourceEntrySpecifications;

  public get contentLoaded(): boolean {
    return this.targets && this.targets !== null && this.exogenousVariables && this.exogenousVariables !== null && this.entries && this.entries !== null;
  }

  public addNew() {
    const newEntry = new SourceEntrySpecification();
    newEntry.sourceShortName = this.sourceShortName;
    newEntry.sortOrder = this.entries.retrieveMaxSortOrder() + 1;
    this.entries.add(newEntry);
  }

  public onRowChanged() {
    this.sourceEntrySpecificationsService.updateSourceEntrySpecifications(this.sourceShortName, this.entries).subscribe(() => {
      this.loadSourceEntries();
    });
  }

  get canAdd() {
    if (StringComparer.isNullUndefinedOrEmpty(this.sourceShortName)) {
      return false;
    }
    return true;
  }
}
