import { BehaviorSubject } from "rxjs";
import { ISortOrder } from "../../../../../angular-common";
import { LanguageItem } from "../../../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";

export class SimulationBase implements ISortOrder {
  public constructor(
    private languageSelection: PlLanguageSelection,
    public shortName: string,
    private _title: LanguageItem,
    private _valuePostfix: LanguageItem,
    public sortOrder: number,
    public value: string,
    private _valueAsNumber: number,
  ) {
    this.valueChanged = new BehaviorSubject(this);
  }

  public get title(): string {
    return this.languageSelection.currentValueFor(this._title);
  }
  public get valuePostfix(): string {
    return this.languageSelection.currentValueFor(this._valuePostfix);
  }

  public get valueAsNumber(): number {
    return this._valueAsNumber;
  }
  public set valueAsNumber(newValue: number) {
    this._valueAsNumber = newValue;
    if (this.valueChanged) {
      this.valueChanged.next(this);
    }
  }

  public valueChanged: BehaviorSubject<SimulationBase>;
}
