import { Component, OnInit } from "@angular/core";
import { LongNameShortName } from "../../../../angular-common/longnameshortname/longname-shortname";
import { LongNameOrShortNameMode } from "../../../../angular-common/longnameshortname/longname-shortname-mode.model";
import { WebLanguage } from "../../../../common/language/weblanguage.service";

@Component({
  selector: "app-management-settings",
  templateUrl: "management-settings.components.html",
  styleUrls: ["management-settings.components.scss"],
})
export class ManagementSettingsComponent implements OnInit {
  constructor(public language: WebLanguage) {}

  ngOnInit() {
    this.titles[
      LongNameOrShortNameMode.ShortName
    ] = this.language.modelShortName;
    this.titles[LongNameOrShortNameMode.LongName] = this.language.modelLongName;
    this.titles[
      LongNameOrShortNameMode.LongAndShortName
    ] = this.language.settingsDisplayModelElementsLongShortName;
    this.titles[
      LongNameOrShortNameMode.ShortAndLongName
    ] = this.language.settingsDisplayModelElementsShortLongName;

    this.values.push(LongNameOrShortNameMode.ShortName);
    this.values.push(LongNameOrShortNameMode.LongName);
    this.values.push(LongNameOrShortNameMode.LongAndShortName);
    this.values.push(LongNameOrShortNameMode.ShortAndLongName);
  }

  public titles: any = {};
  public values: LongNameOrShortNameMode[] = [];

  public get value(): LongNameOrShortNameMode {
    return LongNameShortName.RetrieveUserSetting();
  }
  public set value(newValue: LongNameOrShortNameMode) {
    LongNameShortName.StoreUserSetting(newValue);
  }
}
