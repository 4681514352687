import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiResult } from "../../../../angular-common/baseservice/_models/apiresult.model";
import { ApiResultDto } from "../../../../common/models/dto/ApiResult-dto";
import { FieldChangedDataDto } from "../../../../common/models/dto/FieldChangedData-dto";
import { EmployeeDataForGrid } from "../../../../employee-common/components/employee-grid/employee-data-for-grid.model";
import { EmployeeDataForGridDto } from "../../../../employee-common/models/cms/dto/EmployeeDataForGrid-dto";
import { ClientChangedData } from "./_model/client-changed-data";

@Injectable()
export class ApproveTargetsService extends PlBaseService {
  private endPoint: string = "approvetargets";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getEmployeeDataForGrid(): Observable<EmployeeDataForGrid> {
    const thisService = this;
    return this.getDataTransformed<EmployeeDataForGridDto>(this.endPoint).pipe(map((r) => thisService.extractEmployeeDataForGrid(r)));
  }

  private extractEmployeeDataForGrid(dto: EmployeeDataForGridDto): EmployeeDataForGrid {
    if (dto) {
      const result = new EmployeeDataForGrid();
      result.copyFromDTO(dto);
      return result;
    } else {
      return null;
    }
  }

  public postChanges(changes: ClientChangedData[]): Observable<ApiResult> {
    const location = this.endPoint;
    const thisService = this;

    const changesDto = new Array<FieldChangedDataDto>();
    for (const change of changes) {
      changesDto.push(change.toDTO());
    }

    return this.postDataTransformed<ApiResultDto, ApiResult>(location, changesDto, (r) => thisService.extractApiResult(r));
  }
}
