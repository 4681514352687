import { FeaturesInspireDto } from "./dto/info/featuresDto";
import { Injectable } from "@angular/core";

const globalVariableSpace: any = window;
const pl_hr_f3_featuresSettings = (globalVariableSpace || {}).pl_hr_f3_featuresSettings || {};

@Injectable()
export class InspireFeaturesModel {
  public data: FeaturesInspireDto;

  constructor() {
    this.data = pl_hr_f3_featuresSettings || {};
  }
}
