import { IModelWithIdDto } from './../../../../common/models/dto/IModelWithId-dto';

export declare type TargetSettingsDtoDescriptionDto = [string, string];
export declare type TargetSettingsDtoTitleDto = [string, string];
// t4tsCode gen": "0.8.2.0 for file TargetSettingsDto.cs
// Don't adjust manually!

export class TargetSettingsDto implements IModelWithIdDto {
    public KeyId: number;
    public Description: TargetSettingsDtoDescriptionDto;
    public ExoCategoryRefId: number;
    public IsSelected: string;
    public SortOrder: number;
    public ShortName: string;
    public StatusChangeDate: string;
    public Status: string;
    public TotalRemaining: string;
    public TotalRequired: string;
    public TotalSupplied: string;
    public AllowSubmit: string;
    public Title: TargetSettingsDtoTitleDto;
}

