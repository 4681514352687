<div *ngIf="contentLoaded">
  <div class="buttonBar small-margins">
    <label>{{ language.f3MeWebApiEditSourceEntriesHeader }}</label>
    <label *ngIf="!canAdd" class="warning-message">{{ language.f3MeWebApiEditSourceCannotAddError }}</label>
    <dx-button (click)="addNew()" icon="fas fa-plus" [text]="language.ButtonNew" [disabled]="!canAdd"> </dx-button>
  </div>

  <dx-data-grid [dataSource]="entries.all" autoWidth="true" [showBorders]="true" (onRowUpdated)="onRowChanged()" (onRowRemoved)="onRowChanged()">
    <dxo-editing [useIcons]="true" mode="row" [allowUpdating]="true" [allowDeleting]="true"> </dxo-editing>
    <dxi-column dataField="targetShortName" [caption]="language.f3MeWebApiTargetCaption">
      <dxo-lookup [dataSource]="targets" valueExpr="shortName" displayExpr="title"> </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="exogenousShortName" [caption]="language.f3MeWebApiExogenous">
      <dxo-lookup [dataSource]="exogenousVariables.all" valueExpr="shortName" displayExpr="displayName"> </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="sortOrder" dataType="number" [caption]="language.modelSortOrder"> </dxi-column>
  </dx-data-grid>
</div>
