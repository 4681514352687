import { CommonRoutesDto } from "../../../common/models/dto/CommonRoutes-dto";
import { Component } from "@angular/core";
import { InspireLanguageService } from "../../../f3Me/src/app/shared/language/inspire-language.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-nodata",
  templateUrl: "./login-no-data-for-user.component.html",
  styleUrls: ["./login-no-data-for-user.component.scss"],
})
export class LoginNoUserDataComponent {
  public language: InspireLanguageService;

  constructor(languageService: InspireLanguageService, private router: Router) {
    this.language = languageService;
  }

  public gotoLogin(): void {
    this.router.navigate(["/" + CommonRoutesDto.Login]);
  }
}
