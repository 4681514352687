import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { ExogenousVariable } from "../../../../../common/modelelements";
import { ExogenousVariablesEditDto } from "../../../../../common/models/dto/ExogenousVariablesEditDto-dto";
import { ExogenousVariablesWithCategories } from "../_models/exogenous-variables-with-categories.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InspireLanguageService } from "../../shared/language/inspire-language.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ExogenousVariablesEditService extends PlBaseService {
  private endPoint: string = "ExogenousVariablesEdit";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, private languageService: InspireLanguageService, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getAll(): Observable<ExogenousVariablesWithCategories> {
    const thisService = this;
    return this.getDataTransformed<ExogenousVariablesEditDto>(this.endPoint).pipe(map((r) => thisService.transform(r)));
  }

  private transform(dto: ExogenousVariablesEditDto): ExogenousVariablesWithCategories {
    if (dto) {
      const result: ExogenousVariablesWithCategories = new ExogenousVariablesWithCategories();
      result.copyFromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  public updateExogenousVariable(exogenousVariable: ExogenousVariable): Observable<any> {
    const url = this.endPoint + "/" + encodeURI(exogenousVariable.shortName);
    return this.update(exogenousVariable.copyToDto(), url);
  }
}
