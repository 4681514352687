<div class="panel-with-header-and-content">
    <h3 class="panel-header">
        {{language.settingsDisplayModelElements}}
    </h3>
    <div class="panel-content">
        <dx-radio-group [items]="values" [(value)]="value">
            <div *dxTemplate="let i of 'item'">
                <div>{{titles[i]}}</div>
            </div>
        </dx-radio-group>
    </div>
</div>