import { Component, EventEmitter, Output } from "@angular/core";

import { InspireLanguageService } from "../language/inspire-language.service";

@Component({
  selector: "app-search-bar",
  styleUrls: ["./search-bar.component.scss"],
  templateUrl: "./search-bar.component.html",
})
export class SearchBarComponent {
  public searchValue: string;
  public searchButton: any;

  @Output() search = new EventEmitter<string>();
  public constructor(public language: InspireLanguageService) {
    this.searchButton = {
      icon: "search",
      onClick: () => {
        this.search.emit(this.searchValue);
      },
    };
  }

  emitSearch(e) {
    this.search.emit(this.searchValue);
    e.preventDefault();
  }
}
