import { Component, Input } from "@angular/core";

import { Link } from "../../../../../employee-common/components/links/link";
import { LinkTypeDto } from "../../../../../employee-common/models/cms/dto/LinkType-dto";

export interface ISearchResult {
  title: string;
  summary: string;
  link: Link;
}

@Component({
  selector: "app-search-result",
  templateUrl: "./search-result.component.html",
  styleUrls: ["./search-result.component.scss"],
})
export class SearchResultComponent {
  @Input() searchResult: ISearchResult;

  urlIsExternal(link: Link): boolean {
    return link.linkType === LinkTypeDto.ExternalLink;
  }

  public get buttonStyleAsText() {
    return "text";
  }
}
