<div class="search">
  <form (submit)="emitSearch($event)">
    <dx-text-box name="search" [placeholder]="language.f3MeWebApiSearch + '...'" [(value)]="searchValue">
      <dxi-button name="submit" [options]="searchButton"> </dxi-button>
    </dx-text-box>
  </form>
</div>

<span class="search-icon" (click)="this.search.emit()">
  <i class="fas fa-search fa-2x"></i>
</span>
