import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxDataGridModule, DxNumberBoxModule, DxTextAreaModule } from "devextreme-angular";
import { TriggersEditComponent } from "./triggers-edit.component";

@NgModule({
  declarations: [TriggersEditComponent],
  imports: [CommonModule, DxDataGridModule, DxTextAreaModule, DxNumberBoxModule],
  providers: [],
  exports: [TriggersEditComponent],
})
export class TriggersEditModule {}
