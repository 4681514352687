import { SourceSpecificationDto } from "../../../../../../employee-common/models/choices/dto/SourceSpecificationDto-dto";

export class SourceSpecification {
  public constructor() {}

  public copyFromDto(dto: SourceSpecificationDto) {
    this.sortOrder = dto.SortOrder;
    this.resultValue = dto.ResultValue;
    this.shortName = dto.ShortName;
  }

  public shortName: string;
  public sortOrder: number;
  public resultValue: string;

  public toDto(): SourceSpecificationDto {
    const result = new SourceSpecificationDto();
    result.ResultValue = this.resultValue;
    result.ShortName = this.shortName;
    result.SortOrder = this.sortOrder;
    return result;
  }
}
