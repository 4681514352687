import { Component, OnInit } from "@angular/core";
import { SimulationModelElement, SimulationModelElementsService } from "../shared/simulation-modelelements";

import { Sorter } from "../../../../angular-common";
import { ModelElementSpecification } from "../../../../common/modelelements/modelelementsspecification";
import { CmsSimEditMove } from "../../../../employee-common/cms/edit/cms-sim-edit-move";
import { CmsLanguage } from "../../../../employee-common/cms/language/cmslanguage.service";

@Component({
  selector: "app-cms-simulation-edit-component",
  templateUrl: "./cms-edit-simmodelelements.component.html",
  styleUrls: ["./cms-edit-simmodelelements.component.scss", "../../../../employee-common/cms/shared/shared-styles.scss"],
  providers: [],
})
export class SimulationModelEditComponent implements OnInit {
  public inputOptions: ModelElementSpecification[] = [];
  public outputOptions: ModelElementSpecification[] = [];
  public availableInputOptions: ModelElementSpecification[] = [];
  public availableOutputOptions: ModelElementSpecification[] = [];
  public currentConfig: SimulationModelElement;
  public displayValue = "displayName";
  public searchValue = "displayName";
  private _moveImplementationInput: CmsSimEditMove;
  private _moveImplementationOutput: CmsSimEditMove;
  private sortOrderChanged: boolean = false;

  public constructor(public language: CmsLanguage, private simModelService: SimulationModelElementsService) {}

  public get moveImplementationInput() {
    return this._moveImplementationInput;
  }

  public get moveImplementationOutput() {
    return this._moveImplementationOutput;
  }

  ngOnInit(): void {
    this.getSimData();
  }

  private changeDetected(usedOptions: ModelElementSpecification[], allOptions: ModelElementSpecification[]) {
    return ModelElementSpecification.filterAvailableOptions(usedOptions, allOptions);
  }

  inputChanged() {
    this.availableInputOptions = this.changeDetected(this.currentConfig.inputs, this.inputOptions);
  }

  outputChanged() {
    this.availableOutputOptions = this.changeDetected(this.currentConfig.outputs, this.outputOptions);
  }

  private _originalConfig: SimulationModelElement;
  public get originalConfig(): SimulationModelElement {
    return this._originalConfig;
  }
  public set originalConfig(v: SimulationModelElement) {
    this._originalConfig = v;
  }

  public inputSortOrderChanged(data: ModelElementSpecification[]) {
    this.sortOrderChanged = true;
    this.updateSortOrder(data, this.currentConfig.inputs);
  }

  public outputSortOrderChanged(data: ModelElementSpecification[]) {
    this.sortOrderChanged = true;
    this.updateSortOrder(data, this.currentConfig.outputs);
  }

  private updateSortOrder(newData: ModelElementSpecification[], currentData: ModelElementSpecification[]) {
    for (let index = 0; index < newData.length; index++) {
      const element = newData[index];
      currentData.find((x) => x.keyId === element.keyId).sortOrder = index;
    }
  }

  getSimData() {
    this.simModelService.getAllData().subscribe((r) => {
      this.createInputs(r.inputOptions);
      this.createOutputs(r.outputOptions);
      this.createConfig(r.currentConfiguration);
      this.originalConfig = this.currentConfig.createCopy();
      this.configAvailableOptions();
    });
  }
  private configAvailableOptions() {
    this.inputChanged();
    this.outputChanged();
    this._moveImplementationInput = new CmsSimEditMove(this.currentConfig.inputs);
    this._moveImplementationOutput = new CmsSimEditMove(this.currentConfig.outputs);
  }

  createConfig(currentConfiguration: SimulationModelElement) {
    this.currentConfig = currentConfiguration;
    Sorter.sortSortOrderThenAlpha(this.currentConfig.inputs, (x) => x.longName);
    Sorter.sortSortOrderThenAlpha(this.currentConfig.outputs, (x) => x.longName);
  }

  createOutputs(r: ModelElementSpecification[]) {
    const newModelElements: ModelElementSpecification[] = [];
    r.forEach((element) => {
      newModelElements.push(element);
    });
    this.outputOptions = newModelElements;
  }

  createInputs(r: ModelElementSpecification[]) {
    const newSimElements: ModelElementSpecification[] = [];
    r.forEach((element) => {
      newSimElements.push(element);
    });
    this.inputOptions = newSimElements;
  }

  public get hasChanges(): boolean {
    if (this.originalConfig && this.currentConfig) {
      if (this.currentConfig.inputs.length !== this.originalConfig.inputs.length || this.currentConfig.outputs.length !== this.originalConfig.outputs.length || this.sortOrderChanged === true) {
        return true;
      }
      for (let i = 0; i < this.currentConfig.inputs.length; i++) {
        if (this.currentConfig.inputs[i] !== this.originalConfig.inputs[i]) {
          return true;
        }
      }
      for (let i = 0; i < this.currentConfig.outputs.length; i++) {
        if (this.currentConfig.outputs[i] !== this.originalConfig.outputs[i]) {
          return true;
        }
      }
    }
    return false;
  }

  onSave() {
    this.simModelService.saveOptions(this.currentConfig).subscribe((r) => {});
    this.originalConfig = this.currentConfig.createCopy();
    this.sortOrderChanged = false;
  }
}
