import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { SimulationLanguageEditService } from "./simulation-language-edit.service";

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  exports: [],
  providers: [SimulationLanguageEditService],
})
export class SimulationLanguageEditServiceModule {}
