import { Component, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-files-user-screen",
  templateUrl: "./files-user-screen.component.html",
  styleUrls: ["./files-user-screen.component.scss"],
})
export class FileUserScreenComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  public userId: string;

  public userLoaded = false;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      console.log("received parameter", params["userId"]);
      this.userId = params["userId"];
      this.userLoaded = true;
    });
  }
}
