import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  EnvironmentSettings,
  LoginLandingPageComponent,
  LoginLandingPageModule,
  PlAuthenticationService,
  PlBaseService,
  PlErrorFeedbackComponent,
  PlErrorHandler,
  PlErrorHandlerModule,
} from "../../../angular-common";
import { ApproveTargetsComponent, ApproveTargetsModule, ApproveTargetsService } from "./approvetargets";

import { HttpClient } from "@angular/common/http";
import { canActivateAuthGuard } from "../../../angular-common/authentication-guard/authentication-guard";
import { UserInformationService } from "../../../angular-common/authentication/user-information.service";
import { AuditLogsModule } from "../../../angular-common/components/auditing/audit-logs.module";
import { PlLoginModule } from "../../../angular-common/components/login/login.module";
import { ApplicationAboutInformationComponent } from "../../../angular-common/components/technicalmaintainer/applicationinformation/applicationinformation.component";
import { LogDownloadComponent } from "../../../angular-common/components/technicalmaintainer/logdownload/log-download.component";
import { TechnicalMaintainerModule } from "../../../angular-common/components/technicalmaintainer/technicalmaintainer.module";
import { DevExpressLanguageLoader } from "../../../angular-common/language/devexpress.language";
import { TechnicalManagementRoutesDto } from "../../../common/models/dto/TechnicalManagementRoutes-dto";
import { EmployeeAuthenticationService } from "../../../employee-common/auth/employee-auth.service";
import { UserAcknowledgementGuard, canActivateUserAcknowledgementGuard } from "../../../employee-common/auth/user-acknowledgemen.guard";
import { ChoicesLanguage } from "../../../employee-common/choices/language/choiceslanguage.service";
import { CMSModule } from "../../../employee-common/cms/cms.module";
import { UserFieldsEditModule } from "../../../employee-common/cms/user-fields/userfieldsedit.module";
import { LanguageEditService } from "../../../employee-common/components/language/languageedit.service";
import { EmployeeLoginModule } from "../../../employee-common/components/login/employeelogin.module";
import { LoginNoUserDataComponent } from "../../../employee-common/components/login/login-no-data-for-user.component";
import { MenuTypeProvider } from "../../../employee-common/components/menus/menu-type-provider";
import { MenusModule } from "../../../employee-common/components/menus/menus.module";
import { UserSettingsService } from "../../../employee-common/components/usersettings/usersettings.service";
import { EbsModule } from "../../../employee-common/ebs/ebs.module";
import { EmployeeEmailModule } from "../../../employee-common/email/employee-email.module";
import { FixedImagesModule } from "../../../employee-common/fixedimages/fixedimages.module";
import { HomeModule } from "../../../employee-common/home/home.module";
import { LabourConditionsOverviewModule } from "../../../employee-common/labourconditions/labour-overview/labourconditions-overview.module";
import { LabourConditionsContentModule } from "../../../employee-common/labourconditions/labourcondtions-content.module";
import { LabourConditionDetailModule } from "../../../employee-common/labourconditions/labourcondtition-detail/labour-detail.module";
import { ChoicesRoutesDto } from "../../../employee-common/models/choices/dto/ChoicesRoutes-dto";
import { CmsRoutesDto } from "../../../employee-common/models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { RatingModule } from "../../../employee-common/rating/components/rating.module";
import { MessageRatingDashboardsModule } from "../../../employee-common/rating/dashboard/message-rating-dashboard.module";
import { PageNotFoundComponent } from "./404/page.not.found.component";
import { ErrorPageComponent } from "./500/error.page.component";
import { DashboardsModule } from "./dashboards/dashboards.module";
import { FilesModule } from "./files/files.module";
import { InspireFeaturesModel } from "./inspire-features-model";
import { MyOrdersComponent } from "./myorders/myorders.component";
import { MyordersModule } from "./myorders/myorders.module";
import { SearchScreenComponent } from "./search-screen/search-screen.component";
import { SearchScreenModule } from "./search-screen/search-screen.module";
import { InspireLanguageService } from "./shared/language/inspire-language.service";
import { SimulationModelElementModule } from "./shared/simulation-modelelements";
import { StatusService } from "./shared/status-service";
import { VariantSelectionService } from "./shared/variantselection/variantselection.service";
import { SimulateModule } from "./simulate/simulate.module";

// This function needs to be here and be exported. The language is loaded before other modules.
export function initializeLanguage(langSrv: InspireLanguageService) {
  return () =>
    langSrv
      .loadInspireLanguage()
      .then((l) => {
        DevExpressLanguageLoader.loadDevExpressLanguage(l.MetaData.Language);
      })
      .catch((e) => console.log("Error loading language", e));
}

export function initializeStatus(statusService: StatusService) {
  return () => statusService.loadStatus().catch((e) => console.log("Error loading status", e));
}

export function checkUserLoginState(statusService: UserInformationService) {
  return () => statusService.checkUserStatus().catch((e) => console.log("Error validating user status", e));
}

// TODO routes to choices module: https://dev.azure.com/sdworx/F3/_workitems/edit/847118
const routes: Routes = [
  { path: "", component: LoginLandingPageComponent, pathMatch: "full" },
  { path: "error", component: ErrorPageComponent },
  { path: "notFound", component: PageNotFoundComponent },
  { path: CmsRoutesDto.NoDataForUser, component: LoginNoUserDataComponent },
  {
    path: ChoicesRoutesDto.MyOrders,
    component: MyOrdersComponent,
    data: [{ menuType: MenuItemTypesDto.MainMenu }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: ChoicesRoutesDto.Approve,
    component: ApproveTargetsComponent,
    data: [{ menuType: MenuItemTypesDto.Targets }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: TechnicalManagementRoutesDto.LogDownload,
    component: LogDownloadComponent,
    data: [{ menuType: MenuItemTypesDto.Technical }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: TechnicalManagementRoutesDto.ApplicationInformation,
    component: ApplicationAboutInformationComponent,
    data: [{ menuType: MenuItemTypesDto.Technical }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: CmsRoutesDto.Search,
    component: SearchScreenComponent,
    data: [{ menuType: MenuItemTypesDto.MainMenu }],
    canActivate: [canActivateAuthGuard],
  },

  { path: "**", redirectTo: "notFound" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    HomeModule,
    EbsModule,
    CMSModule,
    ApproveTargetsModule,
    SimulateModule,
    RatingModule,
    MessageRatingDashboardsModule,
    PlLoginModule,
    EmployeeEmailModule,
    FixedImagesModule,
    MenusModule,
    LabourConditionsOverviewModule,
    SearchScreenModule,
    LabourConditionsContentModule,
    LabourConditionDetailModule,
    PlErrorHandlerModule,
    SimulationModelElementModule,
    EmployeeLoginModule,
    LoginLandingPageModule,
    TechnicalMaintainerModule,
    AuditLogsModule,
    DashboardsModule,
    FilesModule,
    MyordersModule,
    UserFieldsEditModule,
  ],
  exports: [MenusModule, PlErrorHandlerModule, PlErrorFeedbackComponent],
  declarations: [ErrorPageComponent, PageNotFoundComponent],
  providers: [
    EnvironmentSettings,
    InspireFeaturesModel,
    PlBaseService,
    EmployeeAuthenticationService,
    UserAcknowledgementGuard,
    MenuTypeProvider,
    ApproveTargetsService,
    UserSettingsService,
    InspireLanguageService,
    StatusService,
    LanguageEditService,
    VariantSelectionService,
    UserInformationService,
    ChoicesLanguage,

    { provide: ErrorHandler, useClass: PlErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLanguage,
      deps: [InspireLanguageService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeStatus,
      deps: [StatusService, HttpClient],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: checkUserLoginState,
      deps: [UserInformationService],
      multi: true,
    },
    { provide: PlAuthenticationService, useExisting: EmployeeAuthenticationService },
  ],
})
export class AppInspireModule {}
