import { HttpClient, HttpResponse } from "@angular/common/http";
import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";
import { TargetSettings } from "../../../../../employee-common/choices/targets/targetsettings";
import { TargetSettingsDto } from "../../../../../employee-common/models/choices/dto/TargetSettingsDto-dto";
import { TargetSettingsGroupingDto } from "../../../../../employee-common/models/choices/dto/TargetSettingsGroupingDto-dto";
import { TargetSettingsGrouping } from "./targetgrouping";

@Injectable()
export class TargetEditService extends PlBaseService {
  private endPoint = "targetedit";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator, private languageSelection: PlLanguageSelection) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveTargetsGrouping(): Observable<TargetSettingsGrouping> {
    return this.getDataTransformed<TargetSettingsGroupingDto>(this.endPoint).pipe(map((r) => this.extractTargets(r)));
  }

  private extractTargets(dto: TargetSettingsGroupingDto): TargetSettingsGrouping {
    if (dto) {
      const result = new TargetSettingsGrouping();
      result.fromDto(dto, this.languageSelection);
      return result;
    } else {
      return null;
    }
  }

  public updateTarget(target: TargetSettings): Observable<any> {
    const url = this.endPoint + "/" + target.keyId;
    return this.update(TargetSettings.toDto(target), url);
  }

  public inserTarget(target: TargetSettings): Observable<TargetSettings> {
    const result = this.postDataTransformed<TargetSettingsDto, TargetSettings>(this.endPoint, TargetSettings.toDto(target), (r) => this.extractNewTarget(r));
    return result;
  }

  private extractNewTarget(dto: HttpResponse<TargetSettingsDto>): TargetSettings {
    if (dto) {
      const result = new TargetSettings();
      result.fromDto(dto.body, this.languageSelection);
      return result;
    } else {
      return null;
    }
  }

  public deleteTarget(target: TargetSettings): Observable<any> {
    const url = this.endPoint + "/" + target.keyId;
    return this.remove(url);
  }
}
