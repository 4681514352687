import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { VariantConfiguration } from "./variant-configuration";
import { VariantModelConfigurationDto } from "../../../../../employee-common/models/cms/dto/VariantModelConfigurationDto-dto";
import { map } from "rxjs/operators";

@Injectable()
export class VariantSelectionService extends PlBaseService {
  endpoint = "variantmodelconfiguration";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveSettings(): Observable<VariantConfiguration[]> {
    return this.getData(this.endpoint).pipe(map(this.convertData, this));
  }

  convertData(data: VariantModelConfigurationDto[]): VariantConfiguration[] {
    if (data) {
      const newConfigurations = data.map((dto) => {
        const config = new VariantConfiguration();
        config.copyFromDto(dto);
        return config;
      });
      return newConfigurations;
    } else {
      return null;
    }
  }

  public storeVariant(variant: VariantConfiguration): Observable<VariantModelConfigurationDto> {
    return this.updateTransformedResponse(VariantConfiguration.toDto(variant), this.endpoint);
  }
}
