import { NgModule } from "@angular/core";
import { SourceSpecificationsService } from "./source-specifications.service";

@NgModule({
  declarations: [],
  imports: [],
  providers: [SourceSpecificationsService],
  exports: [],
})
export class SourceSpecificationsModule {}
