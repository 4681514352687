import { ModelElementSpecification } from "../../../../../common/modelelements/modelelementsspecification";
import { ModelElementDto } from "../../../../../common/models/dto/ModelElementDto-dto";
import { SimulationModelElementsDto } from "../../simulate/dto/SimulationModelElements-dto";

export class SimulationModelElement {
  public constructor() {}

  public inputs: ModelElementSpecification[];
  public outputs: ModelElementSpecification[];

  public copyFromDto(dto: SimulationModelElementsDto) {
    const newInputs: ModelElementSpecification[] = [];
    dto.Inputs.forEach((element) => {
      const newInput = new ModelElementSpecification();
      newInput.copyFromDto(element);
      newInputs.push(newInput);
    });
    this.inputs = newInputs;

    const newOutputs: ModelElementSpecification[] = [];
    dto.Outputs.forEach((element) => {
      const newOutput = new ModelElementSpecification();
      newOutput.copyFromDto(element);
      newOutputs.push(newOutput);
    });
    this.outputs = newOutputs;
  }

  public toDto(value: SimulationModelElement): SimulationModelElementsDto {
    const dto = new SimulationModelElementsDto();
    const newInputs: ModelElementDto[] = [];
    value.inputs.forEach((element) => {
      newInputs.push(element.toDto(element));
    });

    dto.Inputs = newInputs;

    const newOutputs: ModelElementDto[] = [];
    value.outputs.forEach((element) => {
      newOutputs.push(element.toDto(element));
    });

    dto.Outputs = newOutputs;

    return dto;
  }

  public createCopy(): SimulationModelElement {
    const copy = new SimulationModelElement();

    const newInputs: ModelElementSpecification[] = [];
    this.inputs.forEach((e) => {
      let newin = new ModelElementSpecification();
      newin = e;
      newInputs.push(newin);
    });

    const newOutputs: ModelElementSpecification[] = [];
    this.outputs.forEach((e) => {
      let newout = new ModelElementSpecification();
      newout = e;
      newOutputs.push(newout);
    });

    copy.outputs = newOutputs;
    copy.inputs = newInputs;

    return copy;
  }
}
