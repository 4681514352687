import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";
import { SourceEntrySpecificationDto } from "../../../../../employee-common/models/choices/dto/SourceEntrySpecificationDto-dto";
import { SourceEntrySpecificationsMetaDataDto } from "../../../../../employee-common/models/choices/dto/SourceEntrySpecificationsMetaData-dto";
import { SourceEntrySpecificationsMetaData } from "./models/source-entry-specifications-metadata.model";
import { SourceEntrySpecifications } from "./models/source-entry-specifications.model";

@Injectable()
export class SourceEntrySpecificationsService extends PlBaseService {
  private endPoint: string = "SourceEntrySpecification";
  public constructor(http: HttpClient, envSettings: EnvironmentSettings, private languageSelection: PlLanguageSelection, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getMetaData(): Observable<SourceEntrySpecificationsMetaData> {
    const thisService = this;
    return this.getDataTransformed<SourceEntrySpecificationsMetaDataDto>(this.endPoint).pipe(map((r) => thisService.transformMetaData(r)));
  }

  private transformMetaData(dto: SourceEntrySpecificationsMetaDataDto): SourceEntrySpecificationsMetaData {
    if (dto) {
      const result: SourceEntrySpecificationsMetaData = new SourceEntrySpecificationsMetaData();
      result.copyFromDto(dto, this.languageSelection);
      return result;
    } else {
      return null;
    }
  }

  public getSourceEntrySpecifications(sourceShortName: string) {
    const url = this.endPoint + "/" + encodeURI(sourceShortName);
    const thisService = this;
    return this.getDataTransformed<SourceEntrySpecificationDto[]>(url).pipe(map((r) => thisService.transformSpecification(r)));
  }

  private transformSpecification(dto: SourceEntrySpecificationDto[]): SourceEntrySpecifications {
    if (dto) {
      const result: SourceEntrySpecifications = new SourceEntrySpecifications([]);
      result.copyFromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  public updateSourceEntrySpecifications(sourceShortName: string, specifications: SourceEntrySpecifications): Observable<any> {
    const url = this.endPoint + "/" + encodeURI(sourceShortName);
    return this.update(specifications.toDto(), url);
  }
}
