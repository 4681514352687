<div class="edit-button">
  <dx-button icon="edit" [text]="caption" (onClick)="showEditPopup()"></dx-button>
</div>

<dx-popup [width]="800" [height]="'auto'" [showTitle]="true" [title]="language.f3MeWebApiTranslateTargetChoicesEdit" [dragEnabled]="false" [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'">
    <div>
      <dx-data-grid
        [dataSource]="choices.Translations"
        redrawOnResize="true"
        [rowAlternationEnabled]="true"
        [showColumnHeaders]="true"
        height="500px"
        [allowColumnResizing]="true"
        (onEditingStart)="onEditingStart($event.data)"
        (onRowUpdated)="onRowUpdated($event.data, $event.component)"
      >
        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-editing [allowUpdating]="true" [useIcons]="true" mode="row" [allowDeleting]="false"> </dxo-editing>
        <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
        <dxi-column [allowEditing]="false" dataField="CoreDescription" dataType="string" [caption]="language.f3MeWebApiTranslateTargetOriginalDescription"> </dxi-column>
        <dxi-column dataField="Description" [caption]="language.modelDescription" editCellTemplate="descrEditCellTemplate" [calculateDisplayValue]="description"></dxi-column>
        <div *dxTemplate="let d of 'descrEditCellTemplate'">
          <app-languageitem-editor-grid [cellInfo]="d" [item]="d.data.DescriptionCopy"> </app-languageitem-editor-grid>
        </div>
      </dx-data-grid>
      <div class="close-button">
        <dx-button [text]="language.ButtonClose" (click)="closePopup()"> </dx-button>
      </div>
    </div>
  </div>
</dx-popup>
