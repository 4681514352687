import { DxButtonModule, DxDataGridModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { SourceEntrySpecificationsEditComponent } from "./source-entry-specifications-edit.component";
import { SourceEntrySpecificationsModule } from "../sourceentryspecifications/source-entry-specifications.module";
import { SourceSpecificationsEditComponent } from "./source-specifications-edit.component";

@NgModule({
  declarations: [SourceSpecificationsEditComponent, SourceEntrySpecificationsEditComponent],
  imports: [CommonModule, FormsModule, HttpClientModule, DxButtonModule, DxDataGridModule, DxButtonModule, SourceEntrySpecificationsModule],
  providers: [],
  exports: [SourceSpecificationsEditComponent, SourceEntrySpecificationsEditComponent],
})
export class SourceSpecificationsEditModule {}
