import { Component, Input, OnInit } from "@angular/core";

import { CHART_CONFIG } from "../../../../employee-common/ebs/_models/ebs-chart-configuration.model";
import { EbsChartItem } from "../../../../employee-common/ebs/_models/ebs-chart-item.model";
import { LanguageItem } from "../../../../angular-common/language/language-dto.model";
import { Observable } from "rxjs";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { SmartEbsHeader } from "./smart-ebs-header";
import { ValueToText } from "./smartebs-value-to-text-converter";

@Component({
  selector: "app-smart-ebs-chart",
  templateUrl: "smart-ebs-chart.component.html",
  styleUrls: ["smart-ebs-chart.component.scss"],
})
export class SmartEbsChartComponent implements OnInit {
  constructor(private selectLanguage: PlLanguageSelection) {}

  ngOnInit(): void {
    this.ebsGroups.subscribe((ebsGroups) => {
      this._ebsGroups = ebsGroups;
      this.createGroupsForChart();
    });
  }

  public palette: string[] = [];

  private _ebsGroups: SmartEbsHeader[] = [];
  public groupsForChart: EbsChartItem[] = [];

  public series: any = CHART_CONFIG.series;
  public legend: any = CHART_CONFIG.legend;
  public size: any = CHART_CONFIG.size;
  public tooltip: any = CHART_CONFIG.tooltip;

  get totalValue() {
    let value = 0;
    this._ebsGroups.forEach((group) => {
      value = value + group.totalValue;
    });

    return value;
  }

  get totalAsText() {
    return ValueToText.from(this.totalValue);
  }

  @Input() ebsGroups: Observable<SmartEbsHeader[]>;

  public get chartReady() {
    return this.groupsForChart.length > 0 && this.totalValue > 0;
  }

  private createGroupsForChart() {
    const chartGroups = new Array<EbsChartItem>();
    const groupcolors = new Array<string>();
    this._ebsGroups.forEach((group) => {
      if (group.totalValue > 0) {
        groupcolors.push(group.ebsColor);
        const title = [group.longName, group.longName] as LanguageItem;

        const newChartItem = new EbsChartItem(this.selectLanguage, group.ID, title, group.totalValue);

        chartGroups.push(newChartItem);
      }
    });
    this.groupsForChart = chartGroups;
    this.palette = groupcolors;
  }
}
